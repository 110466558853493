import Settings from './Settings';
import MarkerMaps from './MarkerMaps';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {DataSourceFields, DataTypes} from 'interfaces/DataSources';
import {Settings as SettingsSchema} from '../interfaces';

const MarkersMap: ComponentCharacteristics<SettingsSchema> = {
  category: 'Maps',
  type: 'markerMaps',
  name: 'IMarker Maps',
  Component: MarkerMaps,
  settings: Settings,
  dataSourcesOptions: {
    initialValue: {
      dataType: DataTypes.ATTRIBUTES,
    },
    disabledFields: [DataSourceFields.DataType],
  },
};

export default MarkersMap;
