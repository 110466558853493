import instance, {injectParamsIntoUrl} from 'adaptors/httpClient';
import {composeUrl, dataUrl, deviceUrl} from 'adaptors/config';

const urls = {
  searchForDevice: '/v1/organizations/:organizationId/applications/:applicationId/devices',
  searchForTag: '/v1/organizations/:organizationId/applications/:applicationId/tags',
  deviceTelemetryKeys: '/v1/organizations/:organizationId/devices/:deviceId/telemetries/keys',
  deviceAttributesKeys: '/v1/organizations/:organizationId/devices/:deviceId/attributes/keys ',
  tagTelemetryKeys: '/v1/organizations/:organizationId/tags/:tag/telemetries/keys',
  tagAttributesKeys: '/v1/organizations/:organizationId/tags/:tag/attributes/keys',
};

export interface Params {
  organizationId?: string;
  applicationId?: string;
  deviceId?: string;
  tag?: string;
}

interface Response<T> {
  result: T;
  // data: {
  //   result: T;
  // };
}

interface Devices {
  id: string;
  name: string;
}

type Tag = {
  name: string;
};

export default {
  searchForDevice: (deviceName: string, params: Params): Promise<Devices[]> => {
    const url = injectParamsIntoUrl<Params>(urls.searchForDevice, params);
    return instance
      .get<Response<{data: Devices[]}>>(url, {
        baseURL: composeUrl,
      })
      .then(({data}) => data.result.data);
  },
  searchForTag: (deviceName: string, params: Params): Promise<Tag[]> => {
    const url = injectParamsIntoUrl<Params>(urls.searchForTag, params);
    return instance
      .get<Response<{data: Tag[]}>>(url, {
        baseURL: deviceUrl,
      })
      .then(({data}) => data.result.data);
  },
  getDeviceTelemetryKeys: (deviceId: string, params: Params): Promise<string[]> => {
    const url = injectParamsIntoUrl<Params>(urls.deviceTelemetryKeys, {...params, deviceId});
    return instance
      .get<Response<{data: string[]}>>(url, {
        baseURL: dataUrl,
      })
      .then(({data}) => data.result.data);
  },
  getDeviceAttributesKeys: (deviceId: string, params: Params): Promise<string[]> => {
    const url = injectParamsIntoUrl<Params>(urls.deviceAttributesKeys, {...params, deviceId});
    return instance
      .get<Response<{data: string[]}>>(url, {
        baseURL: dataUrl,
      })
      .then(({data}) => data.result.data);
  },
  getTagTelemetryKeys: (tagName: string, params: Params): Promise<string[]> => {
    const url = injectParamsIntoUrl<Params>(urls.tagTelemetryKeys, {...params, tag: tagName});
    return instance
      .get<Response<{data: string[]}>>(url, {
        baseURL: composeUrl,
      })
      .then(({data}) => data.result.data);
  },
  getTagAttributesKeys: (tagName: string, params: Params): Promise<string[]> => {
    const url = injectParamsIntoUrl<Params>(urls.tagAttributesKeys, {...params, tag: tagName});
    return instance
      .get<Response<{data: string[]}>>(url, {
        baseURL: composeUrl,
      })
      .then(({data}) => data.result.data);
  },
};
