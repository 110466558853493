import React from 'react';
import Col from 'antd/es/col';
import useKPI from '../useKPI';
import {Settings} from './interfaces';
import {ElementSchema} from '../../../../interfaces/Elements';

type Props = {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
};

function Card(props: Props) {
  const {value, isLoading} = useKPI({element: props.element});
  const {style} = props;
  const {settings = {}} = props.element;

  const getComponentColorInRGBA = (color?: string, opacity = '1') => {
    const RGBA = color || `74, 74, 74, ${opacity}`;
    const arr = RGBA.split(', ');
    arr[3] = opacity;
    return `rgba(${arr.join(', ')})`;
  };

  // const parseNumber = (value: number, maxValue: number): number => {
  //   const {asPercentage, digits = 1} = settings;
  //   const val = Number(asPercentage ? (value / maxValue) * 100 : value || 0);
  //   const hasDecimals = val % 1 !== 0;
  //   return hasDecimals ? val.toFixed(digits) : val;
  // };

  const hasIcon = Boolean(settings.iconUrl);

  if (isLoading) {
    return 'LOADING';
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        height: '100%',
        ...style,
      }}
    >
      {hasIcon && (
        <Col
          xs={24}
          sm={settings.iconWidth || 12}
          style={{textAlign: settings.iconPosition || 'right'}}
        >
          <img
            width={settings.iconSize || 100}
            height={settings.iconSize || 100}
            src={settings.iconUrl}
            alt="custom icon"
          />
        </Col>
      )}
      <Col
        span={hasIcon ? 12 : 24}
        sm={hasIcon ? 24 - (settings.iconWidth || 12) : 24}
        xs={24}
        style={{
          textAlign: settings.textPosition || 'center',
          color:
            settings &&
            settings.textColor &&
            `rgba(${settings.textColor.r}, ${settings.textColor.g}, ${settings.textColor.b}, ${settings.textColor.a})`,
        }}
      >
        <span
          style={{
            fontSize: settings.textSize === undefined ? 40 : settings.textSize,
            // marginLeft: 10,
          }}
        >
          {value}
        </span>
        <span
          style={{
            fontSize: settings.labelSize === undefined ? 40 : settings.labelSize,
            marginLeft: 10,
          }}
        >
          {settings.labelText || ''}
        </span>
      </Col>
    </div>
  );
}

export default Card;
