import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {Settings} from './interfaces';
import {SettingsElementProps} from '../../../../interfaces/Settings';
import MetricsSettings from '../Metrics';

const {Item: FormItem} = Form;

interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
}
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function LinearGaugeSettings(props: Props) {
  const {
    form: {getFieldDecorator},
    element: {settings = {}},
  } = props;

  return (
    <Form layout="inline" labelAlign="left" {...formItemLayout} className="full-width">
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Title" {...formItemLayout} className="full-width">
            {getFieldDecorator('settings.title', {
              initialValue: settings.title || '',
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Unit" {...formItemLayout} className="full-width">
            {getFieldDecorator('settings.unit', {
              initialValue: settings.unit || '',
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Min Value" {...formItemLayout} className="full-width">
            {getFieldDecorator('settings.minValue', {
              initialValue: settings.minValue || 0,
            })(<InputNumber max={settings.maxValue} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Max Value" {...formItemLayout} className="full-width">
            {getFieldDecorator('settings.maxValue', {
              initialValue: settings.maxValue || 100,
            })(<InputNumber min={settings.minValue} />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Interval" {...formItemLayout} className="full-width">
            {getFieldDecorator('settings.interval', {
              initialValue: settings.interval || 20,
            })(<InputNumber />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Minor Ticks" {...formItemLayout} className="full-width">
            {getFieldDecorator('settings.minorTicks', {
              initialValue: settings.minorTicks || 2,
            })(<InputNumber />)}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}
export default [
  {
    name: 'Gauge Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(LinearGaugeSettings),
  },
  {
    name: 'Metrics',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(MetricsSettings),
  },
];
