import styled from 'styled-components/macro';

interface IPageStyle {
  pageWidth?: string;
  pageHeight?: string;
  styles?: string;
}

interface IComponentStyle {
  width: number | string;
  height: number | string;
  left: number;
  top: number;
  styles?: string;
}

export const PageContainer = styled.div`
  z-index: 1;
  position: relative;
  width: ${(props: IPageStyle) => (props.pageWidth ? `${props.pageWidth}px` : '100vw')};
  height: ${(props: IPageStyle) => (props.pageHeight ? `${props.pageHeight}px` : '100vw')};
  max-width: 100%;
  max-height: 100%;
  outline: none;
  box-shadow: 0 0 3px #a7a7a7;
  border-radius: 3px;
  ${(props: IPageStyle) => props.styles}
`;

export const ComponentContainer = styled.span`
  width: ${(props: IComponentStyle) =>
    typeof props.width === 'number' ? `${props.width}px` : props.width};
  height: ${props => (typeof props.height === 'string' ? props.height : `${props.height}px`)};
  left: ${props => props.left || 0}px;
  top: ${props => props.top || 0}px;
  position: absolute;
  z-index: 1;
`;
