import React, {useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dock from 'react-dock';
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Button from 'antd/es/button';
import Tabs from 'antd/es/tabs';
import Descriptions from 'antd/es/descriptions';
import components from 'modules/Components/index';
import {getSelectedElementPosition, getEditorInfo} from '../Editor/';
import ElementDebuggerData from './ElementData';
import ElementsTree from './ElementsTree';
import {Log, LogActions} from './store/interfaces';
import Debug from './Tabs/Debug';
import Data from './Tabs/Data';
import {getCurrentTime} from 'utils/dateUtils';
import {Store} from 'store/interfaces';
import DataSource from './Tabs/DataSource';
import Settings from './Tabs/Settings';
import StyleEditor from './Tabs/StyleEditor';
import DefaultStyles from './Tabs/Styles';
import {DockPositions, AvailableTabs} from './interfaces';
import useElements from '../../Elements';
import usePages from '../../Pages';
import ResizableRow from './ResizableRow';
import {StyledTabs} from './styles';

const {TabPane} = Tabs;
const {SubMenu} = Menu;

const MIN_DRAWER_HEIGHT = 20;
const MAX_DRAWER_HEIGHT = 100;

export const getLogs = (store: Store) => store.debuggerStore.logs;
export const getIsVisible = (store: Store) => store.debuggerStore.isVisible;
export const getActiveTab = (store: Store) => store.debuggerStore.activeTab;

export const addLog = (log: Omit<Log, 'time'>) => {
  return {
    type: LogActions.ADD_LOG,
    payload: {...log, time: getCurrentTime()},
  };
};

export const setDebuggerVisibility = (visibilityStatus: boolean) => {
  return {
    type: LogActions.SET_DEBUGGER_VISIBILITY,
    payload: visibilityStatus,
  };
};

export const setDebuggerTab = (tab: AvailableTabs) => {
  return {
    type: LogActions.SET_DEBUGGER_TAB,
    payload: tab,
  };
};

const EditorInfo = React.memo(() => {
  const info = useSelector(getEditorInfo);
  return (
    <Descriptions>
      <Descriptions.Item label="Editor Name">{info.name}</Descriptions.Item>
      <Descriptions.Item label="Protection Type">{info.protectionType}</Descriptions.Item>
      <Descriptions.Item label="Number of Pages">{info.numberOfPages}</Descriptions.Item>
    </Descriptions>
  );
});

function Debugger() {
  const {updateElementStyle, getSelectedElement, updateElementDataSource} = useElements();
  const {updatePageStyle, currentPageSelector} = usePages();
  const currentPage = useSelector(currentPageSelector);
  const [dockHeight, setDockHeight] = useState(MIN_DRAWER_HEIGHT);
  const [dockPosition, setDockPosition] = useState<string>(
    localStorage.getItem('debuggerPosition') || DockPositions.BOTTOM,
  );
  const selectedElement = useSelector(getSelectedElement);
  const selectedElementPosition = useSelector(getSelectedElementPosition);
  const visible = useSelector(getIsVisible);
  const activeTab = useSelector(getActiveTab);
  const dispatch = useDispatch();
  let dataSourcesOptions;
  let aggregationOptions;
  let isTimeWindowEnabled;

  if (selectedElement) {
    ({dataSourcesOptions, aggregationOptions, isTimeWindowEnabled} = components[
      selectedElement.type
    ]);
  }

  const updateCompStyles = useCallback(
    styles => {
      if (!selectedElement) {
        return;
      }
      updateElementStyle(selectedElement.id, styles);
    },
    [selectedElement, updateElementStyle],
  );

  const handleMousemove = useCallback(height => {
    height = Math.trunc(height * 100);
    if (height > MIN_DRAWER_HEIGHT && height < MAX_DRAWER_HEIGHT) {
      setDockHeight(height);
    }
  }, []);

  const tabBarExtraContent = useCallback(() => {
    const menu = () => (
      <Menu
        selectable
        onSelect={({key}) => {
          localStorage.setItem('debuggerPosition', key);
          setDockPosition(key);
        }}
        defaultSelectedKeys={[dockPosition]}
      >
        <SubMenu title="Position">
          {Object.values(DockPositions).map(position => (
            <Menu.Item style={{textTransform: 'capitalize'}} key={position}>
              {position}
            </Menu.Item>
          ))}
        </SubMenu>
      </Menu>
    );

    return (
      <div style={{marginRight: 10}}>
        <span style={{marginRight: 5}}>
          <Dropdown overlay={menu()}>
            <Button size="small" icon="setting" />
          </Dropdown>
        </span>
        <Button onClick={() => dispatch(setDebuggerVisibility(false))} icon="close" size="small" />
      </div>
    );
  }, [dispatch, dockPosition]);

  const height = dockPosition === DockPositions.BOTTOM ? dockHeight : 100;

  return (
    <Dock
      defaultSize={0.2}
      size={dockHeight / 100}
      onVisibleChange={() => dispatch(setDebuggerVisibility(false))}
      isVisible={visible}
      position={dockPosition}
      dimMode="none"
      dockStyle={{
        padding: 5,
      }}
      onSizeChange={handleMousemove}
      zIndex={10}
    >
      <ResizableRow />
      <StyledTabs
        onTabClick={(activeKey: AvailableTabs) => dispatch(setDebuggerTab(activeKey))}
        activeKey={activeTab}
        type="card"
        tabBarExtraContent={tabBarExtraContent()}
      >
        <TabPane key={AvailableTabs.INFO} tab={<span className="disable-selection">Info</span>}>
          {selectedElement ? (
            <ElementDebuggerData element={{...selectedElement, ...selectedElementPosition}} />
          ) : (
            <EditorInfo />
          )}
        </TabPane>
        {selectedElement && dataSourcesOptions && (
          <TabPane
            key={AvailableTabs.DATA_SOURCE}
            tab={<span className="disable-selection">Data Source</span>}
          >
            <DataSource
              selectedElement={selectedElement}
              updateElement={updateElementDataSource}
              aggregationOptions={aggregationOptions}
              isTimeWindowEnabled={isTimeWindowEnabled}
            />
          </TabPane>
        )}
        {!selectedElement && (
          <TabPane
            key={AvailableTabs.ELEMENTS}
            tab={<span className="disable-selection">Elements</span>}
          >
            <div style={{height: '200px', overflow: 'auto'}}>
              <ElementsTree />
            </div>
          </TabPane>
        )}
        <TabPane
          key={AvailableTabs.CONSOLE}
          tab={<span className="disable-selection">Console</span>}
        >
          <Debug height={height} />
        </TabPane>
        {selectedElement && (
          <TabPane
            key={AvailableTabs.SETTINGS}
            tab={<span className="disable-selection">Settings</span>}
          >
            <Settings element={selectedElement} />
          </TabPane>
        )}
        {selectedElement && dataSourcesOptions && (
          <TabPane key={AvailableTabs.DATA} tab={<span className="disable-selection">Data</span>}>
            <Data height={height} elementId={selectedElement.id} />
          </TabPane>
        )}
        <TabPane key={AvailableTabs.STYLES} tab={<span className="disable-selection">Styles</span>}>
          <DefaultStyles
            initialValue={
              selectedElement
                ? selectedElement.styles || {}
                : currentPage
                ? currentPage.style || {}
                : {}
            }
            applyStyles={selectedElement ? updateCompStyles : updatePageStyle}
          />
        </TabPane>
        <TabPane
          key={AvailableTabs.ADVANCED_STYLES}
          tab={<span className="disable-selection">Advanced Styles</span>}
        >
          <StyleEditor
            initialValue={
              selectedElement
                ? (selectedElement.styles && selectedElement.styles.content) || ''
                : currentPage
                ? (currentPage.style && currentPage.style.content) || ''
                : ''
            }
            applyStyles={selectedElement ? updateCompStyles : updatePageStyle}
          />
        </TabPane>
      </StyledTabs>
    </Dock>
  );
}

// Debugger.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;

export default React.memo(Debugger);
