import React from 'react';
import {ElementSchema} from 'interfaces/Elements';
import SerialChartV3 from '../SerialV3';

type Props = {
  element: ElementSchema;
  style: {[key: string]: any};
};

function LineChart(props: Props) {
  return (
    <div style={{width: '100%', height: '100%'}}>
      <SerialChartV3 type="smoothedLine" {...props} />
    </div>
  );
}

export default LineChart;
