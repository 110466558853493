// @flow
// import {actions} from './index';
import socket from 'socket.io-client';
// import {APIs} from '../../adapters/httpAdapter/http';
import {apiBaseUrl} from './config';

let SocketClient: SocketIOClient.Socket;
const entitiesListeners: {
  [eventName: string]: {
    cb: Function;
    eventName: string;
  };
} = {};

export const getSocketInstance = (url = apiBaseUrl, path = 'notification') => {
  if (!url) {
    throw new Error('Socket URL is not provided!');
  }
  console.info('Initializing socket to ', path);
  return socket(url, {
    path: `/${path}/socket.io`,
  });
};

export const connectPublicSocket = (publicId: string, pin?: string) => {
  if (!SocketClient) {
    console.info('Initializing public socket', publicId);
    SocketClient = socket(apiBaseUrl, {
      path: '/public/socket.io',
      port: '3001',
    });
    // sendSocketToken();
    SocketClient.emit('join', publicId, pin);
    registerSocketEvents(SocketClient);
  }
};

export const connectPrivateSocket = (token: string, organizationId: string) => {
  if (!SocketClient) {
    console.info('Initializing socket');
    SocketClient = socket(apiBaseUrl, {
      path: '/notification/socket.io',
    });
    sendSocketToken(token);
    SocketClient.emit('join', organizationId);
    registerSocketEvents(SocketClient);
  }
};

export const disconnectSocket = () => {
  SocketClient.disconnect();
};

export const emitMessage = (message: string, payload: any) => {
  console.info(`* Sending message ${message}.`, payload);
  SocketClient.emit(message, payload);
};

// export const removeAllEventListeners = () => {
//   console.info('* Removing events.');
//   SocketClient.removeEventListener();
//   // dispatch(actions.setActiveEvents({}));
//   entitiesListeners = {};
// };

export const listenOnEvent = (eventName: string, callback: Function, identifier?: string) => {
  const customCallBack = (data: any) => {
    callback(data);
  };
  const eventId = identifier || eventName;
  entitiesListeners[eventId] = {cb: customCallBack, eventName};
  // SocketClient.emit('join', params.organizationId);
  console.log('=== Listening on...', eventName);
  SocketClient.on(eventName, customCallBack);
};

export const removeEventListener = (identifier: string) => {
  const listener = entitiesListeners[identifier];
  if (listener) {
    SocketClient.removeEventListener(listener.eventName, listener.cb);
  }
};

const registerSocketEvents = (SocketClient: SocketIOClient.Socket) => {
  SocketClient.on('connect', () => {
    console.info('* Socket connected.');
  });

  SocketClient.on('disconnect', (reason: string) => {
    console.info('* Socket disconnected.', reason);
  });

  SocketClient.on('reconnecting', (attempt: string) => {
    console.info('* Socket reconnecting.', attempt);
  });

  SocketClient.on('connect_error', (error: string) => {
    console.error('* Socket connect error.', error);
  });

  SocketClient.on('connect_timeout', (timeout: string) => {
    console.error('* Socket timeout.', timeout);
  });

  SocketClient.on('error', (err: any) => {
    console.log('== Socket err', err);
  });
};

// const handleClientConnect = () => (dispatch: Function): void => {
//   dispatch(actions.setStatus(true));
// };

// const handleClientDisconnect = () => (dispatch: Function): void => {
//   dispatch(actions.setStatus(false));
// };

// export const reconnectSocket = () => (dispatch: Function): void => {};

const sendSocketToken = (token: string) => {
  SocketClient.on('connect', () => {
    SocketClient.emit('authentication', {authorization: token});
  });
};
