import {GroupDataByTime, MetricsData} from '../interfaces/Data';
import {ElementDataSource} from '../interfaces/Elements';

// export type telemetryObj = {
//   organizationId?: string;
//   deviceId?: string;
//   time: string;
//   key: string;
//   resourceId: string;
//   value: string | number;
//   valueType: 'string' | 'number' | 'boolean';
//   resourceName?: string;
//   assetName?: string;
//   resourceType: string;
//   source: string;
// };

type Accumulator = {
  [time: string]: MetricsData;
};

/*
 * Map telemetry raw data to be grouped be their timestamp
 * @param {array} data array of raw data
 * @param {object[]} options object of options
 * @returns {array} array of mapped data
 */
// export const mapTelemetriesFromRawData = (data: telemetryObj[] = []): GroupedData[] => {
//   return Object.values(
//     data.reduce<accumulatorObj>((accumulator, telemetry) => {
//       const {time: ts, key, value, resourceId, resourceName, assetName} = telemetry;
//       if (accumulator[ts]) {
//         accumulator[ts][key] = value;
//         accumulator[ts].ts = ts;
//         accumulator[ts].resourceName = resourceName;
//         accumulator[ts].assetName = assetName;
//         accumulator[ts].resourceId = resourceId;
//       } else {
//         accumulator[ts] = {
//           [key]: value,
//           ts,
//           resourceName,
//           assetName,
//           resourceId,
//         };
//       }
//       return accumulator;
//     }, {}),
//   );
// };

export const groupDataByTime: GroupDataByTime = data => {
  return Object.values(
    data.reduce<Accumulator>((accumulator, dataRecord) => {
      const {time} = dataRecord;
      accumulator[time] = {
        ...accumulator[time],
        ...dataRecord,
      };
      return accumulator;
    }, {}),
  );
};

export const getDataSourceEventName = (dataSource: ElementDataSource): string | void => {
  if (dataSource.dataSourceValue.identifier && dataSource.dataType) {
    return `${dataSource.dataSourceValue.identifier}-${dataSource.dataType}`;
  }
};
