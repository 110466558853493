import React, {useCallback, useEffect, useState} from 'react';
import Typography from 'antd/es/typography';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Tag from 'antd/es/tag';
import {Log, LogTypes} from '../store/interfaces';
import {useSelector} from 'react-redux';
import {getLogs} from '../index';
import useElements from '../../../Elements';

const {Text} = Typography;
const {CheckableTag} = Tag;

const logTypes: {[key: string]: string} = {
  [LogTypes.ERROR]: 'Error',
  [LogTypes.INFO]: 'Info',
  [LogTypes.SUCCESS]: 'Success',
};

const logTypesColorMap: {[key: string]: string} = {
  [LogTypes.ERROR]: 'red',
  [LogTypes.INFO]: 'blue',
  [LogTypes.SUCCESS]: 'green',
};

function Debug(props: {height: number}) {
  const {getSelectedElement} = useElements();
  const selectedElement = useSelector(getSelectedElement);
  const logs = useSelector(getLogs);
  const [filteredLogs, setFilteredLogs] = useState<Log[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([
    LogTypes.WARNING,
    LogTypes.SUCCESS,
    LogTypes.INFO,
    LogTypes.ERROR,
  ]);
  const changeHandler = useCallback((type: string, value: boolean) => {
    setSelectedTypes(prevTypes => {
      return value ? [...prevTypes, type] : prevTypes.filter(selType => selType !== type);
    });
  }, []);

  useEffect(() => {
    setFilteredLogs(
      logs.filter(
        ({type, source}) =>
          selectedTypes.includes(type) &&
          (selectedElement ? source && source.id === selectedElement.id : true),
      ),
    );
  }, [logs, selectedElement, selectedTypes]);

  return (
    <div style={{overflow: 'auto'}}>
      <Row style={{margin: '10px'}} type="flex" justify="start">
        {Object.keys(logTypes).map((type, index) => {
          const isChecked = selectedTypes.includes(type);
          return (
            <Col key={index}>
              <CheckableTag
                style={
                  isChecked
                    ? {backgroundColor: logTypesColorMap[type]}
                    : {color: logTypesColorMap[type], border: '1px solid'}
                }
                checked={isChecked}
                onChange={value => changeHandler(type, value)}
              >
                {logTypes[type]}
              </CheckableTag>
            </Col>
          );
        })}
      </Row>
      <Card bodyStyle={{overflow: 'auto'}}>
        {filteredLogs.map((log, index) => (
          <Typography key={index}>
            <Text>{log.time}</Text>
            <Text style={{color: logTypesColorMap[log.type]}}>{` ${log.type} `}</Text>
            <Text>{`${log.message}`}</Text>
          </Typography>
        ))}
      </Card>
    </div>
  );
}

export default Debug;
