import Gauge from './Gauge';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from './interfaces';
import {DataTypes} from '../../../../interfaces/DataSources';

const CardBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'gauge',
  name: 'Gauge',
  category: 'KPI',
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.TELEMETRIES, DataTypes.ATTRIBUTES],
    maxNumberOfKeys: 1,
    selectMode: 'default',
  },
  initialSettings: {
    maxValue: 260,
    valueInterval: 20,
  },
  aggregationOptions: {},
  Component: Gauge,
  settings: Settings,
  width: 6,
  height: 6,
};
export default CardBluePrint;
