import format from 'date-fns/format';

export const DEFAULT_DATE_FORMAT = 'd/M/yyyy HH:mm:ss';

export const getFormattedDate = (date: Date, dateFormat = DEFAULT_DATE_FORMAT) => {
  return format(date, dateFormat);
};

export const getCurrentTime = () => {
  return format(new Date(), 'hh:mm:ss');
};
