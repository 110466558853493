import Component from './PieChart';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from '../interfaces';
import {DataTypes} from 'interfaces/DataSources';

const PieChartBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'pieChart',
  name: 'Pie Chart',
  category: 'Charts',
  Component,
  settings: Settings,
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.ATTRIBUTES, DataTypes.TELEMETRIES],
  },
  width: 6,
  height: 6,
};

export default PieChartBluePrint;
