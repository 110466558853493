import React from 'react';
import styled from 'styled-components/macro';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import colors from '../../../utils/colors';

type Props = {
  isVisible?: boolean;
};

export const IconStyle = styled.span<Props>`
  padding: 6px 15px;
  font-size: 16px;
  border-right: 1px solid #e0e0e0;
  cursor: pointer;
  ${props => props.isVisible && `background-color: whitesmoke;`}
  &:hover {
    background-color: whitesmoke;
  }
`;

export const StyledContainer = styled.div`
  display: ${(props: {visible?: boolean}) => (props.visible ? 'initial' : 'none')};
  padding: 10px;
  position: absolute;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.9);
  max-height: 300px;
  left: 60px;
  z-index: 500;
  border-radius: 5px;
  border: 1px solid ${colors.border};
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  & > div {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }
  }
`;

export const StyledButton = styled(({visible, tooltipTitle, tooltipPlacement, ...props}) => (
  <Tooltip placement={tooltipPlacement} title={tooltipTitle}>
    <Button shape="circle" size="large" {...props} />
  </Tooltip>
))`
  &&& {
    margin-bottom: 20px;
    background-color: ${(props: {visible?: boolean}) => (props.visible ? '#b1acfa' : 'inherit')};
    color: ${(props: {visible?: boolean}) => (props.visible ? 'white' : '#b1acfa')};
    border-color: ${(props: {visible?: boolean}) => (props.visible ? 'white' : '#b1acfa')};
  }
  &&&:hover {
    background-color: #b1acfa;
    color: white;
  }
`;
