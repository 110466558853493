import useElements from '../../Elements';
import {Commands} from 'interfaces/Editor';
import {getCurrentState} from '../../../store';
import {setElementInCopy, setChangesStatus} from 'modules/EditMode/Editor';
import {useDispatch} from 'react-redux';

type UseCommands = {
  commands: Commands;
};

function useCommands(): UseCommands {
  const {setElements, addElement, setSelectedElement, deleteElement} = useElements();
  const dispatch = useDispatch();
  const copy = (): void => {
    const {selectedElement} = getCurrentState();

    if (selectedElement !== null) {
      dispatch(setElementInCopy(selectedElement));
      setSelectedElement(null);
    }
  };

  const hide = (): void => {
    const {selectedElement} = getCurrentState();
    if (selectedElement) {
      deleteElement(selectedElement.id);
      setSelectedElement(null);
    }
  };

  const paste = (position: {left?: number; top?: number}) => {
    const {elementInCopy} = getCurrentState();
    if (elementInCopy) {
      addElement({...elementInCopy, ...position});
      dispatch(setElementInCopy(null));
    }
  };

  const forward = (): void => {
    const {elements, selectedElement} = getCurrentState();
    const newElements = {...elements};
    if (selectedElement) {
      delete newElements[selectedElement.id];
      newElements[selectedElement.id] = elements[selectedElement.id];
      setElements(newElements);
      dispatch(setChangesStatus(true));
    }
  };

  const back = (): void => {
    const {elements, selectedElement} = getCurrentState();
    let newElements = {...elements};
    if (selectedElement) {
      delete newElements[selectedElement.id];
      newElements = {[selectedElement.id]: elements[selectedElement.id], ...newElements};
      setElements(newElements);
      dispatch(setChangesStatus(true));
    }
  };

  const cut = () => {
    const {selectedElement} = getCurrentState();

    if (selectedElement !== null) {
      dispatch(setElementInCopy({...selectedElement, parentId: undefined}));
      setSelectedElement(null);
      deleteElement(selectedElement.id);
    }
  };

  return {
    commands: {
      copy,
      cut,
      hide,
      paste,
      forward,
      back,
    },
  };
}

export default useCommands;
