import { DataSourceTypes, DataTypes, DataSourceValue, DataSourceOptions, DataResponse } from "./DataSources";
import {Filter} from 'utils/filtering';
import {Aggregation, AggregationOptions} from './Aggregation';
import {SettingsElementProps} from './Settings';
import {ConnectedComponentClass} from "antd/es/form/interface";
import {OutputSelector} from "reselect";
import {Store} from "store/interfaces";

export interface Params {
  organizationId?: string;
  applicationId?: string;
  visualizationId?: string;
}

export interface PublicParams {
  publicId?: string;
  pageId?: string;
  componentId?: string;
}

export interface BaseComponentNode {
  type: string;
  name: string;
  category?: string;
}

export interface LibraryItem extends BaseComponentNode {
  canContainComponents?: boolean;
  width?: number;
  height?: number;
}

export interface SelectedElementPosition {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export interface ElementPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface ElementDataSource {
  dataSourceType: DataSourceTypes;
  dataSourceValue: DataSourceValue;
  dataType: DataTypes;
  dataKeys?: string[];
}

export interface ElementSchema<Settings = {}> extends ElementPosition, BaseComponentNode {
  id: string;
  index?: number;
  styles?: {
    content: string;
  };
  childrenIds?: string[];
  childElements?: ElementSchema[];
  parentId?: string;
  pageId: string;
  saveSettings?: any;
  dataSource?: ElementDataSource | null;
  aggregation?: Aggregation;
  timeWindow?: {
    type:  'HISTORY' | 'REAL_TIME';
    startValues?: {
      days?: number;
      hours?: number;
      minutes?: number;
      seconds?: number;
    };
    from?: string;
    to?: string;
  };
  settings?: Settings & {isFull?: boolean};
  widthPerc?: number;
  leftPerc: number;
  lastChanged?: string;

  //canInteract: boolean;
}

export interface IEditorComponent extends ElementSchema {
  childElements?: ElementSchema[];
  lastChanged?: string;
  // SettingsComponent: JSX.Element;
}

export enum ElementCommandsEnum {
  debugger = 'debugger',
  copy = 'copy',
  cut = 'cut',
  copyId = 'copyId',
  paste = 'paste',
  delete = 'delete',
  bringForward = 'bringForward',
  bringBack = 'bringBack',
}

// export interface Commands {
//   copy(): void;
//   cut(): void;
//   paste(position: {}): void;
//   hide(id?: string | null): void;
//   forward(): void;
//   back(): void;
// }

export type Elements = {[id: string]: ElementSchema};

// export interface MetricsData {
//   key: string;
//   resourceId: string;
//   resourceType: string;
//   resourceName?: string;
//   value: string | number;
//   valueType: 'string' | 'number' | 'boolean';
//   time: string;
//   source: string;
// }

export interface ElementsOperations {
  setSelectedElement(element: ElementSchema | null): void;
  fetchElements (): void;
  setElements(elements: Elements | ((e: Elements) => void)): void;
  addElement(element: Omit<ElementSchema, 'id' | 'pageId' | 'name'>): void;
  updateElement(element: ElementSchema, syncWithBackend: boolean): void;
  updateElements(elements: Elements): void;
  saveElements(elements?: ElementSchema[]): void;
  debounceSaveElements(): void;
  clearElements(): void;
  updateElementDataSource(elementId: string, dataSource: ElementDataSource): void;
  updateElementSettings(elementId: string, settings: any): void;
  updateElementStyle(elementId: string,styles: string): void;
  deleteElement(elementId: string): void;
  getElementData(
    element: Pick<ElementSchema, 'id' | 'dataSource'>,
    options?: RequestDataOptions,
    transformData?: boolean,
  ): Promise<DataResponse>;
  getElementsWithInjectedChildren: OutputSelector<Store,IEditorComponent[],Function>;
  getSelectedElement:  OutputSelector<Store,ElementSchema | null,Function>;
  getElements: OutputSelector<Store,Elements,Function>
}

export interface RequestDataOptions {
  lastValue?: boolean;
  last?: boolean;
  filters?: Filter[];
  sort?: string;
  pageSize?: number;
  pageNumber?: number;
  transformToNumber?: boolean;
}

type Component = (props: any) => JSX.Element;

interface Settings <SettingsSchema> {
  name: string;
  SettingsComponent: ConnectedComponentClass<(props: any) =>
    JSX.Element, SettingsElementProps<SettingsSchema>> | Component;
}
type Styles = {name: string; Component: (props: any) => JSX.Element};
type Conditions = {name: string; Component: (props: any) => JSX.Element};

type AnyComponent = (props: any) => any;

export interface ComponentCharacteristics <SettingsSchema> extends BaseComponentNode {
  name: string;
  category: string;
  dataSourcesOptions?: DataSourceOptions;
  aggregationOptions?: AggregationOptions;
  isTimeWindowEnabled?: boolean;
  Styles?: Styles;
  Conditions?: Conditions;
  settings: Settings<SettingsSchema> | Settings<SettingsSchema>[];
  Component: ConnectedComponentClass<(props: any) => JSX.Element,any> | AnyComponent;
  width?: number;
  height?: number;
  isDroppable?: boolean;
  initialSettings?: {
    [key: string]: any;
  };
  // {element: ElementSchema; children?: React.ReactNode}
}
