import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import {SettingsElementProps} from '../../../../interfaces/Settings';
import {WrappedFormUtils} from 'antd/es/form/Form';
import * as interfaces from './interfaces';
import FormItemSettings from '../FormItemSettings';
import ControlItemSettings from '../ControlItemSettings';

const FormItem = Form.Item;

interface Props extends SettingsElementProps<interfaces.Settings> {
  form: WrappedFormUtils;
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function Settings(props: Props) {
  const {
    element,
    element: {parentId, settings},
    form,
    form: {getFieldDecorator},
  } = props;

  return (
    <Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        {parentId && <FormItemSettings form={form} element={element} />}
        <FormItem label="Custom On Key" style={{width: '350px'}} {...formItemLayout}>
          {getFieldDecorator('settings.customOnKey', {
            initialValue: (settings && settings.customOnKey) || '',
          })(<Input />)}
        </FormItem>
        <FormItem label="Custom Off Key" style={{width: '350px'}} {...formItemLayout}>
          {getFieldDecorator('settings.customOffKey', {
            initialValue: (settings && settings.customOffKey) || '',
          })(<Input />)}
        </FormItem>
        <FormItem label="Custom On Payload" style={{width: '400px'}} {...formItemLayout}>
          {getFieldDecorator('settings.customOnPayload', {
            initialValue: (settings && settings.customOnPayload) || '',
          })(<Input />)}
        </FormItem>
        <FormItem label="Custom Off Payload" style={{width: '400px'}} {...formItemLayout}>
          {getFieldDecorator('settings.customOffPayload', {
            initialValue: (settings && settings.customOffPayload) || '',
          })(<Input />)}
        </FormItem>
        <ControlItemSettings {...props} />
      </div>
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(Settings),
  },
];
