export const alarmStatus = {
  1: {
    name: 'active',
    code: 1,
    color: '#ea5254',
  },
  2: {
    name: 'acknowledged',
    code: 2,
    color: '#49a7da',
  },
  3: {
    name: 'cleared',
    code: 3,
    color: '#4f9668',
  },
};

export const alarmSeverity = {
  3: {
    title: 'critical',
    key: 'CRITICAL',
    name: 'alarm.severity-critical',
    class: 'tb-critical',
    color: '#960101',
    code: 3,
  },
  2: {
    title: 'major',
    key: 'MAJOR',
    name: 'alarm.severity-major',
    class: 'tb-major',
    color: 'orange',
    code: 2,
  },
  1: {
    title: 'minor',
    key: 'MINOR',
    name: 'alarm.severity-minor',
    class: 'tb-minor',
    color: '#858585',
    code: 1,
  },
};

export const commandsDataTypes = {
  string: 'String',
  integer: 'Integer',
  boolean: 'Boolean',
  enum: 'Enum',
};

export const deviceProvFileHeaders = {
  uniqueIdentifier: 'uniqueIdentifier',
  deviceName: 'deviceName',
  deviceType: 'deviceType',
};

export const deviceTypes = {
  GATEWAY: 'GATEWAY',
  STANDALONE: 'STANDALONE',
  PERIPHERAL: 'PERIPHERAL',
};

export const dataTypes = {
  TELEMETRIES: 'TELEMETRIES',
  ATTRIBUTES: 'ATTRIBUTES',
  ALARMS: 'ALARMS',
  EVENTS: 'EVENTS',
  COMMANDS: 'COMMANDS',
  DEVICELOGS: 'DEVICELOG',
};

export const uiBuilderWidgetsTypes = {
  button: 'BUTTON',
  input: 'INPUT',
  text: 'TEXT',
  card: 'CARD',
  gauge: 'GAUGE',
  textArea: 'TEXTAREA',
  container: 'CONTAINER',
  image: 'IMAGE',
  grid: 'GRID',
  lineChart: 'LINECHART',
  angularGauge: 'ANGULARGAUGE',
  linearGauge: 'LINEARGAUGE',
  liquidGauge: 'LIQUIDGAUGE',
  columnChart: 'COLUMNCHART',
  pieChart: 'PIECHART',
  telemetryTable: 'TELEMETRYTABLE',
  alarmsTable: 'ALARMSTable',
  eventsTable: 'EVENTSTABLE',
  code: 'Code',
  switch: 'Switch',
  slider: 'Slider',
  menu: 'Menu',
  markerMaps: 'MARKERMAPS',
  realTimeMaps: 'REALTIMEMAPS',
  dataTable: 'DATATABLE',
};

export const uiBuilderActions = {
  settings: 'SETTINGS',
  editContent: 'EDIT_CONTENT',
  copy: 'COPY',
  cut: 'CUT',
  paste: 'PASTE',
  delete: 'DELETE',
  bringForward: 'BRING_FORWARD',
  bringBack: 'BRING_BACK',
};

export const communicationMethods = {
  DEFAULT: 'DEFAULT',
  MODBUS: 'MODBUS',
};
