// import {Translate} from 'react-redux-i18n';
import React, {useCallback, useMemo} from 'react';
import Table from 'antd/es/table';
import {getFormattedDate} from 'utils/dateUtils';
import {alarmSeverity, alarmStatus} from 'utils/types';
import {ElementSchema} from 'interfaces/Elements';
import {operators} from 'utils/filtering';
import {Settings} from '../interfaces';
import useTable from '../useTable';
import useTableColumns from '../useColumns';

type alarmObj = {[propName: string]: string | number};

const severityMap = {
  1: 'minor',
  2: 'major',
  3: 'critical',
};

const statusMap = {
  1: 'active',
  2: 'acknowledged',
  3: 'cleared',
};

const severityColorMap = {
  1: 'orange',
  2: 'red',
  3: undefined,
};

const statusColorMap = {
  1: 'red',
  2: 'orange',
  3: 'green',
};

type Props = {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
};

interface Column {
  [propName: string]: string | number | Function | {}[];
}

function AlarmsTable(props: Props) {
  const {
    data: {data, totalCount},
    isLoading,
    updatePagination,
    setTableFilters,
  } = useTable<alarmObj[]>({...props, transformData: false});
  const {
    style,
    element: {settings},
  } = props;
  const onPaginationChange = useCallback(
    pageNumber => {
      updatePagination({pageNumber});
    },
    [updatePagination],
  );

  // @ts-ignore
  const initialColumns = useMemo(
    (): Column[] => [
      {
        title: 'Date',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (date: string) => <span>{getFormattedDate(new Date(date))}</span>,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Severity',
        dataIndex: 'severity',
        key: 'severity',
        filters: [
          {
            text: alarmSeverity[1].name,
            value: 1,
          },
          {
            text: alarmSeverity[2].name,
            value: 2,
          },
          {
            text: alarmSeverity[3].name,
            value: 3,
          },
        ],
        render: (sev: 1 | 2 | 3) => (
          <span style={{color: severityColorMap[sev]}}>{severityMap[sev]}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '20%',
        filters: [
          {
            text: alarmStatus[1].name,
            value: 1,
          },
          {
            text: alarmStatus[2].name,
            value: 2,
          },
          {
            text: alarmStatus[3].name,
            value: 3,
          },
        ],
        render: (status: 1 | 2 | 3) => (
          <span style={{color: statusColorMap[status]}}>{statusMap[status]}</span>
        ),
      },
    ],
    [],
  );

  const [columns] = useTableColumns({element: props.element, initialColumns});

  const handleTableChange = (pagination: {}, filters: Record<string | number, string[]>) => {
    setTableFilters({
      severity: {
        operator: operators.in,
        values: filters.severity && filters.severity.length ? [filters.severity] : [],
      },
      status: {
        operator: operators.in,
        values: filters.status && filters.status.length ? [filters.status] : [],
      },
    });
  };

  return (
    <Table
      loading={isLoading}
      rowKey="id"
      style={{
        height: '100%',
        backgroundSize: 'cover',
        ...style,
      }}
      pagination={{
        pageSize: settings && settings.rowsCount,
        total: totalCount,
        onChange: onPaginationChange,
      }}
      dataSource={data}
      size={settings && settings.tableSize}
      columns={columns}
      onChange={handleTableChange}
    />
  );
}

export default AlarmsTable;
