import React, {useCallback, useMemo} from 'react';
import {getFormattedDate} from 'utils/dateUtils';
import Table, {ColumnProps} from 'antd/es/table';
import {ElementSchema} from 'interfaces/Elements';
import {Settings} from '../interfaces';
import useTable from '../useTable';
import useTableColumns from '../useColumns';

type eventObj = {[propName: string]: string | number};

type Props = {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
};

function EventsTable(props: Props) {
  const {
    data: {data, totalCount},
    isLoading,
    updatePagination,
  } = useTable<eventObj[]>({...props, transformData: false});
  const {
    element: {settings},
    style,
  } = props;
  const onPaginationChange = useCallback(
    pageNumber => {
      updatePagination({pageNumber});
    },
    [updatePagination],
  );

  const initialColumns = useMemo(
    (): ColumnProps<{}>[] => [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'created_at',
        render: (date: string) => <span title={date}>{getFormattedDate(new Date(date))}</span>,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      },
    ],
    [],
  );

  const [columns] = useTableColumns({element: props.element, initialColumns});

  return (
    <Table
      loading={isLoading}
      style={{
        height: '100%',
        backgroundSize: 'cover',
        pointerEvents: 'auto',
        ...style,
      }}
      pagination={{
        total: totalCount,
        pageSize: settings && settings.rowsCount,
        onChange: onPaginationChange,
      }}
      dataSource={data}
      size={settings && settings.tableSize}
      rowKey="id"
      columns={columns}
    />
  );
}

EventsTable.whyDidYouRender = true;
export default React.memo(EventsTable);
