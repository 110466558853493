import Component from './ColumnChart';
import Settings from '../../Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from '../../interfaces';
import {DataTypes} from 'interfaces/DataSources';

const ColumnChartPluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'columnChart',
  name: 'Column Chart',
  category: 'Charts',
  Component,
  settings: Settings,
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.ATTRIBUTES, DataTypes.TELEMETRIES],
  },
  //Aggregation,
  width: 6,
  height: 6,
};
export default ColumnChartPluePrint;
