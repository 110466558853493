export interface MenuLink {
  name: string;
  path: string;
}

export interface IMenu {
  enabled: boolean;
  enableIcon: boolean;
  icon?: {
    url: string;
    width?: number;
    height?: number;
  };
  links: MenuLink[];
  position: 'TOP'; // | 'LEFT';
  style: {
    backgroundColor?: string;
  };
  settings: {};
}

export enum Actions {
  SET_MENU_OPTIONS = 'SET_MENU_OPTIONS',
  ADD_MENU_LINK = 'ADD_MENU_LINK',
}
