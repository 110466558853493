import React from 'react';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import {WrappedFormUtils} from 'antd/es/form/Form';
import * as interfaces from './input/interfaces';
import {ElementSchema} from '../../../interfaces/Elements';

const FormItem = Form.Item;

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function FormItemSettings(props: Props) {
  const {
    form: {getFieldDecorator},
    element: {settings},
  } = props;

  return (
    <>
      <FormItem label="Field Name" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.fieldName', {
          initialValue: settings && settings.fieldName,
        })(<Input />)}
      </FormItem>
      <FormItem label="Label" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.label', {
          initialValue: settings && settings.label,
        })(<Input />)}
      </FormItem>
      {/*<FormItem label="Default Value" style={{width: '300px'}} {...formItemLayout}>*/}
      {/*  {getFieldDecorator('settings.defaultValue', {*/}
      {/*    initialValue: settings && settings.defaultValue,*/}
      {/*  })(<Input />)}*/}
      {/*</FormItem>*/}
      {/*<FormItem label="Disabled" style={{width: '200px'}} {...formItemLayout}>*/}
      {/*  {getFieldDecorator('settings.disabled', {*/}
      {/*    initialValue: settings && settings.disabled,*/}
      {/*    valuePropName: 'checked',*/}
      {/*  })(<Switch />)}*/}
      {/*</FormItem>*/}
    </>
  );
}

export default FormItemSettings;
