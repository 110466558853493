import React from 'react';
import {StyledContainer} from './styles';
import Row from 'antd/es/row';
import {ActiveModal} from './interfaces';

interface Props {
  isVisible: boolean;
  setActiveModal: Function;
}

function SettingsDropDown(props: Props) {
  const {isVisible, setActiveModal} = props;

  return (
    <StyledContainer visible={isVisible}>
      <Row onClick={() => setActiveModal(ActiveModal.EDITOR_SETTINGS)}>Editor Settings</Row>
      <Row onClick={() => setActiveModal(ActiveModal.MENU_SETTINGS)}>Menu Settings</Row>
      <Row onClick={() => setActiveModal(ActiveModal.HOT_KEYS)}>Hot Keys</Row>
    </StyledContainer>
  );
}

export default SettingsDropDown;
