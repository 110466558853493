import React from 'react';
import {ElementSchema} from 'interfaces/Elements';
import Form from 'antd/es/form';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Container from '../Common/Container/Container';

interface Props {
  element: ElementSchema;
  updateElement(element: ElementSchema, syncWithBackend: boolean): void;
  handleDropElement: Function;
  form: WrappedFormUtils;
  style: {[key: string]: any};
  width: number | null;
}

function FormContainer(props: Props) {
  return (
    <Form style={{width: '100%', height: '100%'}}>
      <Container {...props} />
    </Form>
  );
}

export default Form.create<Props>({
  onValuesChange: props => {
    const {element, updateElement} = props;
    console.log('update element');
    updateElement({...element, lastChanged: new Date().getTime().toString()}, false);
  },
})(FormContainer);
