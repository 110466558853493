import React from 'react';
import Settings from '../MapSettings';
import Rules from '../Rules';

function MapSettings(props: any) {
  return <Settings {...props} showOSRM={true} mapType="realTime" />;
}

function RuleSettings(props: any) {
  return <Rules {...props} ruleType="telemetries" />;
}

export default [
  {
    name: 'Map Settings',
    SettingsComponent: MapSettings,
  },
  {
    name: 'Rules Settings',
    SettingsComponent: RuleSettings,
  },
];
