import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import {SettingsElementProps} from '../../../../interfaces/Settings';
import {WrappedFormUtils} from 'antd/es/form/Form';
import * as interfaces from './interfaces';
import FormItemSettings from '../FormItemSettings';
import ControlItemSettings from '../ControlItemSettings';
import InputNumber from 'antd/es/input-number';
import Checkbox from 'antd/es/checkbox';

const FormItem = Form.Item;

interface Props extends SettingsElementProps<interfaces.Settings> {
  form: WrappedFormUtils;
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function Settings(props: Props) {
  const {
    element,
    element: {parentId, settings},
    form,
    form: {getFieldDecorator},
  } = props;

  return (
    <Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        {parentId && <FormItemSettings form={form} element={element} />}
        <FormItem label="Min Value" style={{width: '350px'}} {...formItemLayout}>
          {getFieldDecorator('settings.minValue', {
            initialValue: (settings && settings.minValue) || 0,
          })(<InputNumber />)}
        </FormItem>
        <FormItem label="Max Value" style={{width: '350px'}} {...formItemLayout}>
          {getFieldDecorator('settings.maxValue', {
            initialValue: (settings && settings.maxValue) || 50,
          })(<InputNumber />)}
        </FormItem>
        <FormItem label="Initial Value" style={{width: '350px'}} {...formItemLayout}>
          {getFieldDecorator('settings.initialValue', {
            initialValue: (settings && settings.initialValue) || 0,
          })(<InputNumber />)}
        </FormItem>
        <FormItem label="Is Vertical" style={{width: '350px'}} {...formItemLayout}>
          {getFieldDecorator('settings.isVertical', {
            initialValue: (settings && settings.isVertical) || false,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </FormItem>
        <FormItem label="Label" style={{width: '350px'}} {...formItemLayout}>
          {getFieldDecorator('settings.text', {
            initialValue: settings && settings.text,
          })(<Input />)}
        </FormItem>
        <ControlItemSettings {...props} />
      </div>
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(Settings),
  },
];
