import {Reducer} from 'react';
import {Action, Actions, ElementsStore} from './interfaces';

const defaultState: ElementsStore = {elements: {}, selectedElement: null};

export const elementsStore: Reducer<ElementsStore, any> = (
  state = defaultState,
  action: Action,
) => {
  switch (action.type) {
    case Actions.SET_ELEMENTS:
      return {...state, elements: action.payload};
    case Actions.UPDATE_ELEMENTS:
      return {...state, elements: {...state.elements, ...action.payload}};
    case Actions.SET_SELECTED_ELEMENT:
      return {...state, selectedElement: action.payload};
    case Actions.UPDATE_ELEMENT:
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.payload.id]: {...state.elements[action.payload.id], ...action.payload},
        },
      };
    case Actions.ADD_ELEMENT:
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.payload.id]: action.payload,
        },
      };
    default:
      return state;
  }
};
