import React from 'react';
import {ElementSchema} from '../../../../interfaces/Elements';
import * as Gauges from 'react-canvas-gauges';
import useKPI from '../useKPI';
import {Settings} from './interfaces';

type Props = {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
};

function LinearGauge(props: Props) {
  const {value} = useKPI(props);
  const {
    style,
    element: {settings = {}, width, height},
  } = props;

  const getMajorTicks = (maxValue: number, minValue: number, interval: number) => {
    if ([maxValue, minValue, interval].some(arg => Number.isNaN(Number(arg)))) {
      return [];
    }
    const output = [minValue];
    let current = minValue;
    if (current < maxValue) {
      while (current < maxValue) {
        current += interval;
        output.push(current);
      }
    }
    return output;
  };

  const {
    title,
    minValue = 0,
    maxValue = 100,
    interval = 20,
    minorTicks = 2,
    metrics = [],
    unit = '',
  } = settings;
  return (
    <div style={{...style, position: 'absolute'}}>
      <Gauges.LinearGauge
        width={width}
        height={height}
        units={`${value} ${unit}`}
        title={title}
        startAngle={90}
        ticksAngle={180}
        value={value}
        minValue={minValue}
        maxValue={maxValue}
        majorTicks={getMajorTicks(maxValue, minValue, interval)}
        minorTicks={minorTicks + 1}
        animation={true}
        animationRule="linear"
        animationDuration={500}
        borders={false}
        highlights={metrics
          .filter(band => band)
          .map(band => ({
            from: band.startValue,
            to: band.endValue,
            color: `rgba(${Object.values(band.color).join(',')})`,
          }))}
      />
    </div>
  );
}

export default LinearGauge;
