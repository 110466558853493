import * as React from 'react';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/eclipse.css';
import {ElementSchema} from 'interfaces/Elements';
import {Settings} from './interfaces';
interface Props {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
}

function Code(props: Props) {
  const iframeName = Date.now();
  const isLive = false;
  const {
    element: {settings = {}},
    style,
  } = props;

  return (
    <div
      style={{
        textAlign: 'left',
        overflow: 'hidden',
        height: '100%',
        pointerEvents: !isLive && 'none',
        backgroundColor: 'white',
      }}
    >
      <iframe
        name={`${iframeName}`}
        title="code-iframe"
        srcDoc={settings.content || 'Right click to add code..'}
        width="100%"
        height="100%"
        frameBorder="0"
        sandbox="allow-scripts allow-top-navigation"
        style={style}
      />
    </div>
  );
}
export default Code;
