import React, {useCallback, useEffect, useState} from 'react';
import Tabs from 'antd/es/tabs';
import Container from '../Container/Container';
import {ElementSchema} from 'interfaces/Elements';
import * as interfaces from './interfaces';
import {StyledTab} from './Styled';
import {DroppableElement} from 'interfaces/Editor';
import useElements from '../../../Elements';

const {TabPane} = Tabs;
type Props = {
  element: ElementSchema<interfaces.ISettings>;
  handleDropElement: Function;
  width: number | null;
  style: {[key: string]: any};
};
function TabsWrapper(props: Props) {
  const {
    element: {settings = {}, id, childElements = []},
    style,
    handleDropElement,
  } = props;
  const tabs = settings.tabs || [];
  const tabsChilds = settings.tabsChilds || {};
  const {updateElementSettings} = useElements();
  const [activeTabName, setActiveTab] = useState<string>('');

  const handleDropTabElement = useCallback(
    (elementWithPosition: DroppableElement) => {
      const childElement = handleDropElement(elementWithPosition);
      updateElementSettings(id, {
        tabsChilds: {
          ...tabsChilds,
          [activeTabName]: (tabsChilds[activeTabName] || []).concat(childElement.id),
        },
      });
      return childElement;
    },
    [activeTabName, handleDropElement, id, tabsChilds, updateElementSettings],
  );
  useEffect(() => {
    setActiveTab((tabs[0] && tabs[0].name) || '');
  }, [tabs]);

  return (
    <StyledTab style={{height: '100%', ...style}} onChange={setActiveTab} activeKey={activeTabName}>
      {tabs.map((tab, index) => (
        <TabPane tab={tab.name} key={tab.name}>
          <Container
            {...props}
            handleDropElement={handleDropTabElement}
            element={{
              ...props.element,
              childElements: childElements.filter(child =>
                (tabsChilds[tab.name || ''] || []).includes(child.id),
              ),
            }}
          />
        </TabPane>
      ))}
    </StyledTab>
  );
}
export default TabsWrapper;
