import React, {useCallback, useEffect, useState} from 'react';
import ColorPicker, {ColorType} from 'commons/ColorPicker';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import {SettingsElementProps} from '../../../interfaces/Settings';
import {Settings, Metric} from './interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';

interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
}

const FormItem = Form.Item;

function MetricsSettings(props: Props) {
  const {
    form: {getFieldDecorator, setFieldsValue, getFieldValue},
    element: {settings},
  } = props;
  const [metrics, setMetrics] = useState<Metric[]>([]);

  const handleComponentColorChange = useCallback(
    (color: ColorType, index: number) => {
      setFieldsValue({
        [`settings.metrics[${index}].color`]: color,
      });
    },
    [setFieldsValue],
  );

  const addMetrics = useCallback(() => {
    setMetrics(prevMetrics =>
      prevMetrics.concat({color: {r: 255, g: 255, b: 255}, startValue: 0, endValue: 0}),
    );
  }, []);

  const removeMetric = useCallback(
    (itemIdex: number) => {
      const newMetrics = getFieldValue('settings.metrics').filter(
        (metric: Metric, index: number) => index !== itemIdex,
      );
      setMetrics(newMetrics);
      props.setSettings({settings: {metrics: newMetrics}});
    },
    [getFieldValue, props],
  );

  useEffect(() => {
    if (settings && settings.metrics) {
      setMetrics(settings.metrics);
    }
  }, [settings]);

  return (
    <Form layout="inline" labelAlign="left">
      <Row type="flex" justify="start">
        <Col style={{marginLeft: '10px'}}>
          <Button onClick={addMetrics} icon="plus" shape="circle" type="dashed" />
        </Col>
        <Col md={4} sm={8} xs={12} style={{margin: 'auto auto auto 10px'}}>
          New
        </Col>
      </Row>
      {metrics.map((metric, index) => {
        getFieldDecorator(`settings.metrics[${index}].color`, {initialValue: metric.color});
        return (
          <Row gutter={24} key={index}>
            <Col md={7} sm={16} xs={24}>
              <FormItem label="Metric Color" className="full-width">
                <ColorPicker
                  initialValue={metric.color}
                  handleColorChange={(color: ColorType) => {
                    handleComponentColorChange(color, index);
                  }}
                />
              </FormItem>
            </Col>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.metrics[${index}].startValue`, {
                  initialValue: metric.startValue,
                })(<Input placeholder="Start Value" />)}
              </FormItem>
            </Col>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.metrics[${index}].endValue`, {
                  initialValue: metric.endValue,
                })(<Input placeholder="End Value" />)}
              </FormItem>
            </Col>
            <Col md={3} sm={16} xs={24}>
              <Button style={{marginBottom: 24}} onClick={() => removeMetric(index)} icon="close" />
            </Col>
          </Row>
        );
      })}
    </Form>
  );
}

export default MetricsSettings;
