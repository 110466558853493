import React, {useCallback, useEffect, useState} from 'react';
import Form from 'antd/es/form';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Collapse from 'antd/es/collapse';
import Button from 'antd/es/button';
import immer from 'immer';
import DataSourceFields from 'commons/DataSources';
// import components from 'modules/Components';
import {ElementSchema} from 'interfaces/Elements';
import AggregationSettings from 'commons/Aggregation';
import TimeWindow from 'commons/TimeWindow/TimeWindow';
// import {DataSourceFields as IDataSourceFields} from 'interfaces/DataSources';

const {Panel} = Collapse;
const {Group} = Button;
// const {Option} = Select;
// const {Item: FormItem} = Form;

// const DataSources = ['cervello'];

interface Props {
  form: WrappedFormUtils;
  selectedElement: ElementSchema;
  updateElement: Function;
  aggregationOptions: any;
  isTimeWindowEnabled: boolean;
}

function DataSource(props: Props) {
  const {form, aggregationOptions, selectedElement, isTimeWindowEnabled, updateElement} = props;
  const [restoredElement, setRestoredElement] = useState<ElementSchema>();

  const ResetHandler = useCallback(() => {
    if (!restoredElement) {
      return;
    }
    const {dataSource} = restoredElement;
    const restoredData = {
      ...(restoredElement.dataSource || {}),
      dataSourceValue: {
        key:
          dataSource && dataSource.dataSourceValue
            ? dataSource.dataSourceValue.identifier
            : undefined,
        label:
          dataSource && dataSource.dataSourceValue ? dataSource.dataSourceValue.name : undefined,
      },
    };

    form.setFieldsValue(restoredData);
  }, [form, restoredElement]);

  const submitData = useCallback(() => {
    form.validateFields((err, values) => {
      if (!err) {
        const newValues = immer(values, (draft: any) => {
          if (draft.dataSource) {
            if (draft.dataSource.dataSourceValue) {
              draft.dataSource.dataSourceValue = {
                identifier: draft.dataSource.dataSourceValue.key,
                name: draft.dataSource.dataSourceValue.label,
              };
              delete draft.dataSource.dataSourceValue.label;
              delete draft.dataSource.dataSourceValue.key;
            }

            if (draft.dataSource.dataKeys && typeof draft.dataSource.dataKeys === 'string') {
              draft.dataSource.dataKeys = [draft.dataSource.dataKeys];
            }
          }
          if (draft.aggregation && !draft.aggregation.func) {
            draft.aggregation = null;
          }
          if (draft.timeWindow && draft.timeWindow.type === 'HISTORY') {
            if (draft.timeWindow.from) {
              draft.timeWindow.from = `${draft.timeWindow.from.unix() * 1000}`;
              delete draft.timeWindow.startValues;
            } else if (draft.timeWindow.to) {
              draft.timeWindow.to = `${draft.timeWindow.to.unix() * 1000}`;
              delete draft.timeWindow.startValues;
            } else if (draft.timeWindow.range && draft.timeWindow.range.length) {
              draft.timeWindow.from = `${draft.timeWindow.range[0].unix() * 1000}`;
              draft.timeWindow.to = `${draft.timeWindow.range[1].unix() * 1000}`;
              delete draft.timeWindow.startValues;
            } else if (
              draft.timeWindow.startValues &&
              !(
                draft.timeWindow.startValues.days ||
                draft.timeWindow.startValues.seconds ||
                draft.timeWindow.startValues.hours ||
                draft.timeWindow.startValues.minutes
              )
            ) {
              draft.timeWindow = {};
            }
            delete draft.timeWindow.range;
          } else if (draft.timeWindow && draft.timeWindow.type === 'REAL_TIME') {
            delete draft.timeWindow.from;
            delete draft.timeWindow.to;
          } else if (draft.timeWindow && draft.timeWindow.type === 'NONE') {
            draft.timeWindow = {};
          }
        });
        updateElement(selectedElement.id, newValues);
      }
    });
  }, [form, selectedElement.id, updateElement]);

  useEffect(() => {
    if (!selectedElement) {
      return;
    }
    if (restoredElement ? selectedElement.id !== restoredElement.id : true) {
      setRestoredElement(selectedElement);
    }
  }, [restoredElement, selectedElement, setRestoredElement]);

  return (
    <Form>
      <Collapse defaultActiveKey={['DataSourceQuery']}>
        {/*<Panel header="Data Source" key="DataSource">*/}
        {/*  <FormItem {...formItemLayout} label="Data Source">*/}
        {/*    {form.getFieldDecorator('dataSource.dataSource', {*/}
        {/*      initialValue: ['cervello'],*/}
        {/*    })(*/}
        {/*      <Select>*/}
        {/*        {DataSources.map(type => (*/}
        {/*          <Option key={type}>{type}</Option>*/}
        {/*        ))}*/}
        {/*      </Select>,*/}
        {/*    )}*/}
        {/*  </FormItem>*/}
        {/*</Panel>*/}
        <Panel header="Data Source Query" key="DataSourceQuery">
          <DataSourceFields element={selectedElement} form={form} />
        </Panel>
        {aggregationOptions && (
          <Panel header="Aggregation" key="aggregation">
            <AggregationSettings
              form={form}
              element={selectedElement}
              options={aggregationOptions}
            />
          </Panel>
        )}
        {isTimeWindowEnabled && (
          <Panel header="Time Window" key="time-window">
            <TimeWindow form={form} element={selectedElement} />
          </Panel>
        )}
      </Collapse>
      <div style={{textAlign: 'center', marginTop: 20}}>
        <Group>
          <Button onClick={submitData} type="primary">
            Save
          </Button>
          <Button onClick={ResetHandler}>Reset</Button>
        </Group>
      </div>
    </Form>
  );
}

// DataSource.whyDidYouRender = Boolean(process.env.REACT_APP_ENABLE_DEBUG);

export default React.memo(
  Form.create<Props>({
    // onValuesChange: (props: Props, changedValues, allValues) => {
    //   const {selectedElement, updateElement} = props;
    //   if (!selectedElement) {
    //     return;
    //   }
    //   const {
    //     dataSourcesOptions: {
    //       visibleFields = [
    //         IDataSourceFields.DataType,
    //         IDataSourceFields.DataSourceType,
    //         IDataSourceFields.DataSourceValue,
    //         IDataSourceFields.DataKeys,
    //       ],
    //     },
    //   } = components[selectedElement.type];
    //
    //   const isValidData = visibleFields.every((field: string) => {
    //     const fieldName = field.toLowerCase().replace(/([-_][a-z])/g, group =>
    //       group
    //         .toUpperCase()
    //         .replace('-', '')
    //         .replace('_', ''),
    //     );
    //     // @ts-ignore
    //     const value = selectedElement.dataSource && selectedElement.dataSource[fieldName];
    //     allValues.dataSource[fieldName] = allValues.dataSource[fieldName] || value;
    //     return Boolean(allValues.dataSource[fieldName]);
    //   });
    //   if (allValues.timeWindow && allValues.timeWindow.type === 'HISTORY') {
    //     if (allValues.timeWindow.from) {
    //       allValues.timeWindow.from = `${allValues.timeWindow.from.unix() * 1000}`;
    //       delete allValues.timeWindow.startValues;
    //     } else if (allValues.timeWindow.to) {
    //       allValues.timeWindow.to = `${allValues.timeWindow.to.unix() * 1000}`;
    //       delete allValues.timeWindow.startValues;
    //     } else if (allValues.timeWindow.range && allValues.timeWindow.range.length) {
    //       allValues.timeWindow.from = `${allValues.timeWindow.range[0].unix() * 1000}`;
    //       allValues.timeWindow.to = `${allValues.timeWindow.range[1].unix() * 1000}`;
    //       delete allValues.timeWindow.startValues;
    //     } else if (
    //       allValues.timeWindow.startValues &&
    //       !(
    //         allValues.timeWindow.startValues.days ||
    //         allValues.timeWindow.startValues.seconds ||
    //         allValues.timeWindow.startValues.hours ||
    //         allValues.timeWindow.startValues.minutes
    //       )
    //     ) {
    //       allValues.timeWindow = {};
    //     }
    //     delete allValues.timeWindow.range;
    //     //allValues.timeWindow = undefined;
    //   } else if (allValues.timeWindow && allValues.timeWindow.type === 'REAL_TIME') {
    //     delete allValues.timeWindow.from;
    //     delete allValues.timeWindow.to;
    //   }
    //
    //   if (isValidData) {
    //     if (allValues.dataSource) {
    //       if (allValues.dataSource.dataSourceValue) {
    //         allValues.dataSource.dataSourceValue = {
    //           identifier:
    //             allValues.dataSource.dataSourceValue.identifier ||
    //             allValues.dataSource.dataSourceValue.key,
    //           name:
    //             allValues.dataSource.dataSourceValue.name ||
    //             allValues.dataSource.dataSourceValue.label,
    //         };
    //         delete allValues.dataSource.dataSourceValue.label;
    //         delete allValues.dataSource.dataSourceValue.key;
    //       }
    //
    //       if (allValues.dataSource.dataKeys) {
    //         if (typeof allValues.dataSource.dataKeys === 'string') {
    //           allValues.dataSource.dataKeys = [allValues.dataSource.dataKeys];
    //         } else if (!allValues.dataSource.dataKeys.length) {
    //           return;
    //         }
    //       }
    //     }
    //     if (allValues.aggregation && !allValues.aggregation.func) {
    //       allValues.aggregation = null;
    //     }
    //
    //     updateElement(selectedElement.id, allValues);
    //   }
    // },
  })(DataSource),
);
