import Leaflet from 'leaflet';
import {IRule, GroupedDevicesCoordinates, CoordinatesData} from '../interfaces';
import {SerialData} from 'interfaces/Data';

// TODO:
// update markers on socket data => return new markers data
// apply rules on telemetries return new telemtries
// listen and remove socket event listener
// match and replace content
// generate markers components
// generate markers data
// lat, lng validation
export const ruleConditionOperators: {[key: string]: string[]} = {
  string: ['===', '!==', 'startsWith', 'endsWith', 'indexOf'],
  number: ['>', '>=', '<', '<=', '===', '!=='],
  boolean: [],
};

export const evaluateRuleCondition = (rule: IRule, value: string | number) => {
  const {dataType = 'number', key, operator, ruleValue} = rule;
  if (!ruleValue || !operator || !key || !dataType || value === null || value === undefined) {
    return;
  }
  switch (dataType) {
    case 'string':
      switch (operator) {
        case '===':
          return value === ruleValue;
        case '!==':
          return value !== ruleValue;
        case 'indexOf':
          return typeof value === 'string' && value.indexOf(ruleValue) > -1;
        case 'startsWith':
          return typeof value === 'string' && value[operator](ruleValue);
        case 'endsWith':
          return typeof value === 'string' && value[operator](ruleValue);
        case 'Regex':
          const match = ruleValue.match(new RegExp('^/(.*?)/([gimy]*)$'));
          return match && new RegExp(match[1], match[2]).test(ruleValue);
        default:
          return null;
      }
    case 'number':
      if (ruleConditionOperators.number.includes(operator)) {
        return eval(`${value}${operator}${ruleValue}`);
      } else {
        break;
      }
    case 'boolean':
      return value === ruleValue;
    default:
      throw new Error(`Data type ${dataType} is not supported.`);
  }
};

export const getMapTilesUrl = (key: string, mapboxAPIKey: string) => {
  switch (key) {
    case 'mapbox':
      return `https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=${mapboxAPIKey}`;
    case 'terrain':
      return 'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png';
    case 'toner-lite':
      return 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png';
    case 'esri-wsm':
      return 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}';
    case 'esri-img':
      return 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
    case 'wikimedia':
      return 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png';
    default:
      return `https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=${mapboxAPIKey}`;
  }
};

export const getMapContribution = (key: string) => {
  switch (key) {
    case 'mapbox':
      return '<a href="https://www.mapbox.com/">Mapbox</a>';
    case 'terrain':
      return 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    case 'toner-lite':
      return 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    case 'esri-wsm':
      return 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012';
    case 'esri-img':
      return 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
    case 'wikimedia':
      return '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>';
    default:
      return 'Cervello Platform';
  }
};

export const style = {
  height: 'calc(100% - 45px)',
};

export const previewStyles = {
  height: '250px',
};

const getMarkerTitle = (
  title: undefined | string | Function,
  record: SerialData,
): string | undefined => {
  if (!title) {
    return undefined;
  }
  if (typeof title === 'function') {
    // TODO: select title from function
    return undefined;
  }
  return title;
};

const isAttributes = (content: string) => {
  return content.includes('attributes');
};

const isTelemetries = (content: string) => {
  return content.includes('telemetries');
};

const getKeyValue = (key: string) => {
  return key.split('.')[1];
};

export const getContent = ({
  content,
  attributes = {},
  telemetries = {},
}: {
  content?: string;
  telemetries?: {[key: string]: number | string};
  attributes?: {[key: string]: number | string};
}) => {
  const tester = /{{&(.*?)}}/g;
  if (!content) {
    return '';
  }
  return content.replace(tester, (fullmatch: string, originalKey: string): string => {
    if (isAttributes(originalKey)) {
      return attributes[getKeyValue(originalKey)].toString() || '';
    } else if (isTelemetries(originalKey)) {
      return telemetries[getKeyValue(originalKey)].toString() || '';
    } else {
      return '';
    }
  });
};

export const getIcon = (iconNumber = 0) => {
  return Number(iconNumber) === 8
    ? undefined
    : Leaflet.icon({
        iconUrl: require(`./images/markers/marker-icon-${iconNumber}.png`),
        popupAnchor: [0, -32],
      });
};

export const getMarkerIcon = ({
  rules = [],
  data,
  iconNumber = 0,
}: {
  rules: IRule[];
  data: {[key: string]: number | string};
  iconNumber: number;
}): Leaflet.Icon | undefined => {
  let markerNumber = iconNumber;

  rules.forEach(rule => {
    if (!rule.key) {
      return;
    }
    const value = data[rule.key];
    if (value && evaluateRuleCondition(rule, value)) {
      ({markerNumber = iconNumber} = rule);
    }
  });

  return getIcon(markerNumber);
};

export const groupDataBy = (data: SerialData[], groupBy: string[]) => {
  return data.reduce((accumulator: {[key: string]: {[key: string]: any}}, record) => {
    const {key, value, ...rest} = record;

    groupBy.reduce((obj, xkey, index) => {
      if (!obj[record[xkey]]) {
        obj[record[xkey]] = {};
      }
      if (index === groupBy.length - 1) {
        obj[record[xkey]] = {
          [key]: value,
          ...rest,
          ...obj[record[xkey]],
        };
      }
      return obj[record[xkey]];
    }, accumulator);

    return accumulator;
  }, {});
};

export const getCoordinatesFromSerialData = (
  locationData: {[key: string]: {[key: string]: any}}[],
  latKey: string,
  lngKey: string,
  defaultTitle: string = '',
): CoordinatesData => {
  return Object.values(
    locationData.reduce(
      (accumulator: GroupedDevicesCoordinates, deviceData): GroupedDevicesCoordinates => {
        Object.values(deviceData).forEach(record => {
          const {resourceName, resourceId, [latKey]: lat, [lngKey]: lng} = record;
          if (!accumulator[resourceId]) {
            accumulator[resourceId] = {coordinates: [], data: {}};
          }
          accumulator[resourceId].data = {...record, ...accumulator[resourceId].data};

          if (record[latKey] && record[lngKey]) {
            accumulator[resourceId].coordinates.push({
              lat: Number(lat),
              lng: Number(lng),
              markerTitle: resourceName ? `${resourceName}` : defaultTitle,
            });
          }
        });
        return accumulator;
      },
      {},
    ),
  );
};
