import Component from './Slider';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from './interfaces';

const SliderBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'slider',
  category: 'Common',
  name: 'Slider',
  Component,
  width: 6,
  height: 6,
  settings: Settings,
};

export default SliderBluePrint;
