import React, {memo, useMemo, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {
  CategoryName,
  LibraryComponent,
  SearchIcon,
  SearchInput,
  ElementsLibraryStyled,
  Library,
  Category,
  LibraryItem,
} from './StyledComponents';
import components from '../../Components';
import useCategorizedComponents, {IDraggableItem} from './useCategorizedComponents';
import {getLibraryVisibility} from '../Editor';

type CategorizedComponentsList = [string, IDraggableItem[]][];

function ElementsLibrary() {
  const visible = useSelector(getLibraryVisibility);
  const [filterValue, setSearchValue] = useState('');
  const memComponents = useMemo(() => Object.values(components), []);
  const categorizedComponents: CategorizedComponentsList = useCategorizedComponents({
    filterValue,
    components: memComponents,
  });
  const onSearch = useCallback((e: {target: HTMLInputElement}) => {
    const {value} = e.target;
    setSearchValue(value);
  }, []);

  return (
    <ElementsLibraryStyled visible={visible}>
      <SearchInput
        value={filterValue}
        addonAfter={<SearchIcon type="search" />}
        size="default"
        onChange={onSearch}
      />
      <Library>
        <div style={{overflow: 'auto', maxHeight: '50vh', width: '270px'}}>
          {categorizedComponents.map(([categoryName, components]) => {
            return (
              <Category key={categoryName}>
                <CategoryName>{categoryName}</CategoryName>
                <LibraryComponent>
                  {components.map(({Component}, index: number) => {
                    return <LibraryItem key={index}>{Component}</LibraryItem>;
                  })}
                </LibraryComponent>
              </Category>
            );
          })}
        </div>
      </Library>
    </ElementsLibraryStyled>
  );
}
// ElementsLibrary.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;

export default memo(ElementsLibrary);
