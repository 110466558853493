import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import Icon from 'antd/es/icon';
import Radio from 'antd/es/radio';
import Tooltip from 'antd/es/tooltip';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {Settings} from './interfaces';
import {SettingsElementProps} from 'interfaces/Settings';
import {WrappedFormUtils} from 'antd/es/form/Form';

const mockKeys = ['lat', 'lng'];
const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;
const {TextArea} = Input;
const cutomUrlHelpTexts: {[key: string]: string} = {
  'custom-xyz':
    'Please note that url must be like the following format : https://yourcustom.provider/{z}/{x}/{y}.png',
  'custom-wms':
    'Please note that url must be like the following format : https://yourcustom.provider/services/wms?layers=',
};
const cutomUrlHelpPatterns: {[key: string]: RegExp} = {
  'custom-xyz': new RegExp('{z}/{x}/{y}'),
  'custom-wms': new RegExp('\\?layers='),
};

const markerPlaceHolder = {
  markers: 'You can embed attribute data into tooltip body.\nFor example {{key}}',
  realTime: 'You can embed telemetry data into tooltip body.\nFor example {{key}}',
};

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 16},
  },
};

interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
  showOSRM?: boolean;
  mapType: 'markers' | 'realTime';
}

const MapSettings = (props: Props) => {
  const {
    element: {settings, dataSource},
    form,
    showOSRM = false,
    mapType,
  } = props;
  const {getFieldDecorator, getFieldValue, setFieldsValue} = form;
  const keys = dataSource ? dataSource.dataKeys || mockKeys : mockKeys;
  const isCustom = settings && settings.tilesKey && settings.tilesKey.includes('custom');

  return (
    <Form labelAlign="left" layout="inline">
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem {...formItemLayout} label="Latitude Key" className="full-width">
            {getFieldDecorator('settings.latKey', {
              initialValue: settings ? settings.latKey : '',
            })(
              <Select placeholder="Key">
                {keys.map((key, i) => (
                  <Option key={i} value={key}>
                    {key}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem {...formItemLayout} label="Longitude Key" className="full-width">
            {getFieldDecorator('settings.lngKey', {
              initialValue: settings ? settings.lngKey : '',
            })(
              <Select placeholder="Longitude Key">
                {keys.map((key, i) => (
                  <Option key={i} value={key}>
                    {key}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem {...formItemLayout} label="Map Zoom" className="full-width">
            {getFieldDecorator('settings.zoom', {
              initialValue: settings ? settings.zoom : 10,
            })(<InputNumber min={0} max={18} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem {...formItemLayout} label="Map Tiles" className="full-width">
            {getFieldDecorator('settings.tilesKey', {
              initialValue: settings ? settings.tilesKey : 'wikimedia',
            })(
              <Select
                placeholder="Choose Tile"
                onChange={() => {
                  if (isCustom) {
                    setFieldsValue({
                      settings: {
                        tilesCustomUrl: '',
                      },
                    });
                  }
                }}
              >
                <Option key="0" value="wikimedia">
                  Wikimedia
                </Option>
                <Option key="1" value="terrain">
                  Terrain
                </Option>
                <Option key="2" value="toner-lite">
                  Toner lite
                </Option>
                <Option key="3" value="mapbox">
                  Mapbox (Requires API key)
                </Option>
                <Option key="4" value="esri-wsm">
                  Esri (WorldStreetMaps)
                </Option>
                <Option key="5" value="esri-img">
                  Esri (Imagery)
                </Option>
                <Option key="6" value="custom-xyz">
                  Custom (XYZ) (On premises availability)
                </Option>
                <Option key="6" value="custom-wms">
                  Custom (WMS) (On premises availability)
                </Option>
              </Select>,
            )}
          </FormItem>
        </Col>
        {getFieldValue('settings.tilesKey') === 'mapbox' && (
          <Col md={8} sm={16} xs={24}>
            <FormItem
              className="full-width"
              {...formItemLayout}
              label={
                <span>
                  Api Key &nbsp;
                  <Tooltip
                    title={
                      <p>
                        <a
                          target="_blank"
                          href="https://www.mapbox.com/help/how-access-tokens-work/"
                          rel="noopener noreferrer"
                        >
                          What is API Key?
                        </a>
                      </p>
                    }
                  >
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('settings.mapboxAPIKey', {
                initialValue: settings ? settings.mapboxAPIKey : '',
                rules: [{required: true}],
              })(<Input />)}
            </FormItem>
          </Col>
        )}

        {isCustom && (
          <Col md={8} sm={16} xs={24}>
            <React.Fragment>
              <FormItem
                {...formItemLayout}
                label="Custom URL"
                extra={settings && settings.tilesKey ? cutomUrlHelpTexts[settings.tilesKey] : ''}
              >
                {getFieldDecorator('settings.tilesCustomUrl', {
                  initialValue: settings ? settings.tilesCustomUrl : '',
                  rules:
                    settings && settings.tilesKey
                      ? [
                          {
                            required: true,
                            pattern: cutomUrlHelpPatterns[settings.tilesKey],
                            message: 'Invalid tiles url !',
                          },
                        ]
                      : [],
                })(<Input />)}
              </FormItem>
            </React.Fragment>
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        {showOSRM && (
          <Col md={8} sm={16} xs={24}>
            <FormItem {...formItemLayout} label="Custom OSRM URL" className="full-width">
              {getFieldDecorator('settings.OSRMCustomUrl', {
                initialValue: settings ? settings.OSRMCustomUrl : '',
              })(<Input />)}
            </FormItem>
          </Col>
        )}
        <Col md={16} sm={16} xs={24}>
          <FormItem
            label="Marker Icon"
            className="full-width"
            labelCol={{xs: 24, sm: 6, md: 4}}
            wrapperCol={{xs: 24, sm: 18, md: 20}}
          >
            {getFieldDecorator('settings.iconNumber', {
              initialValue: settings ? settings.iconNumber : 0,
            })(
              <RadioGroup>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((marker, i) => {
                  // eslint-disable-next-line global-require
                  const img = require(`./mapsUtils/images/markers/marker-icon-${i}.png`);

                  return (
                    <Radio value={i} key={i}>
                      <img src={img} alt="marker" />
                    </Radio>
                  );
                })}
              </RadioGroup>,
            )}
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            label="Marker Text"
            className="full-width"
            labelCol={{xs: 6, sm: 6, md: 3}}
            wrapperCol={{xs: 18, sm: 18, md: 18}}
          >
            {getFieldDecorator('settings.markerPopupText', {
              initialValue: settings ? settings.markerPopupText : '',
            })(<TextArea rows={5} placeholder={markerPlaceHolder[mapType]} />)}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({
  onValuesChange: (props: Props, changedValues, allValues) => {
    props.setSettings(allValues);
  },
})(MapSettings);
