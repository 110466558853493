import {Reducer} from 'react';
import {DataActions, ElementDataStore} from './interfaces';

const defaultState: ElementDataStore = {};

export const dataStore: Reducer<ElementDataStore, any> = (state = defaultState, action) => {
  switch (action.type) {
    case DataActions.ADD:
      const {id} = action.payload;
      return {...state, [id]: action.payload.data};

    case DataActions.SET:
      return action.payload;

    default:
      return state;
  }
};
