import React, {useEffect, useState, useCallback} from 'react';
import {getFormattedDate} from 'utils/dateUtils';
import {ElementSchema} from 'interfaces/Elements';
import {Settings} from './interfaces';
import {ColumnProps} from 'antd/es/table';
import {DataSourceTypes} from 'interfaces/DataSources';

type Column = {
  [propName: string]: string | number | Function | {}[];
};

type Props = {
  initialColumns?: ColumnProps<{}>[];
  element: ElementSchema<Settings>;
};

function useTableColumns(props: Props) {
  const [columns, setColumns] = useState<Column[]>([]);
  const {initialColumns = [], element} = props;

  const getDeviceNameColumn = useCallback(() => {
    const {settings, dataSource} = element;
    let deviceName: string;
    if (
      dataSource &&
      dataSource.dataSourceType === DataSourceTypes.DEVICE &&
      dataSource.dataSourceValue.name
    ) {
      deviceName = dataSource.dataSourceValue.name;
    }
    return {
      title: (settings && settings.deviceNameColumnLabel) || 'Device',
      dataIndex: 'resourceName',
      key: 'resourceName',
      render: (name: string) => {
        return deviceName || name || '-';
      },
    };
  }, [element]);

  const getAssetNameColumn = useCallback(() => {
    const {settings} = element;

    return {
      title: (settings && settings.assetNameColumnLabel) || 'Asset',
      dataIndex: 'assetName',
      key: 'assetName',
      render: (name: string) => name || 'N/A',
    };
  }, [element]);

  const getTimeColumn = useCallback(() => {
    const {settings} = element;

    return {
      title: (settings && settings.timeColumnLabel) || 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (date: string) => (date ? <span>{getFormattedDate(new Date(date))}</span> : 'N/A'),
    };
  }, [element]);

  useEffect(() => {
    const {settings} = element;

    const temp = [];
    if (settings && settings.showDeviceNameColumn) {
      temp.push(getDeviceNameColumn());
    }

    if (settings && settings.showAssetNameColumn) {
      temp.push(getAssetNameColumn());
    }

    if (settings && settings.showTimeColumn) {
      temp.push(getTimeColumn());
    }

    setColumns(temp);
  }, [getDeviceNameColumn, getTimeColumn, element, getAssetNameColumn]);

  return [initialColumns.concat(columns)];
}

export default useTableColumns;
