import React from 'react';
import Form from 'antd/lib/form';
import {WrappedFormUtils} from 'antd/lib/form/Form';
import Select from 'antd/lib/select';
import {DataTypes} from 'interfaces/DataSources';

const FormItem = Form.Item;
const {Option} = Select;
const availableDataTypes = [DataTypes.TELEMETRIES, DataTypes.ATTRIBUTES, DataTypes.ALARMS];

interface Props {
  form: WrappedFormUtils;
  initialValue?: string;
  formItemLayout: {};
  disabled?: boolean;
  availableTypes?: DataTypes[];
}

function DataType(props: Props) {
  const {form, initialValue, formItemLayout, disabled, availableTypes = availableDataTypes} = props;
  const {getFieldDecorator} = form;

  return (
    <FormItem {...formItemLayout} label="Data Type">
      {getFieldDecorator('dataSource.dataType', {
        initialValue,
      })(
        <Select disabled={disabled}>
          {availableTypes.map(type => (
            <Option key={type}>{type}</Option>
          ))}
        </Select>,
      )}
    </FormItem>
  );
}

export default DataType;
