import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IEditorComponent} from 'interfaces/Elements';
import useElements from 'modules/Elements';
import isEqual from 'lodash.isequal';
import styler from 'react-styling';
import {WrappedFormUtils} from 'antd/es/form/Form';
import components from '../../Components';
import {LogTypes} from '../Debugger/store/interfaces';
import {addLog, setDebuggerTab, setDebuggerVisibility} from '../Debugger';
import {AvailableTabs} from '../Debugger/interfaces';
import {getEditorSettings} from '../Editor';

type EditorComponentProps = {
  element: IEditorComponent;
  handleDropElement: Function;
  width: number | null;
  index?: number;
  form?: WrappedFormUtils;
  isLive?: boolean;
};

function EditorComponent(props: EditorComponentProps) {
  const {element, handleDropElement, isLive} = props;
  const {getSelectedElement, updateElement, setSelectedElement} = useElements();
  const dispatch = useDispatch();
  const selectedElement = useSelector(getSelectedElement);
  const editorSettings = useSelector(getEditorSettings);
  const {styles} = element || {styles: {}};
  const {content = '', ...defaultStyle} = styles || {};
  // const componentStyle = styler`
  //     ${element.styles ? element.styles.content : ''}
  //     overflow:auto
  // `;
  const appliedStyles = content.concat(
    Object.entries({...defaultStyle, overflow: 'auto'})
      .map(([key, value]) =>
        value ? (key === 'background-image' ? `${key}:url(${value})` : `${key}:${value}`) : '',
      )
      .join('\n'),
  );

  const style = styler`${appliedStyles};`;

  const handleDoubleClick = useCallback(() => {
    dispatch(setDebuggerVisibility(true));
    dispatch(setDebuggerTab(AvailableTabs.SETTINGS));
  }, [dispatch]);

  const handleComponentClick = useCallback(
    e => {
      e.stopPropagation();
      /**
       * Prevent unnecessary updates when clicking the same element
       */
      if (isLive) {
        return;
      }
      if (!selectedElement || selectedElement.id !== element.id) {
        setSelectedElement(element);
      }
    },
    [element, selectedElement, setSelectedElement, isLive],
  );

  const borderStyle = useMemo(
    () =>
      selectedElement && selectedElement.id === element.id
        ? '1px dashed blue'
        : editorSettings.showElementBorders
        ? '1px dashed rgb(193, 193, 193)'
        : '1px solid rgba(255, 255, 255, 0)',
    [editorSettings.showElementBorders, element.id, selectedElement],
  );

  if (!components[element.type]) {
    dispatch(
      addLog({
        type: LogTypes.ERROR,
        message: `Component type ${element.type} is not supported!`,
        source: {
          type: 'EDITOR',
        },
      }),
    );
    return null;
  }

  const {Component} = components[element.type];

  return (
    <div
      onDoubleClick={() => handleDoubleClick()}
      onClick={handleComponentClick}
      style={{
        border: isLive ? '' : borderStyle,
        height: '100%',
      }}
    >
      <Component
        handleDropElement={handleDropElement}
        element={element}
        {...props}
        style={style}
        updateElement={updateElement}
        isLive={isLive}
      />
    </div>
  );
}

// @ts-ignore
// EditorComponent.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;
export default React.memo<EditorComponentProps>(EditorComponent, (prevProps, nextProps) => {
  if (nextProps.isLive) {
    return false;
  }
  return isEqual(prevProps.element, nextProps.element);
});
