import {ComponentCharacteristics} from 'interfaces/Elements';
import Component from './Switch';
import Settings from './Settings';

const SwitchBluePrint: ComponentCharacteristics<{}> = {
  type: 'switch',
  name: 'Switch',
  Component,
  settings: Settings,
  category: 'Common',
  width: 2,
  height: 2,
};
export default SwitchBluePrint;
