import {ComponentCharacteristics} from 'interfaces/Elements';
import Component from './InputNumber';
import Settings from '../input/Settings';

const InputNumberBluePrint: ComponentCharacteristics<{}> = {
  type: 'input-number',
  name: 'Input Number',
  category: 'Form',
  Component,
  settings: Settings,
  width: 6,
  height: 6,
};
export default InputNumberBluePrint;
