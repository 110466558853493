import React, {useCallback, useContext, useRef} from 'react';
import {createSelector} from 'reselect';
import {useDispatch} from 'react-redux';
import {useHistory, useRouteMatch, generatePath} from 'react-router-dom';
import pagesApi from './api';
import {addLog} from '../EditMode/Debugger';
import {Page, PagesOperations} from 'interfaces/Pages';
import {LogTypes} from '../EditMode/Debugger/store/interfaces';
import {OperationTypes} from '../EditMode/Editor/store/interfaces';
import {Actions} from './Store/interfaces';
import {getCurrentState, getPagesStore} from 'store';
import {setMenuOptions} from '../NavigationMenu';
import {setEditorInfo} from '../EditMode/Editor';
import {AppContext} from 'App';

const {location} = window;

function usePages(): PagesOperations {
  const history = useHistory();
  // const location = useLocation();
  const match = useRouteMatch<{publicId?: string; pageId?: string}>([
    '/public/:publicId',
    '/:visualizationId/pages/:pageId',
  ]);
  const dispatch = useDispatch();
  const {params} = useContext(AppContext);
  const pageRef = useRef(match && match.params.pageId);
  const path = useRef(match && match.path).current;
  const {publicId, visualizationId} = params;

  /**
   * Pages Selectors
   */
  const pagesSelector = createSelector(
    [getPagesStore],
    pagesStore => pagesStore.pages,
  );

  const currentPageSelector = createSelector(
    [getPagesStore],
    pagesStore => pagesStore.currentPage,
  );

  const isLoadingSelector = createSelector(
    [getPagesStore],
    pagesStore => pagesStore.isLoading,
  );

  /**
   * Pages Actions
   */
  const setLoaderVisiblility = useCallback(
    (value: boolean) => {
      dispatch({
        type: Actions.SET_IS_LOADING,
        payload: value,
      });
    },
    [dispatch],
  );

  const setPages = useCallback(
    (pages: Array<Page>) => {
      dispatch({
        type: Actions.SET_PAGES,
        payload: pages,
      });
    },
    [dispatch],
  );

  const setSelectedPage = useCallback(
    (page: Page | null) => {
      if (page) {
        if (pageRef.current) {
          const newPath =
            path &&
            generatePath(path, {
              pageId: page.id,
              visualizationId,
            });
          history.push(`${newPath}${location.search}`);
        } else {
          pageRef.current = page.id;
          history.push(`${location.pathname}/pages/${page.id}${location.search}`);
        }
      }
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: page,
      });
    },
    [dispatch, history, pageRef, path, visualizationId],
  );

  const fetchPrivatePages = useCallback(() => {
    setLoaderVisiblility(true);
    dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.FETCHING_PAGES}));
    pagesApi
      .getAll({visualizationId, pageId: pageRef.current})
      .then(({pages, menu, name, protectionType}) => {
        let page: Page | undefined;
        if (pageRef.current) {
          page = pages.find(({id}) => id === pageRef.current);
        } else {
          page = pages.find(({isRootPage}) => isRootPage);
        }
        page && setSelectedPage(page);
        dispatch(setEditorInfo({name, protectionType, numberOfPages: pages.length}));
        document.title = name;
        dispatch(setMenuOptions(menu));
        setPages(pages);
        setLoaderVisiblility(false);
      })
      .catch(e => {
        setLoaderVisiblility(false);
        dispatch(
          addLog({
            type: LogTypes.ERROR,
            message: `Error while Fetching Pages`,
          }),
        );
      });
  }, [dispatch, setLoaderVisiblility, setPages, setSelectedPage, visualizationId]);

  const fetchPublicPages = useCallback(() => {
    setLoaderVisiblility(true);
    dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.FETCHING_PAGES}));
    pagesApi
      .getAllPublicPages({publicId})
      .then(({pages, menu, name, protectionType}) => {
        const page: Page | null = pages.find(({isRootPage}) => isRootPage) || null;
        setPages(pages);
        setSelectedPage(page);
        setLoaderVisiblility(false);
        dispatch(setEditorInfo({name, protectionType, numberOfPages: pages.length}));
        document.title = name;
        dispatch(setMenuOptions(menu));
      })
      .catch(e => {
        setLoaderVisiblility(false);
      });
  }, [dispatch, publicId, setLoaderVisiblility, setPages, setSelectedPage]);

  const fetchPages = useCallback(() => {
    if (publicId) {
      fetchPublicPages();
    } else {
      fetchPrivatePages();
    }
  }, [publicId, fetchPublicPages, fetchPrivatePages]);

  const setCurrentPage = useCallback(
    (pageId: string) => {
      const {pages} = getCurrentState();
      const page: Page | null = pages.find(({id}) => id === pageId) || null;
      setSelectedPage(page);
    },
    [setSelectedPage],
  );

  const updatePageStyle = useCallback(
    styleContent => {
      const {pages, currentPage} = getCurrentState();
      if (!currentPage || !currentPage.id || !visualizationId) {
        throw new Error('Required parameters are missing');
      }
      const updatedPage = {...currentPage, style: {...currentPage.style, ...styleContent}};

      pagesApi
        .update(updatedPage, {pageId: updatedPage.id, visualizationId})
        .then(() => {
          setSelectedPage(updatedPage);
          const updatedPages = pages
            .filter(({id}: {id: string}) => id !== currentPage.id)
            .concat(updatedPage);
          setPages(updatedPages);
        })
        .catch(e =>
          dispatch(
            addLog({
              type: LogTypes.ERROR,
              message: e.message,
            }),
          ),
        );
    },
    [dispatch, setPages, setSelectedPage, visualizationId],
  );

  const updatePage = useCallback(
    page => {
      pagesApi
        .update(page, {pageId: page.id})
        .then(() => fetchPages())
        .catch(e =>
          dispatch(
            addLog({
              type: LogTypes.ERROR,
              message: e.message,
            }),
          ),
        );
    },
    [dispatch, fetchPages],
  );

  const createPage = useCallback(
    (page: Page) => {
      if (!page.id || !visualizationId) {
        throw new Error('Required parameters are missing');
      }
      const {pages} = getCurrentState();
      setLoaderVisiblility(true);
      dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.CREATING_PAGES}));
      pagesApi
        .create(page, {visualizationId})
        .then(() => {
          setPages([...pages, page]);
          dispatch(setEditorInfo({numberOfPages: pages.length + 1}));
          setSelectedPage(page);
          setLoaderVisiblility(false);
        })
        .catch(e => {
          setLoaderVisiblility(false);
          dispatch(
            addLog({
              type: LogTypes.ERROR,
              message: e.message,
            }),
          );
        });
    },
    [dispatch, setLoaderVisiblility, setPages, setSelectedPage, visualizationId],
  );

  const deletePage = useCallback(
    (pageId: string) => {
      const {pages, currentPage} = getCurrentState();
      setLoaderVisiblility(true);
      dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.DELETING_PAGES}));
      pagesApi
        .remove({pageId})
        .then(() => {
          const filteredPages = pages.filter(({id}: {id: string}) => id !== pageId);
          setPages(filteredPages);
          dispatch(setEditorInfo({numberOfPages: filteredPages.length}));
          if (currentPage && currentPage.id === pageId) {
            setCurrentPage(filteredPages[0].id);
          }
          setLoaderVisiblility(false);
        })
        .catch(e => {
          setLoaderVisiblility(false);
          dispatch(
            addLog({
              type: LogTypes.ERROR,
              message: e.message,
            }),
          );
        });
    },
    [dispatch, setCurrentPage, setLoaderVisiblility, setPages],
  );

  const updateMenuSettings = useCallback(
    ({menu}) => {
      dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.UPDATING_MENU_SETTINGS}));
      pagesApi.updateMenuSettings({menu, params});
    },
    [dispatch, params],
  );

  return {
    pagesSelector,
    currentPageSelector,
    isLoadingSelector,
    setCurrentPage,
    updatePageStyle,
    updatePage,
    updateMenuSettings,
    setPages,
    fetchPages,
    deletePage,
    createPage,
  };
}

export default usePages;
