import {ComponentCharacteristics} from 'interfaces/Elements';
import Settings from './Settings';
import Component from './Radio';

const RadioBluePrint: ComponentCharacteristics<{}> = {
  type: 'radio',
  name: 'Radio',
  category: 'Form',
  Component,
  settings: Settings,
  width: 6,
  height: 6,
};
export default RadioBluePrint;
