import React, {useCallback, useEffect, useState} from 'react';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {Controlled as CodeMirror} from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/mode/css/css';

const options = {
  mode: 'css',
  theme: 'eclipse',
  lineNumbers: true,
};

type Props = {initialValue: string; applyStyles: Function};

function StylesIDE(props: Props) {
  const [styles, setStyles] = useState<string>('');
  const handleChangeStyles = (editor: {}, data: string, styles: string) => {
    setStyles(styles);
  };

  const applyStyle = useCallback(() => {
    props.applyStyles({content: styles});
  }, [props, styles]);

  useEffect(() => {
    setStyles(props.initialValue);
  }, [props.initialValue]);

  return (
    <Row gutter={8}>
      <Col span={22} style={{border: '1px solid #d8d8d8'}}>
        <CodeMirror value={styles} options={options} onBeforeChange={handleChangeStyles} />
      </Col>
      <Col span={2}>
        <Button type="primary" size="small" icon="check-circle" onClick={applyStyle}>
          Apply
        </Button>
      </Col>
    </Row>
  );
}

export default StylesIDE;
