import React from 'react';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {SettingsElementProps} from '../../../../interfaces/Settings';
import * as interfaces from './interfaces';
import Action from '../../Control/Settings';

interface Props extends SettingsElementProps<interfaces.Settings> {
  form: WrappedFormUtils;
}
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const {Item: FormItem} = Form;
const {Option} = Select;
const types = {
  Submit: 'submit',
  Action: 'action',
};
function Settings(props: Props) {
  const {
    form,
    form: {getFieldDecorator},
    element: {settings},
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
      }}
    >
      {/*<FormItem label="type" style={{width: '300px'}} {...formItemLayout}>*/}
      {/*  {getFieldDecorator('settings.type', {*/}
      {/*    initialValue: settings && settings.type,*/}
      {/*  })(*/}
      {/*    <Select>*/}
      {/*      {Object.entries(types).map(([key, value]) => (*/}
      {/*        <Option value={value}>{key}</Option>*/}
      {/*      ))}*/}
      {/*    </Select>,*/}
      {/*  )}*/}
      {/*</FormItem>*/}
      <Action
        settings={{actionName: settings ? settings.actionName || '' : ''}}
        fieldName="actionName"
        form={form}
        style={{width: '400px'}}
      />
      <FormItem label="Label" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.label', {
          initialValue: settings && (settings.label || 'Button'),
        })(<Input />)}
      </FormItem>
    </div>
  );
}
export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(Settings),
  },
];
