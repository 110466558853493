import Component from './TelemetryTable';
import Settings from '../TableSettings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from '../interfaces';
import {DataSourceFields, DataTypes} from '../../../../interfaces/DataSources';

const initialSettings = {
  showDeviceNameColumn: true,
  showAssetNameColumn: false,
  showTimeColumn: true,
  deviceNameColumnLabel: 'Device',
  assetNameColumnLabel: 'Asset',
  rowsCount: 10,
  tableSize: 'small',
  timeColumnLabel: 'Time',
};
const TelemetryTableBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'telemetryTable',
  name: 'Telemetry Table',
  category: 'Tables',
  Component,
  dataSourcesOptions: {
    disabledFields: [DataSourceFields.DataType],
    initialValue: {
      dataType: DataTypes.TELEMETRIES,
    },
  },
  isTimeWindowEnabled: true,
  settings: Settings,
  initialSettings,
  height: 6,
  width: 6,
};
export default TelemetryTableBluePrint;
