import {Elements, ElementSchema} from "../../../interfaces/Elements";

export interface ElementsStore  {
  elements: Elements;
  selectedElement: ElementSchema | null;
};

export enum Actions {
  SET_ELEMENTS='SET_ELEMENTS',
  UPDATE_ELEMENT='UPDATE_ELEMENT',
  UPDATE_ELEMENTS='UPDATE_ELEMENTS',
  SET_SELECTED_ELEMENT = 'SET_SELECTED_ELEMENT',
  ADD_ELEMENT ='ADD_ELEMENT'
}
export interface Action {
  type: Actions;
  payload: any;
}