import React, {useEffect, useState, useMemo} from 'react';
import Map from '../Map';
import {ElementSchema} from 'interfaces/Elements';
import useElements from 'modules/Elements';
import {CoordinatesData, Settings} from '../interfaces';
import {SerialData} from 'interfaces/Data';
import {groupDataBy} from '../mapsUtils/utils';
const identifier = Date.now();

interface Props {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
}

const DEFAULT_LAT_KEY = 'lat';
const DEFAULT_LNG_KEY = 'lng';

function MarkerMaps(props: Props) {
  const {
    element: {settings = {}, dataSource, id},
    style,
  } = props;
  const {getElementData} = useElements();
  const isLive = false;

  const [locationData, setLocationData] = useState<SerialData[]>([]);
  useEffect(() => {
    getElementData({id, dataSource}, {pageSize: 5000}, false).then(({data}) => {
      if (data) {
        setLocationData(data);
      }
    });
  }, [dataSource, getElementData, id]);

  const getCoordinatesFromSerialData = useMemo(() => {
    const latKey = settings.latKey || DEFAULT_LAT_KEY;
    const lngKey = settings.lngKey || DEFAULT_LNG_KEY;

    return Object.values(groupDataBy(locationData, ['resourceId'])).reduce(
      (accumulator: CoordinatesData, record): CoordinatesData => {
        const {resourceName, [latKey]: lat, [lngKey]: lng} = record;

        if (record[latKey] && record[lngKey]) {
          accumulator.push({
            coordinates: [
              {
                lat: Number(lat),
                lng: Number(lng),
                markerTitle: resourceName
                  ? `${resourceName}`
                  : dataSource
                  ? dataSource.dataSourceValue.name
                  : '',
              },
            ],
            data: record,
          });
        }
        return accumulator;
      },
      [],
    );
  }, [dataSource, locationData, settings.latKey, settings.lngKey]);

  return (
    <Map
      settings={settings}
      style={style}
      mapOptions={{
        zoom: settings.zoom || 14,
        style: {
          height: props.element.height,
          width: props.element.width,
        },
        boundsOptions: {maxZoom: settings.zoom || 14},
      }}
      coordinatesData={getCoordinatesFromSerialData}
    />
  );
}
MarkerMaps.whyDidYouRender = Boolean(process.env.REACT_APP_ENABLE_DEBUG);
export default React.memo(MarkerMaps);
