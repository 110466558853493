import React, {useCallback} from 'react';
import Select from 'antd/es/select';
import {ElementSchema} from '../../../../interfaces/Elements';
import * as interfaces from '../Radio/interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Form from 'antd/es/form';
import useController from '../../Control/Prototype';

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
}
const {Option} = Select;
const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function SelectCmp(props: Props) {
  const {
    element: {settings = {}},
    form,
  } = props;

  const {items = [], fieldName, label} = settings || {};
  const {value, sendAction} = useController(props);

  const handleChange = useCallback(
    value => {
      if (settings.actionName && value) {
        sendAction({
          name: settings.actionName,
          value: value,
        });
      }
    },
    [sendAction, settings.actionName],
  );

  if (form) {
    return (
      <FormItem label={label} {...formItemLayout}>
        {form.getFieldDecorator(`${fieldName}`, {
          initialValue: value || undefined,
        })(
          <Select onChange={handleChange} allowClear={true}>
            {items.map((item, index) => (
              <Option value={item.value} key={index}>
                {item.label}
              </Option>
            ))}
          </Select>,
        )}
      </FormItem>
    );
  }

  return (
    <Select
      onChange={handleChange}
      defaultValue={value || undefined}
      allowClear={true}
      style={{width: '100%', height: '100%'}}
    >
      {items.map((item, index) => (
        <Option value={item.value} key={index}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
}
export default SelectCmp;
