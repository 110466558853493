import Component from './LineChart';
import Settings from '../../Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from '../../interfaces';
import {DataTypes} from 'interfaces/DataSources';

const LineChartBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'lineChart',
  name: 'Line Chart',
  category: 'Charts',
  Component,
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.ATTRIBUTES, DataTypes.TELEMETRIES],
  },
  aggregationOptions: {},
  settings: Settings,
  width: 6,
  height: 6,
};

export default LineChartBluePrint;
