import React, {useCallback} from 'react';
import Form from 'antd/es/form';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import Tabs from 'antd/es/tabs';
import DatePicker from 'antd/es/date-picker';
import moment from 'moment';
import {ElementSchema} from '../../interfaces/Elements';

const {Item: FormItem} = Form;
const {Option} = Select;
const {TabPane} = Tabs;
const {RangePicker} = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 10},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 14},
  },
};
const rangeFormLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 4},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 20},
  },
};

export const timeWindowRelativeTimeValues: {
  [key: string]: 'days' | 'hours' | 'minutes' | 'seconds';
} = {
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
};

interface Props {
  form: WrappedFormUtils;
  element: ElementSchema;
}
function TimeWindow(props: Props) {
  const {
    form: {getFieldDecorator, getFieldValue, setFieldsValue},
    element,
  } = props;
  const timeWindowType =
    getFieldValue('timeWindow.type') || (element.timeWindow && element.timeWindow.type);
  const timeWindow = getFieldValue('timeWindow').type
    ? getFieldValue('timeWindow')
    : element.timeWindow || {};

  const changeTimeWindowHandler = useCallback(
    value => {
      setFieldsValue({
        timeWindow: {
          type: value,
        },
      });
    },
    [setFieldsValue],
  );

  const getRelativeTimeFormItems = useCallback(() => {
    return Object.entries(timeWindowRelativeTimeValues).map(([key, value]) => (
      <FormItem key={key} {...formItemLayout} label={value}>
        {getFieldDecorator(`timeWindow.startValues.${value}`, {
          initialValue:
            timeWindow && timeWindow.startValues && timeWindow.startValues[value]
              ? timeWindow.startValues[value]
              : 0,
        })(<InputNumber min={0} />)}
      </FormItem>
    ));
  }, [getFieldDecorator, timeWindow]);
  const getDefaultActiveKey = useCallback(() => {
    if (timeWindow.from && timeWindow.to) {
      return 'range';
    } else if (timeWindow.to) {
      return 'timeBefore';
    } else if (timeWindow.from) {
      return 'timeAfter';
    } else {
      return 'last';
    }
  }, [timeWindow.from, timeWindow.to]);

  const getHistoryForm = useCallback(() => {
    return (
      <Tabs defaultActiveKey={getDefaultActiveKey()} tabPosition="left" style={{height: 220}}>
        <TabPane
          tab="Range"
          key="range"
          disabled={
            !!(
              timeWindow &&
              ((timeWindow.from && !timeWindow.to) ||
                (timeWindow.to && !timeWindow.from) ||
                (timeWindow.startValues &&
                  (timeWindow.startValues.days ||
                    timeWindow.startValues.hours ||
                    timeWindow.startValues.minutes ||
                    timeWindow.startValues.seconds)))
            )
          }
        >
          <FormItem style={{textAlign: 'left', width: '500px'}} {...rangeFormLayout} label="Range">
            {getFieldDecorator('timeWindow.range', {
              initialValue:
                timeWindow && timeWindow.from && timeWindow.to
                  ? [
                      moment.unix(Number(timeWindow.from / 1000)),
                      moment.unix(Number(timeWindow.to / 1000)),
                    ]
                  : undefined,
            })(<RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
          </FormItem>
        </TabPane>
        <TabPane
          tab="Before Time"
          key="timeBefore"
          disabled={
            !!(
              timeWindow &&
              (timeWindow.from ||
                (timeWindow.range && timeWindow.range.length) ||
                (timeWindow.startValues &&
                  (timeWindow.startValues.days ||
                    timeWindow.startValues.hours ||
                    timeWindow.startValues.minutes ||
                    timeWindow.startValues.seconds)))
            )
          }
        >
          <FormItem style={{textAlign: 'left'}} {...formItemLayout} label="Before Time">
            {getFieldDecorator('timeWindow.to', {
              initialValue:
                timeWindow && !timeWindow.from && timeWindow.to
                  ? moment.unix(Number(timeWindow.to / 1000))
                  : undefined,
            })(
              <DatePicker
                disabledTime={() => {
                  return {};
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />,
            )}
          </FormItem>
        </TabPane>
        <TabPane
          tab="After Time"
          key="timeAfter"
          disabled={
            !!(
              (timeWindow && (timeWindow.to || (timeWindow.range && timeWindow.range.length))) ||
              (timeWindow.startValues &&
                (timeWindow.startValues.days ||
                  timeWindow.startValues.hours ||
                  timeWindow.startValues.minutes ||
                  timeWindow.startValues.seconds))
            )
          }
        >
          <FormItem style={{textAlign: 'left'}} {...formItemLayout} label="After Time">
            {getFieldDecorator('timeWindow.from', {
              initialValue:
                timeWindow && timeWindow.from && !timeWindow.to
                  ? moment.unix(Number(timeWindow.from / 1000))
                  : undefined,
            })(
              <DatePicker
                disabledTime={() => {
                  return {};
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />,
            )}
          </FormItem>
        </TabPane>
        <TabPane
          tab="Relative"
          key="last"
          disabled={
            timeWindow &&
            (timeWindow.to || timeWindow.from || (timeWindow.range && timeWindow.range.length))
          }
        >
          {getRelativeTimeFormItems()}
        </TabPane>
      </Tabs>
    );
  }, [getDefaultActiveKey, getFieldDecorator, getRelativeTimeFormItems, timeWindow]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
      }}
    >
      <FormItem label="Time Window Type" style={{width: '400px'}} {...formItemLayout}>
        {getFieldDecorator('timeWindow.type', {
          initialValue: (timeWindow && timeWindow.type) || 'NONE',
        })(
          <Select className="full-width" onChange={changeTimeWindowHandler}>
            <Option value="HISTORY">History</Option>
            <Option value="REAL_TIME">Real Time</Option>
            <Option value="NONE">None</Option>
          </Select>,
        )}
      </FormItem>
      {timeWindowType === 'REAL_TIME'
        ? getRelativeTimeFormItems()
        : timeWindowType === 'HISTORY'
        ? getHistoryForm()
        : null}
    </div>
  );
}
export default TimeWindow;
