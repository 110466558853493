import {Reducer} from 'react';
import {Action, Actions, PagesStore} from './interfaces';

const defaultState: PagesStore = {pages: [], currentPage: null, isLoading: false};

export const pagesStore: Reducer<PagesStore, any> = (state = defaultState, action: Action) => {
  switch (action.type) {
    case Actions.SET_PAGES:
      return {...state, pages: action.payload};
    case Actions.SET_CURRENT_PAGE:
      return {...state, currentPage: action.payload};
    case Actions.SET_IS_LOADING:
      return {...state, isLoading: action.payload};
    default:
      return state;
  }
};
