import React, {useCallback} from 'react';
import Form from 'antd/es/form';
import Radio from 'antd/es/radio';
import {ElementSchema} from 'interfaces/Elements';
import * as interfaces from './interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import useController from '../../Control/Prototype';

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
}
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const {Group} = Radio;
const {Item: FormItem} = Form;
function RadioComp(props: Props) {
  const {
    element: {settings = {}},
    form,
  } = props;

  const {items = [], fieldName, label} = settings || {};
  const {value, sendAction} = useController(props);

  const handleChange = useCallback(
    ({target}) => {
      if (settings.actionName) {
        sendAction({
          name: settings.actionName,
          value: target.value,
        });
      }
    },
    [sendAction, settings.actionName],
  );
  if (form) {
    return (
      <FormItem label={label} {...formItemLayout}>
        {form.getFieldDecorator(`${fieldName}`, {
          initialValue: value,
        })(
          <Group onChange={handleChange}>
            {items.map((item, index) => (
              <Radio value={item.value} key={index}>
                {item.label}
              </Radio>
            ))}
          </Group>,
        )}
      </FormItem>
    );
  }
  return (
    <Group onChange={handleChange} defaultValue={value}>
      {items.map((item, index) => (
        <Radio value={item.value} key={index}>
          {item.label}
        </Radio>
      ))}
    </Group>
  );
}
export default RadioComp;
