import React from 'react';
import InputNumber from 'antd/es/input-number';
import * as interfaces from '../input/interfaces';
import {ElementSchema} from '../../../../interfaces/Elements';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Form from 'antd/es/form';

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
}

const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function InputNumberCmp(props: Props) {
  const {
    form: {getFieldDecorator},
    element: {settings},
  } = props;
  const {fieldName, label, disabled} = settings || {};

  return (
    <FormItem label={label} {...formItemLayout}>
      {getFieldDecorator(`${fieldName}`, {})(<InputNumber disabled={disabled} />)}
    </FormItem>
  );
}
export default InputNumberCmp;
