import React, {useCallback, useEffect, useRef} from 'react';
import {Responsive, WidthProvider as widthProvider, Layout as GridLayout} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import ContextMenu from '../ContextMenu';
import HotKeys from './hooks/HotKeys';
import {Elements, ElementSchema, SelectedElementPosition} from 'interfaces/Elements';
import {DroppableElement, Info} from 'interfaces/Editor';
import EditorComponent from '../EditorComponent';
import DropTarget from './hooks/DropTargetWrapper';
import EditorViewPort from './hooks/EditorViewPort';
import {Store} from 'store/interfaces';
import {Actions} from './store/interfaces';
import {getEditorStore} from 'store';
import useElements from 'modules/Elements';
import usePages from 'modules/Pages';
import Layout from 'antd/es/layout';

const {Content} = Layout;
const ResponsiveReactGridLayout = widthProvider(Responsive);

/**
 * Editor Selectors
 */

export const getIsLoading = (store: Store) => store.editorStore.isLoading;
export const getSelectedElementPosition = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.selectedElementPosition;
  },
);
export const elementInCopySelector = createSelector(
  [getEditorStore],
  editorStore => editorStore.elementInCopy,
);
export const getEditorSettings = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.settings;
  },
);

export const getLibraryVisibility = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.isElementsLibraryVisible;
  },
);

export const getSettingsVisibility = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.isSettingsVisible;
  },
);

export const getPagesVisibility = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.isPagesVisible;
  },
);
export const getIsDragging = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.isDragging;
  },
);

export const hotKeysVisibilitySelector = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.isHotKeysModalVisible;
  },
);

export const savingLoaderStatusSelector = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.isSaving;
  },
);

export const hasUnsavedChangesSelector = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.hasUnsavedChanges;
  },
);

export const getEditorInfo = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.editorInfo;
  },
);

export const getIsResizing = createSelector(
  [getEditorStore],
  editorStore => {
    return editorStore.isResizing;
  },
);

/**
 * Editor Actions
 */

export const setElementInCopy = (element: ElementSchema | null) => {
  return {
    type: Actions.SET_ELEMENT_IN_COPY,
    payload: element,
  };
};

export const setSelectedElementPosition = (position: SelectedElementPosition) => {
  return {
    type: Actions.SET_ELEMENT_POSITION,
    payload: position,
  };
};

export const setEditorSettings = (settings: any) => {
  return {
    type: Actions.SET_SETTINGS,
    payload: settings,
  };
};
export const setLoaderVisiblility = (value: boolean) => {
  return {
    type: Actions.SET_IS_LOADING,
    payload: value,
  };
};
export const toggleElementsLibraryVisibility = () => {
  return {
    type: Actions.TOGGLE_LIBRARY_VISIBILITY,
  };
};

export const toggleSettingsVisibility = () => {
  return {
    type: Actions.TOGGLE_SETTINGS_VISIBILITY,
  };
};
export const togglePagesVisibility = () => {
  return {
    type: Actions.TOGGLE_PAGES_VISIBILITY,
  };
};
export const setIsDragging = (draggingStatus: boolean) => {
  return {
    type: Actions.SET_IS_DRAGGING,
    payload: draggingStatus,
  };
};
export const setIsResizing = (resize: {isResize: boolean; elementId: string | null}) => {
  return {
    type: Actions.SET_IS_RESIZING,
    payload: resize,
  };
};

export const closeSettings = () => {
  return {
    type: Actions.CLOSE_EDITOR_SETTINGS,
  };
};
export const setSavingLoaderStatus = (status: boolean) => {
  return {
    type: Actions.SET_SAVING_LOADER_STATUS,
    payload: status,
  };
};

export const setChangesStatus = (status: boolean) => {
  return {
    type: Actions.SET_CHANGES_STATUS,
    payload: status,
  };
};

export const setEditorInfo = (info: Info) => {
  return {
    type: Actions.SET_EDITOR_INFO,
    payload: info,
  };
};
let editorRef: React.RefObject<HTMLDivElement>;

// export const getEditorBounding = () => {
//   let bounding = {
//     x: 0,
//     y: 0,
//     offsetTop: 0,
//     width: window.innerWidth,
//     height: window.innerHeight,
//   };
//   if (editorRef.current) {
//     bounding = editorRef.current.getBoundingClientRect();
//     bounding.offsetTop = editorRef.current.offsetTop;
//   }
//   return bounding;
// };

function Editor() {
  const dispatch = useDispatch();
  const {
    getElements,
    addElement,
    fetchElements,
    setSelectedElement,
    getElementsWithInjectedChildren,
    updateElements,
  } = useElements();
  const {currentPageSelector} = usePages();
  editorRef = useRef<HTMLDivElement>(null);
  const elementsWithInjectedChildren = useSelector(getElementsWithInjectedChildren);
  // const isLoading = useSelector(getIsLoading);
  const currentPage = useSelector(currentPageSelector);
  const elements = useSelector(getElements);
  const dropPosition = useRef<GridLayout>();
  const currentPageId = currentPage && currentPage.id;

  const handleLayoutClick = useCallback(() => {
    dispatch(closeSettings());
    setSelectedElement(null);
  }, [dispatch, setSelectedElement]);

  const onDrop = useCallback((droppedElement: GridLayout) => {
    dropPosition.current = {...droppedElement, w: 4, h: 4};
  }, []);

  const handleDropElement = useCallback(
    (droppedElement: DroppableElement) => {
      const dropPos = dropPosition.current;
      if (!dropPos) {
        return;
      }
      const element = {
        type: droppedElement.elementType,
        childrenIds: [],
        top: dropPos.y,
        left: dropPos.x,
        width: droppedElement.width || dropPos.w || 4,
        height: droppedElement.height || dropPos.h || 4,
        widthPerc: droppedElement.width * 100,
        leftPerc: droppedElement.x,
        parentId: droppedElement.parentId,
        settings: droppedElement.settings,
        //index: droppedElement.index,
        //canInteract,
      };
      dropPosition.current = undefined;
      return addElement(element);
    },
    [addElement],
  );

  const handleDropChildElement = useCallback(
    (droppedElement: DroppableElement) => {
      // const editorBounding = getEditorBounding();
      const element = {
        type: droppedElement.elementType,
        childrenIds: [],
        top: droppedElement.y, // - editorBounding.y,
        left: droppedElement.x, // - editorBounding.x,
        width: droppedElement.width || 100,
        height: droppedElement.height || 100,
        widthPerc: droppedElement.width, // (droppedElement.width * 100) / editorBounding.width,
        leftPerc: droppedElement.x, // ((droppedElement.x - editorBounding.x) * 100) / editorBounding.width,
        parentId: droppedElement.parentId,
        settings: droppedElement.settings,
      };
      return addElement(element);
    },
    [addElement],
  );

  const onLayoutChange = (layout: GridLayout[]) => {
    const newElements = layout.reduce<Elements>((result, item) => {
      if (!item.i) {
        return result;
      }
      const component = elements[item.i];
      result[item.i] = {
        ...component,
        left: item.x,
        top: item.y,
        height: item.h,
        width: item.w,
      };
      return result;
    }, {});
    updateElements(newElements);
  };

  useEffect(() => {
    if (currentPageId) {
      fetchElements();
    }
  }, [fetchElements, currentPageId]);

  const layouts = elementsWithInjectedChildren.map(element => ({
    ...element,
    w: Number(element.width),
    h: Number(element.height),
    x: element.left,
    y: element.top,
    i: element.id,
  }));

  return (
    <div ref={editorRef}>
      <Layout>
        <Content>
          <DropTarget handleDropElement={handleDropElement}>
            <HotKeys>
              <ContextMenu>
                <EditorViewPort handleLayoutClick={handleLayoutClick}>
                  <ResponsiveReactGridLayout
                    margin={[0, 0]}
                    cols={{lg: 48, md: 24, sm: 12, xs: 8, xxs: 4}}
                    rowHeight={28}
                    style={{padding: 0, minHeight: '100vh'}}
                    layouts={{lg: layouts}}
                    onResizeStop={onLayoutChange}
                    onDragStop={onLayoutChange}
                    measureBeforeMount={true}
                    useCSSTransforms={true}
                    compactType={null}
                    preventCollision={true}
                    // @ts-ignore
                    onDrop={onDrop}
                    // @ts-ignore
                    isDroppable={true}
                  >
                    {elementsWithInjectedChildren.map(element => {
                      return (
                        <div key={element.id}>
                          <EditorComponent
                            element={element}
                            handleDropElement={handleDropChildElement}
                            width={element.width}
                          />
                        </div>
                      );
                    })}
                  </ResponsiveReactGridLayout>
                </EditorViewPort>
              </ContextMenu>
            </HotKeys>
          </DropTarget>
        </Content>
      </Layout>
    </div>
  );
}

export default React.memo(Editor);
