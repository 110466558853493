import Container from './Container';
import {ComponentCharacteristics} from 'interfaces/Elements';

const ContainerBluePrint: ComponentCharacteristics<{}> = {
  type: 'container',
  name: 'Container',
  category: 'Common',
  Component: Container,
  settings: [],
  isDroppable: true,
  width: 6,
  height: 6,
};

export default ContainerBluePrint;
