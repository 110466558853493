import React, {useCallback} from 'react';
import {ElementSchema} from '../../../../interfaces/Elements';
import {StyledComponent} from '../../styled';
import useTextData from './useData';

const defaultText = '<h3>Right click to edit content..</h3>';

interface Settings {
  text?: string;
}

type Props = {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
};

function TextComponent(props: Props) {
  const {
    element,
    element: {settings},
    style,
  } = props;
  const {textData} = useTextData(element);

  const injectValuesIntoText = useCallback((text: string | undefined, values) => {
    if (!text) {
      return text;
    }
    return text.replace(/{{&(.*?)}}/g, (pattern, key) => {
      return values[key.replace('amp;', '')];
    });
  }, []);

  const textParsed = settings && settings.text;

  return (
    <div
      dangerouslySetInnerHTML={{__html: injectValuesIntoText(textParsed, textData) || defaultText}}
      style={{...style, width: '100%', height: '100%'}}
    />
  );
}
TextComponent.whyDidYouRender = Boolean(process.env.REACT_APP_ENABLE_DEBUG);
export default TextComponent;
