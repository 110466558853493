import React from 'react';
import Form from 'antd/es/form';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import {ElementSchema} from 'interfaces/Elements';
import {
  AggregationFunctions,
  AggregationOptions,
  AggregationTimeBucket,
  AvailableUnits,
} from 'interfaces/Aggregation';
const {Item: FormItem} = Form;
const {Option} = Select;
const availableUnits = Object.values(AvailableUnits);
const availableFunctions = Object.values(AggregationFunctions);

interface Props {
  element: ElementSchema;
  form: WrappedFormUtils;
  options: AggregationOptions;
}
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 2},
  },
};

function AggregationSettings(props: Props) {
  const {form, element, options} = props;
  const {aggregationFunctions = availableFunctions, enableTimeBucket} = options;
  const {aggregation: initialData} = element;
  const {getFieldDecorator} = form;
  const initialInterval: Partial<AggregationTimeBucket> =
    initialData && initialData.interval ? initialData.interval : {};

  return (
    <>
      <FormItem {...formItemLayout} label="Aggregation Function">
        {getFieldDecorator('aggregation.func', {
          initialValue: initialData && initialData.func,
        })(
          <Select allowClear>
            {aggregationFunctions.map(func => (
              <Option key={func}>
                <span style={{textTransform: 'capitalize'}}>{func}</span>
              </Option>
            ))}
          </Select>,
        )}
      </FormItem>
      {enableTimeBucket === true && (
        <>
          <FormItem {...formItemLayout} label="Time Bucket">
            {getFieldDecorator('aggregation.interval.value', {
              initialValue: initialInterval && initialInterval.value,
            })(<InputNumber type="text" style={{width: '30%', marginRight: '3%'}} />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Time Bucket Unit">
            {getFieldDecorator('aggregation.interval.unit', {
              initialValue: initialInterval && initialInterval.unit,
            })(
              <Select allowClear style={{width: '67%'}}>
                {availableUnits.map(unit => (
                  <Option key={unit}>
                    <span style={{textTransform: 'capitalize'}}>{unit}</span>
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </>
      )}
    </>
  );
}

export default AggregationSettings;
