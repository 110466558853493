import React, {useEffect} from 'react';
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Layout from 'antd/es/layout';
import {Prompt} from 'react-router';
import {useSelector} from 'react-redux';
import Editor, {hasUnsavedChangesSelector} from './Editor';
import usePages from 'modules/Pages/index';
import Actions from './Sider';
import ElementsLibrary from './ElementsLibrary';
import NavigationMenu from '../NavigationMenu';
import Scrollbar from 'react-smooth-scrollbar';
import EditorActions from './Editor/EditorActions';
import Debugger from './Debugger';
import whyDidYouRender from '../../utils/whyDidYouRender';

const {Sider, Content} = Layout;

// whyDidYouRender();

function EditMode() {
  const {fetchPages} = usePages();
  const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    if (hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    } else {
      // @ts-ignore
      window.onbeforeunload = undefined;
    }
  }, [hasUnsavedChanges]);

  return (
    <div className="app">
      <Prompt
        when={hasUnsavedChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Layout>
        <Sider theme="light" width={60}>
          <Actions />
        </Sider>
        <Content>
          <Scrollbar>
            <div style={{maxHeight: '100vh'}}>
              <NavigationMenu />
              <Editor />
            </div>
          </Scrollbar>
          <ElementsLibrary />
          <EditorActions />
          <Debugger />
        </Content>
      </Layout>
    </div>
  );
}

export default React.memo(EditMode);
