import React, {useState} from 'react';
import uuid from 'uuid';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';

type Props = {
  isVisible: boolean;
  onCancel(): void;
  handleSubmit({}): void;
};

function CreatePageModal(props: Props) {
  const {isVisible} = props;
  const [pageName, setPageName] = useState();

  const handleInputChange = setPageName;

  const handleSubmit = () => {
    props.handleSubmit({
      id: uuid.v4(),
      name: pageName,
    });
    handleClose();
  };

  const handleClose = () => {
    setPageName(undefined);
    props.onCancel();
  };

  return (
    <Modal title="Create new page" onCancel={handleClose} onOk={handleSubmit} visible={isVisible}>
      <Input value={pageName} onChange={({target}) => handleInputChange(target.value)} />
    </Modal>
  );
}

export default CreatePageModal;