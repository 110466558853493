import React, {useState} from 'react';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {MenuLink} from 'interfaces/NavigationMenu';
import {useSelector} from 'react-redux';
import {getMenuStore} from '../../../NavigationMenu';
import usePages from '../../../Pages';

type Props = {
  form: WrappedFormUtils;
};

const {Item: FormItem} = Form;
const {Option} = Select;

function MenuSettingsForm(props: Props) {
  const {form} = props;
  const {getFieldDecorator} = form;
  const menuOptions = useSelector(getMenuStore);
  const [items, setItems] = useState<MenuLink[]>(menuOptions.links);
  const {pagesSelector} = usePages();
  const pages = useSelector(pagesSelector);

  const handleAddItem = () => {
    setItems(items => [...items, {name: '', path: ''}]);
  };

  const handleDeleteItem = (index: number) => {
    setItems(items => [...items.slice(0, index), ...items.slice(index + 1)]);
  };

  return (
    <>
      <Row type="flex" justify="end">
        <Button shape="circle" icon="plus" onClick={handleAddItem} />
      </Row>
      {items.map((item, index) => {
        return (
          <Row type="flex" align="middle" justify="space-around" key={index}>
            <Col xs={{span: 24}} sm={{span: 9}}>
              <FormItem label="Name">
                {getFieldDecorator(`menu.links.${index}.name`, {
                  initialValue: item.name,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={{span: 20}} sm={{span: 9}}>
              <FormItem label="Page">
                {getFieldDecorator(`menu.links.${index}.path`, {initialValue: item.path})(
                  <Select>
                    {pages.map(page => (
                      <Option value={page.id} key={page.id}>
                        {page.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col sm={{span: 2}} xs={{span: 4}}>
              <Button shape="circle" icon="delete" onClick={() => handleDeleteItem(index)} />
            </Col>
          </Row>
        );
      })}
    </>
  );
}

export default MenuSettingsForm;
