import React from 'react';
import ReactLiquidGauge from 'react-liquid-gauge';
import {color} from 'd3-color';
import {interpolateRgb} from 'd3-interpolate';
import {ElementSchema} from '../../../../interfaces/Elements';
import * as interfaces from './interfaces';
import useKPI from '../useKPI';

const startColor = '#6495ed'; // cornflowerblue
const endColor = '#dc143c'; // crimson
const interpolate = interpolateRgb(startColor, endColor);

type Props = {
  element: ElementSchema<interfaces.Settings>;
  style: {[key: string]: any};
};

function LiquidGauge(props: Props) {
  const {value} = useKPI(props);
  const {
    style,
    element: {settings = {}, width, height},
  } = props;
  const {title, maxValue = 100} = settings;

  const getPercentageValue = () => {
    if (!maxValue || Number.isNaN(maxValue)) {
      return;
    }
    return (Number(value) * 100) / maxValue;
  };

  const percentageValue = getPercentageValue();
  if (!percentageValue && percentageValue !== 0) {
    return '';
  }
  const fillColor = color(interpolate(percentageValue / 100));
  const gradientStops = [
    {
      key: '0%',
      stopColor: fillColor && fillColor.darker(0.5).toString(),
      stopOpacity: 1,
      offset: '0%',
    },
    {
      key: '50%',
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: '50%',
    },
    {
      key: '100%',
      stopColor: fillColor && fillColor.brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: '100%',
    },
  ];

  const waveColor = color('#fff');
  const textColor = color('#444');
  return (
    <ReactLiquidGauge
      style={{width: '100%', height: '100%', ...style, overflow: 'hidden'}}
      width={width}
      height={`calc(${height}- 30)`}
      value={percentageValue}
      percent="%"
      textSize={1}
      textOffsetX={0}
      textOffsetY={0}
      textRenderer={(props: {
        value: number;
        height: number;
        width: number;
        textSize: number;
        percent: number;
      }) => {
        const value = Math.round(props.value);
        const radius = Math.min(props.height / 2, props.width / 2);
        const textPixels = (props.textSize * radius) / 2;
        const valueStyle = {
          fontSize: textPixels,
        };
        const percentStyle = {
          fontSize: textPixels * 0.6,
        };

        return (
          <tspan>
            <tspan className="value" style={valueStyle}>
              {value}
            </tspan>
            <tspan style={percentStyle}>{props.percent}</tspan>
            <tspan style={{fontSize: 20}} y="40" x="1">
              {title}
            </tspan>
          </tspan>
        );
      }}
      riseAnimation
      waveAnimation
      waveFrequency={2}
      waveAmplitude={1}
      gradient
      gradientStops={gradientStops}
      circleStyle={{
        fill: fillColor,
      }}
      waveStyle={{
        fill: fillColor,
      }}
      textStyle={{
        fill: textColor && textColor.toString(),
        fontFamily: 'Arial',
      }}
      waveTextStyle={{
        fill: waveColor && waveColor.toString(),
        fontFamily: 'Arial',
      }}
    />
  );
}

export default LiquidGauge;
