import {Reducer} from 'react';
import {Actions, IEditor} from './interfaces';

const initialElementPosition = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: '0',
  height: '0',
};

const defaultState: IEditor = {
  isLoading: false,
  isSaving: false,
  hasUnsavedChanges: false,
  isDragging: false,
  isResizing: {
    isResize: false,
    elementId: null,
  },
  isElementsLibraryVisible: localStorage.getItem('elementsLibraryVisibility') === 'true',
  isSettingsVisible: false,
  isPagesVisible: false,
  isHotKeysModalVisible: false,
  selectedElementPosition: initialElementPosition,
  editorInfo: {
    name: '',
    protectionType: '',
    numberOfPages: 0,
  },
  settings: {
    enableSnap: JSON.parse(localStorage.getItem('editorSettings') || '{"enableSnap" : true}')
      .enableSnap,
    height: JSON.parse(localStorage.getItem('editorSettings') || '{"height": 1300}').height,
    showElementBorders: JSON.parse(
      localStorage.getItem('editorSettings') || '{"showElementBorders": true}',
    ).showElementBorders,
    showGrid: JSON.parse(localStorage.getItem('editorSettings') || '{"showGrid": true}').showGrid,
  },
};

export const editorStore: Reducer<IEditor, {type: Actions; payload: any}> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case Actions.SET_ELEMENT_POSITION:
      return {...state, selectedElementPosition: action.payload};
    case Actions.SET_IS_DRAGGING:
      return {...state, isDragging: action.payload};
    case Actions.SET_SETTINGS:
      localStorage.setItem('editorSettings', JSON.stringify(action.payload));
      return {...state, settings: {...state.settings, ...action.payload}};
    case Actions.SET_IS_LOADING:
      return {...state, isLoading: action.payload};
    case Actions.TOGGLE_LIBRARY_VISIBILITY: {
      const isVisible = !state.isElementsLibraryVisible;
      localStorage.setItem('elementsLibraryVisibility', `${isVisible}`);

      return {
        ...state,
        isElementsLibraryVisible: isVisible,
        isSettingsVisible: isVisible ? false : state.isSettingsVisible,
        isPagesVisible: isVisible ? false : state.isPagesVisible,
      };
    }
    case Actions.TOGGLE_SETTINGS_VISIBILITY: {
      const isVisible = !state.isSettingsVisible;
      return {
        ...state,
        isSettingsVisible: isVisible,
        isElementsLibraryVisible: isVisible ? false : state.isElementsLibraryVisible,
        isPagesVisible: isVisible ? false : state.isPagesVisible,
      };
    }
    case Actions.TOGGLE_PAGES_VISIBILITY: {
      const isVisible = !state.isPagesVisible;
      return {
        ...state,
        isPagesVisible: isVisible,
        isSettingsVisible: isVisible ? false : state.isSettingsVisible,
        isElementsLibraryVisible: isVisible ? false : state.isElementsLibraryVisible,
      };
    }
    case Actions.CLOSE_EDITOR_SETTINGS: {
      return {
        ...state,
        isPagesVisible: false,
        isSettingsVisible: false,
        isElementsLibraryVisible: false,
      };
    }
    case Actions.SET_ELEMENT_IN_COPY:
      return {...state, elementInCopy: action.payload};
    case Actions.TOGGLE_HOT_KEYS_VISIBILITY:
      return {...state, isHotKeysModalVisible: !state.isHotKeysModalVisible};
    case Actions.SET_SAVING_LOADER_STATUS: {
      return {...state, isSaving: action.payload};
    }
    case Actions.SET_CHANGES_STATUS: {
      if (action.payload !== state.hasUnsavedChanges) {
        return {...state, hasUnsavedChanges: action.payload};
      }
      return state;
    }
    case Actions.SET_EDITOR_INFO: {
      return {...state, editorInfo: {...state.editorInfo, ...action.payload}};
    }
    case Actions.SET_IS_RESIZING: {
      return {...state, isResizing: action.payload};
    }
    default:
      return state;
  }
};
