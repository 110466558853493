import axios from 'axios';
import {visualizationUrl} from './config';

const instance = axios.create({
  baseURL: visualizationUrl,
  withCredentials: false,
});

// instance.interceptors.response.use(({data}) => data);

instance.interceptors.request.use(config => {
  config.headers.Authorization = instance.defaults.headers['Authorization'];
  // if (config.params) {
  //   config.url = replaceParams(config.url, config.params);
  // }
  return config;
});

export const authorizationToken = {
  get: () => instance.defaults.headers['Authorization'],
  set: (token: string) => {
    instance.defaults.headers['Authorization'] = `Bearer ${token}`;
  },
  remove: () => {
    delete instance.defaults.headers['Authorization'];
  },
};

export function injectParamsIntoUrl<P = {[key: string]: string}>(url: string, params: P): string {
  Object.keys(params).forEach(key => {
    // @ts-ignore
    const paramValue = params[key];
    if (paramValue) {
      url = url.replace(`:${key}`, encodeURIComponent(paramValue));
    }
  });

  return url;
}

export function setBaseUrl(baseUrl: string) {
  instance.defaults.baseURL = baseUrl;
}

export default instance;
