// @flow
import React, {useState, useEffect, useCallback, useContext} from 'react';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
// import {Translate} from 'react-redux-i18n';
import {WrappedFormUtils} from 'antd/lib/form/Form';
import APIs from './apis';
import {AppContext} from '../../../App';

const {Option} = Select;
const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
};

type Props = {
  settings?: {[key: string]: string};
  form: WrappedFormUtils;
  style?: {[key: string]: any};
  fieldName?: string;
};

// const params = {applicationId: 'fdsfds', organizationId: 'fdsfd', visualizationId: 'fds'};

function Settings(props: Props) {
  const {params} = useContext(AppContext);
  const [actions, setActions] = useState<{name: string}[]>([]);
  const {form, style = {}, settings = {}, fieldName = 'name'} = props;
  const {getFieldDecorator} = form;

  const getActionsOptions = (actions: {name: string}[]) => {
    if (actions) {
      return actions.map((action, i) => (
        <Option key={i} value={action.name}>
          {action.name}
        </Option>
      ));
    }
  };

  const getActions = useCallback(() => {
    APIs.fetchActions({
      organizationId: params.organizationId,
      applicationId: params.applicationId,
    }).then(result => {
      if (result) {
        setActions(result.data);
      }
    });
  }, [params.applicationId, params.organizationId]);

  useEffect(() => {
    getActions();
  }, [getActions]);

  return (
    <FormItem {...formItemLayout} label="Action Name" style={style}>
      {getFieldDecorator(`settings.${fieldName}`, {
        initialValue: settings[fieldName] || '',
      })(
        <Select showSearch placeholder="Select an action" allowClear={true}>
          {getActionsOptions(actions)}
        </Select>,
      )}
    </FormItem>
  );
}

export default Settings;
