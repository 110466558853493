import {useState, useEffect, useCallback} from 'react';
import {ElementDataSource, ElementSchema} from '../../../interfaces/Elements';
import useElements from '../../Elements';
import {listenOnEvent, removeEventListener} from 'adaptors/webSocket';
import {getDataSourceEventName} from '../../../utils/dataSources';
import {MetricsData} from '../../../interfaces/Data';

type Props = {
  dataSource?: ElementDataSource;
  element: ElementSchema;
};

type Value = string | number;

interface Response {
  value: Value;
  isLoading: boolean;
}

function useKPI(props: Props): Response {
  const {element} = props;
  const {getElementData} = useElements();
  const {id, dataSource} = element;
  const [value, setValue] = useState<string | number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    getElementData({id, dataSource}, {pageSize: 1})
      .then(({data}) => {
        const [lastValue] = data;
        if (data.length && dataSource && dataSource.dataKeys) {
          const value = lastValue[dataSource.dataKeys[0]];
          setValue(value);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getElementData, id, dataSource]);

  const handleRealTimeData = useCallback(
    data => {
      if (dataSource) {
        const newValue = data.find(
          (record: MetricsData) => dataSource.dataKeys && dataSource.dataKeys.includes(record.key as string),
        );
        if (newValue) {
          setValue(newValue.value);
        }
      }
    },
    [dataSource],
  );

  useEffect(() => {
    fetchData();
    // listenOnData();
  }, [fetchData]);

  useEffect(() => {
    if (dataSource) {
      const eventName = getDataSourceEventName(dataSource);
      if (eventName) {
        listenOnEvent(eventName, handleRealTimeData, id);
        return () => removeEventListener(id);
      }
    }
  }, [dataSource, handleRealTimeData, id]);

  return {value, isLoading};
}

export default useKPI;
