import React, {useEffect, useState, createContext} from 'react';
import ViewMode from './modules/ViewMode';
import EditMode from './modules/EditMode';
import './main.css';
import {useRouteMatch} from 'react-router-dom';
import {authorizationToken as authorizationTokenApi, setBaseUrl} from 'adaptors/httpClient';
import queryString from 'query-string';
import {visualizationUrl} from 'adaptors/config';
import {connectPrivateSocket, connectPublicSocket} from 'adaptors/webSocket';

export interface EditParams {
  organizationId: string;
  applicationId: string;
  visualizationId: string;
}

export interface PublicParams {
  visualizationId: string;
  publicId: string;
}

const params: Partial<EditParams> & Partial<PublicParams> & {mode?: 'preview'} = queryString.parse(
  window.location.search,
);
const isPreview = params.mode === 'preview';
const {organizationId, applicationId, visualizationId, publicId} = params;

if (!organizationId || !applicationId) {
  // throw new Error('Required params are not provided!');
}

export const AppContext = createContext<{params: Partial<EditParams> & Partial<PublicParams>}>({
  params: {
    publicId: String(publicId),
    organizationId: String(organizationId),
    applicationId: String(applicationId),
    visualizationId: String(visualizationId),
  },
});

const initialToken = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'eyJhbGciOiJFUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJXaGFhbDlNMzA4NVVEdklqbGtVZ2Z6bE8wMmk5SXhmNXhqbW15V05TRDJrIn0.eyJqdGkiOiJjZjFjN2FjNy05NTUxLTQ2MzgtYTdlMS05YjAyMzI2NzRiN2QiLCJleHAiOjE1NzYwNTkyOTUsIm5iZiI6MCwiaWF0IjoxNTc1MTk1Mjk1LCJpc3MiOiJodHRwczovL2FjY291bnRzLnN0YWdpbmcuY2VydmVsbG8uaW8vYXV0aC9yZWFsbXMvY2VydmVsbG8iLCJzdWIiOiIyNGEyOTJjYi0zNGIyLTRiYjMtYTJkNy1hOTJhYzkzNjE5MjIiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJjZXJ2ZWxsby11aSIsIm5vbmNlIjoiNjMyZTk0NmItOThiMS00ODliLTk5NDUtN2RiZjVmMTRmM2I4IiwiYXV0aF90aW1lIjoxNTc1MTk1Mjk0LCJzZXNzaW9uX3N0YXRlIjoiZmQ4Y2NiMzYtNjlkZi00MDJjLWIyMDEtYTlmOWQ2YjQzOTRmIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyIqIl0sInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJzeXN0ZW0tYWRtaW4iLCJvcmdhbml6YXRpb24tb3duZXIiLCJzeXN0ZW0tdXNlciIsIm9mZmxpbmVfYWNjZXNzIiwiYXBwbGljYXRpb24tb3duZXIiLCJ1bWFfYXV0aG9yaXphdGlvbiIsIm1hbnVmYWN0dXJlci1vd25lciJdfSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiS2hhbGVkIEtoYWxpbCIsInByZWZlcnJlZF91c2VybmFtZSI6ImtraGFsaWxAaW90Ymx1ZS5uZXQiLCJnaXZlbl9uYW1lIjoiS2hhbGVkIiwibG9jYWxlIjoiZW4iLCJmYW1pbHlfbmFtZSI6IktoYWxpbCIsImVtYWlsIjoia2toYWxpbEBpb3RibHVlLm5ldCJ9.MEUCIF51-E_Y-rNW0bPy3ddkrtyRRHiR7ECh4TNuqpplnWUyAiEA6DMbSF9a3xPan91K_JPLV8mFDLwhu1X7ODn6rgFp9pA'
  }
  return null;
};

function App() {
  const [authorizationToken, setAuthorizationToken] = useState<string | null>(initialToken());

  /**
   * Matches history url for both public and private views
   */
  const match = useRouteMatch<EditParams & PublicParams>([
    '/public/:publicId',
    '/:visualizationId',
  ]);

  if (!match || !match.params) {
    throw new Error('Required params are not provided!');
  }

  const {publicId, visualizationId} = match.params;

  useEffect(() => {
    window.addEventListener('message', ({data}) => {
      /**
       * Update HTTP authorization token whenever it changes
       */
      if (data.token) {
        setAuthorizationToken(data.token);
      }
    });
  }, []);

  useEffect(() => {
    if (authorizationToken) {
      authorizationTokenApi.set(authorizationToken);
    }
  }, [authorizationToken]);

  const basePublicUrl = `${visualizationUrl}/v1/visualizations/public/${publicId}`;
  const basePrivateUrl = `${visualizationUrl}/v1/organizations/${organizationId}/applications/${applicationId}`;

  if (publicId) {
    setBaseUrl(basePublicUrl);
    connectPublicSocket(publicId);
  } else if (visualizationId && authorizationToken) {
    setBaseUrl(basePrivateUrl);
    connectPrivateSocket(authorizationToken, String(organizationId));
    // listenOnEvent('0dc9cdd4-5482-4170-b31a-448514b5dd5b-TELEMETRIES', (data: any) => console.log(data));
  }

  if (publicId || isPreview) {
    return (
      <AppContext.Provider
        value={{
          params: {
            applicationId: String(applicationId),
            organizationId: String(organizationId),
            publicId,
            visualizationId,
          },
        }}
      >
        <ViewMode
          publicId={publicId}
          isPreview={isPreview}
          setAuthorizationToken={setAuthorizationToken}
        />
      </AppContext.Provider>
    );
  } else if (visualizationId && authorizationToken) {
    return (
      <AppContext.Provider
        value={{
          params: {
            applicationId: String(applicationId),
            organizationId: String(organizationId),
            visualizationId: String(visualizationId),
          },
        }}
      >
        <EditMode />
      </AppContext.Provider>
    );
  }
  return null;
}

export default App;
