import {Reducer} from 'react';
import {Actions, IMenu} from 'interfaces/NavigationMenu';

const defaultState: IMenu = {
  enableIcon: false,
  enabled: false,
  links: [
    // {
    //   name: 'Home',
    //   path: '/',
    // },
  ],
  icon: {
    url: 'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
  },
  position: 'TOP',
  style: {},
  settings: {},
};

export const navigationMenu: Reducer<IMenu, {type: Actions; payload: any}> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case Actions.SET_MENU_OPTIONS:
      return {...state, ...action.payload};
    case Actions.ADD_MENU_LINK:
      return {...state, links: [...state.links, action.payload]};
    default:
      return state;
  }
};
