import React, {useCallback} from 'react';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Tabs from 'antd/lib/tabs';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {useDispatch} from 'react-redux';
import {setMenuOptions} from '../../../NavigationMenu';
import MenuSettingsForm from './SettingsForm';
import MenuLinksForm from './MenuLinksForm';
import usePages from '../../../Pages';

const {TabPane} = Tabs;
type Props = {
  visible: boolean;
  form: WrappedFormUtils;
  onClose: Function;
};

function MenuSettings(props: Props) {
  const {visible, form, onClose} = props;
  const {updateMenuSettings} = usePages();
  const dispatch = useDispatch();

  const savePageSettings = useCallback(() => {
    form.validateFieldsAndScroll(
      {
        force: true,
      },
      (err, values) => {
        if (!err) {
          dispatch(setMenuOptions(values.menu));
          updateMenuSettings({...values});
          onClose();
        }
      },
    );
  }, [dispatch, form, onClose]);

  return (
    <Modal
      title="Navigation Menu Settings"
      visible={visible}
      onCancel={() => onClose()}
      onOk={savePageSettings}
      bodyStyle={{padding: '0px 24px 24px 24px'}}
    >
      <Form>
        <Tabs>
          <TabPane key="menuSettings" tab="Menu Settings">
            <MenuSettingsForm form={form} />
          </TabPane>
          <TabPane key="menuLinks" tab="Menu Links">
            <MenuLinksForm form={form} />
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}

export default Form.create<Props>()(MenuSettings);
