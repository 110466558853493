import React, {useEffect} from 'react';
import Switch from 'antd/es/switch';
import Form from 'antd/es/form';
import Settings from '../Control/Settings';
import {ElementSchema} from '../../../interfaces/Elements';
import * as interfaces from './input/interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
const FormItem = Form.Item;

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function ControlItemSettings(props: Props) {
  const {
    form,
    element: {settings},
  } = props;

  return (
    <>
      <Settings
        settings={{actionName: settings ? settings.actionName || '' : ''}}
        fieldName="actionName"
        form={form}
        style={{width: '400px'}}
      />
    </>
  );
}
export default ControlItemSettings;
