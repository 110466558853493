import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {createSelector} from 'reselect';
import {getDataStore} from 'store';
import {DataActions, ElementData, DataOperations} from './DataStore/interfaces';

function DataOps(): DataOperations {
  const dispatch = useDispatch();
  const getElementData = useCallback(
    (id: string) =>
      createSelector(
        [getDataStore],
        dataStore => {
          return dataStore[id];
        },
      ),
    [],
  );

  const addElementData = useCallback(
    (data: ElementData) => {
      dispatch({
        type: DataActions.ADD,
        payload: data,
      });
    },
    [dispatch],
  );

  return {getElementData, addElementData};
}

export default DataOps;
