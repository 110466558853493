import React, {useCallback} from 'react';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import {ElementSchema} from '../../../../interfaces/Elements';
import * as interfaces from '../Radio/interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import useController from '../../Control/Prototype';

const {Group} = Checkbox;
const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
}

function CheckBox(props: Props) {
  const {
    element: {settings = {}},
    form,
  } = props;

  const {items = [], fieldName, label} = settings || {};
  const {value, sendAction} = useController(props);

  const handleChange = useCallback(
    checkedValue => {
      if (settings.actionName && checkedValue) {
        sendAction({
          name: settings.actionName,
          value: checkedValue,
        });
      }
    },
    [sendAction, settings.actionName],
  );

  if (form) {
    return (
      <FormItem label={label} {...formItemLayout}>
        {form.getFieldDecorator(`${fieldName}`, {
          initialValue: Array.isArray(value) ? value : [value],
        })(
          <Group onChange={handleChange}>
            {items.map((item, index) => (
              <Checkbox value={item.value} key={index}>
                {item.label}
              </Checkbox>
            ))}
          </Group>,
        )}
      </FormItem>
    );
  }
  return (
    <Group onChange={handleChange} defaultValue={Array.isArray(value) ? value : [value]}>
      {items.map((item, index) => (
        <Checkbox value={item.value} key={index}>
          {item.label}
        </Checkbox>
      ))}
    </Group>
  );
}
export default CheckBox;
