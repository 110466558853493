import Component from './Form';
import {ComponentCharacteristics} from 'interfaces/Elements';

const FormBluePrint: ComponentCharacteristics<{}> = {
  type: 'form',
  name: 'Form Container',
  category: 'Form',
  Component,
  settings: [],
  width: 6,
  height: 6,
};
export default FormBluePrint;
