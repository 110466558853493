import React, {useEffect, useState} from 'react';
import Button from 'antd/es/button';
import {ColorResult, SketchPicker} from 'react-color';

export type ColorType = {r: number; g: number; b: number; a?: number};
type Props = {
  initialValue?: ColorType;
  handleColorChange(color: ColorType): void;
};
const initialColor = {
  r: 255,
  g: 255,
  b: 255,
  a: 1,
};
function ColorPicker(props: Props) {
  const {initialValue, handleColorChange} = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [color, setColor] = useState<ColorType>(initialColor);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleChange = ({rgb}: ColorResult): void => {
    setColor(rgb);
    handleColorChange(rgb);
  };

  useEffect(() => {
    if (initialValue) {
      setColor(initialValue);
    } else {
      setColor(initialColor);
    }
  }, [initialValue]);

  return (
    <div>
      <Button
        style={{
          width: 50,
          height: 25,
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          verticalAlign: 'middle',
        }}
        onClick={handleClick}
      />

      {isVisible ? (
        <div style={{zIndex: 200, position: 'absolute'}}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClick}
          />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

export default ColorPicker;
