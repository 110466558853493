import RealTimeMaps from './RealTimeMaps';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {DataSourceFields, DataTypes} from 'interfaces/DataSources';
import {Settings as SettingsSchema} from '../interfaces';

const MarkersMap: ComponentCharacteristics<SettingsSchema> = {
  category: 'Maps',
  type: 'realtimeMap',
  name: 'RealTime Maps',
  Component: RealTimeMaps,
  settings: Settings,
  dataSourcesOptions: {
    initialValue: {
      dataType: DataTypes.TELEMETRIES,
    },
    disabledFields: [DataSourceFields.DataType],
  },
};

export default MarkersMap;
