import React, {useState, useEffect, useCallback} from 'react';
import {LibraryItem} from '../../../interfaces/Elements';
import DraggableItem from './DragSource';

// TODO: Refactor this part so that Categorized components don't know about Drag API.
export interface IDraggableItem extends LibraryItem {
  Component: React.ReactNode;
}

export type CategorizedComponents = {[category: string]: IDraggableItem[]};

type CategorizedComponentsList = [string, IDraggableItem[]][];

type Props = {
  components: LibraryItem[];
  filterValue?: string;
};

const categorize = (components: LibraryItem[]): CategorizedComponents => {
  return components.reduce((accumulator: CategorizedComponents, component: LibraryItem) => {
    const {category = 'Common', type, name} = component;
    if (!accumulator[category]) {
      accumulator[category] = [];
    }

    const Component: React.ReactNode = <DraggableItem component={component} />;

    accumulator[category].push({type, name, Component});
    return accumulator;
  }, {});
};

function CategorizeComponents(props: Props): CategorizedComponentsList {
  const {components, filterValue} = props;
  const [filteredComponents, setFilteredComponents] = useState<CategorizedComponentsList>([]);

  const filterComponentsList = useCallback(
    (components: CategorizedComponentsList): CategorizedComponentsList => {
      if (!filterValue) {
        return components;
      }
      return components.filter(([category, draggableComponent]) => {
        // return true;
        return draggableComponent.some((component: LibraryItem) => {
          return component.name.toLowerCase().includes(filterValue.toLowerCase());
        });
      });
    },
    [filterValue],
  );
  useEffect(() => {
    setFilteredComponents(filterComponentsList(Object.entries(categorize(components))));
  }, [components, filterComponentsList]);

  return filteredComponents.sort();
}

export default CategorizeComponents;
