import {VisualizationInfo} from 'interfaces/Pages';
import instance, {injectParamsIntoUrl} from 'adaptors/httpClient';

const Urls = {
  pages: '/',
  page: '/pages/:pageId',
  verifyPIN: '/login',
  editor: '/visualizations/:visualizationId',
};

export default {
  getPublicVisualizationInfo: (): Promise<VisualizationInfo> => {
    return instance.get(Urls.pages).then(({data}) => data.result);
  },
  verifyPIN: ({publicId, pin}: {publicId: string; pin: string}): Promise<string> => {
    const url = injectParamsIntoUrl<{publicId: string}>(Urls.verifyPIN, {publicId});
    return instance
      .post(url, {
        pin,
      })
      .then(({data}) => data.result);
  },
  getShareOptions: <P, O>(params: P): Promise<O> => {
    const url = injectParamsIntoUrl<P>(Urls.editor, params);
    return instance.get(url).then(({data}) => data.result);
  },
  editShareOptions: <P = {visualizationId: string}>(data: {}, params: P): Promise<string> => {
    const url = injectParamsIntoUrl<P>(Urls.editor, params);
    return instance.put(url, data).then(({data}) => data.result);
  },
};
