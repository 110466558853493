import React from 'react';
import {
  ConnectDragSource,
  // DragSource,
  // DragSource as createDragSource,
  // DragSourceConnector,
  DragSourceMonitor,
  useDrag,
} from 'react-dnd';
import {CapitalizedText} from './StyledComponents';
import {LibraryItem} from '../../../interfaces/Elements';

type Props = {
  component: LibraryItem;
};

interface DragProps {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
}

export default function DragSource(props: Props) {
  const {component} = props;
  const {name, canContainComponents} = component;

  const [{isDragging}, drag] = useDrag({
    item: {
      type: component.type,
      elementType: component.type,
      canContainComponents,
      width: component.width,
      height: component.height,
    },
    canDrag: true,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // const componentSource = {
  //   beginDrag: () => {
  //     return {
  //       type,
  //       canContainComponents,
  //       width: component.width || 100,
  //       height: component.height || 100,
  //     };
  //   },
  // };

  // const collect = (connect: DragSourceConnector, monitor: DragSourceMonitor): DragProps => {
  //   return {
  //     // Call this function inside render()
  //     // to let React DnD handle the drag events:
  //     connectDragSource: connect.dragSource(),
  //     // You can ask the monitor about the current drag state:
  //     isDragging: monitor.isDragging(),
  //   };
  // };

  return (
    <div ref={drag} style={{padding: 1.5}}>
      <CapitalizedText>{name.toLowerCase()}</CapitalizedText>
    </div>
  );

  // return createDragSource(type, componentSource, collect)(
  //   ({connectDragSource}: {connectDragSource: ConnectDragSource}) =>
  //     connectDragSource(
  //       <div style={{padding: 1.5}}>
  //         <LibraryItem>
  //           <CapitalizedText>{type.toLowerCase()}</CapitalizedText>
  //         </LibraryItem>
  //       </div>,
  //     ),
  // );
}
