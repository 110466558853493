import React, {useEffect, useState, memo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import List from 'antd/es/list';
import Icon from 'antd/es/icon';
import usePages from '../../Pages';
import CreatePageModal from './PageSettings/CreatePageModal';
import {StyledContainer} from './styles';
import {SearchIcon, SearchInput} from '../ElementsLibrary/StyledComponents';
import {Page} from 'interfaces/Pages';

interface Props {
  isVisible: boolean;
  setPageInSettings: Function;
}

function Pages(props: Props) {
  const {isVisible, setPageInSettings} = props;
  const {
    setCurrentPage,
    createPage,
    pagesSelector,
    currentPageSelector,
    isLoadingSelector,
  } = usePages();
  const pages = useSelector(pagesSelector);
  const isLoading = useSelector(isLoadingSelector);
  const currentPage = useSelector(currentPageSelector);
  const [filteredPages, setFilteredPages] = useState(pages);
  const [isPageModalOpen, setPageModalVisibility] = useState(false);

  const changePage = useCallback(
    id => {
      setCurrentPage(id);
    },
    [setCurrentPage],
  );

  const onSearchPages = useCallback(
    (e: {target: HTMLInputElement}) => {
      const {value} = e.target;
      setFilteredPages(pages.filter(page => page.name.toLowerCase().includes(value.toLowerCase())));
    },
    [pages],
  );

  const addPage = useCallback(
    (params: {}) => {
      createPage(params);
    },
    [createPage],
  );

  const RenderPageItem = useCallback(
    (page: Page) => {
      return (
        <List.Item
          style={{
            padding: 5,
            cursor: 'pointer',
            backgroundColor:
              currentPage && page.id === currentPage.id ? 'rgba(176, 172, 250, 0.2)' : 'inherit',
          }}
        >
          <Row className="full-width">
            <Col onClick={() => changePage(page.id)} span={22} style={{wordWrap: 'break-word'}}>
              {page.name}
            </Col>
            <Col span={2}>
              <Icon type="setting" onClick={() => setPageInSettings(page)} />
            </Col>
          </Row>
        </List.Item>
      );
    },
    [changePage, currentPage, setPageInSettings],
  );

  useEffect(() => {
    setFilteredPages(pages);
  }, [pages]);

  return (
    <StyledContainer visible={isVisible}>
      <SearchInput
        addonAfter={<SearchIcon type="search" />}
        size="default"
        onChange={onSearchPages}
      />
      <div style={{padding: '10px'}}>
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          <List
            id="id"
            loading={isLoading}
            size="small"
            style={{
              minHeight: 100,
              maxHeight: 200,
              overflow: 'auto',
            }}
            dataSource={filteredPages}
            renderItem={RenderPageItem}
          />
        </div>
        <Row
          style={{
            borderTop: '0.5px solid #e0e0e0',
            paddingTop: 10,
            cursor: 'pointer',
            textAlign: 'center',
            color: '#6765f5',
            fontWeight: 'bold',
          }}
          onClick={() => setPageModalVisibility(true)}
        >
          <Icon type="plus-circle" style={{paddingRight: 10}} />
          Add Page
        </Row>
      </div>
      <CreatePageModal
        isVisible={isPageModalOpen}
        onCancel={() => setPageModalVisibility(false)}
        handleSubmit={addPage}
      />
    </StyledContainer>
  );
}

export default memo(Pages);
