import React, {useEffect} from 'react';
import Form from 'antd/es/form';
import Switch from 'antd/es/switch';
import * as interfaces from './interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {ElementSchema} from '../../../../interfaces/Elements';
import useController from '../../Control/Prototype';

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form?: WrappedFormUtils;
}
const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function SwitchCmp(props: Props) {
  const {value, sendAction} = useController(props);
  const {
    element: {settings = {}},
    form,
  } = props;
  const {fieldName, label} = settings || {};

  const handleChange = (value: boolean) => {
    if (settings.actionName) {
      const valuee = value ? settings.customOnPayload || value : settings.customOffPayload || value;

      sendAction({
        name: settings.actionName,
        value: valuee,
      });
    }
  };

  useEffect(() => {
    const valuee =
      settings.customOnKey === value ? true : settings.customOffKey === value ? false : null;
    if (valuee === null || !form || !fieldName) {
      return;
    }
    form.setFieldsValue({[fieldName]: valuee});
  }, [fieldName, form, settings.customOffKey, settings.customOnKey, value]);

  if (form) {
    return (
      <FormItem label={label} {...formItemLayout}>
        {form.getFieldDecorator(`${fieldName}`, {
          valuePropName: 'checked',
          initialValue: settings.customOnPayload
            ? value === settings.customOnPayload
            : Boolean(value),
        })(<Switch onChange={handleChange} />)}
      </FormItem>
    );
  }
  return (
    <Switch
      checked={settings.customOnPayload ? value === settings.customOnPayload : Boolean(value)}
      onChange={handleChange}
    />
  );
}

export default SwitchCmp;
