import React from 'react';

type Props = {
  height?: number;
};

function ResizableRow(props: Props) {
  return (
    <div
      style={{
        position: 'absolute',
        top: -10,
        width: '100%',
        height: props.height || 35,
        left: 0,
        cursor: 'row-resize',
      }}
    />
  );
}

export default ResizableRow;
