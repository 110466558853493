import {createStore} from 'redux';
import rootReducer from './reducers';
import {Store} from './interfaces';

const store = createStore<Store, any, any, any>(rootReducer());

export const getCurrentState = () => {
  const state: Store = store.getState();
  const {elements, selectedElement} = state.elementsStore;
  const {pages, currentPage} = state.pagesStore;
  const {elementInCopy, hasUnsavedChanges} = state.editorStore;

  return {elements, selectedElement, pages, currentPage, elementInCopy, hasUnsavedChanges};
};

export const getEditorStore = (store: Store) => store.editorStore;
export const getElementsStore = (store: Store) => store.elementsStore;
export const getPagesStore = (store: Store) => store.pagesStore;
export const getDataStore = (store: Store) => store.dataStore;

export default store;
