import {Page} from 'interfaces/Pages';

export interface PagesStore {
  pages: Array<Page>;
  currentPage: Page | null;
  isLoading: boolean;
}

export enum Actions {
  SET_PAGES='SET_PAGES',
  SET_CURRENT_PAGE='SET_CURRENT_PAGE',
  SET_IS_LOADING='SET_IS_PAGES_LOADING'
}
export interface Action {
  type: Actions;
  payload: any;
}