import React, {useEffect} from 'react';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input';
import {FormLabel} from './styledComponents';
import {SettingsElementProps} from 'interfaces/Settings';
import {Settings as ISettings} from './interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {getCurrentState} from '../../../../store';

const FormItem = Form.Item;

interface Props extends SettingsElementProps<ISettings> {
  form: WrappedFormUtils;
}

function Settings(props: Props) {
  const {
    formItemLayout,
    form: {getFieldDecorator},
    element: {settings, id},
  } = props;
  const {elements} = getCurrentState();
  const {width, height} = elements[id];

  return (
    <Form>
      <FormItem {...formItemLayout} label={<FormLabel>URL</FormLabel>}>
        {getFieldDecorator('settings.imgUrl', {
          initialValue: settings ? settings.imgUrl : '',
        })(<Input />)}
      </FormItem>
      <FormItem {...formItemLayout} label={<FormLabel>Fixed Width</FormLabel>}>
        {getFieldDecorator('settings.width', {
          initialValue: width,
        })(<InputNumber />)}
      </FormItem>
      <FormItem {...formItemLayout} label={<FormLabel>Fixed Height</FormLabel>}>
        {getFieldDecorator('settings.height', {
          initialValue: height,
        })(<InputNumber />)}
      </FormItem>
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(Settings),
  },
];
