import {useEffect, useState, useCallback} from 'react';
import {ElementSchema} from 'interfaces/Elements';
import {MetricsData, SerialData} from 'interfaces/Data';
import useElemnets, { transformDataFunc } from "modules/Elements";
import {LogTypes} from 'modules/EditMode/Debugger/store/interfaces';
import {useDispatch} from 'react-redux';
import {addLog} from 'modules/EditMode/Debugger';
import {getDataSourceEventName} from 'utils/dataSources';
import {listenOnEvent, removeEventListener} from 'adaptors/webSocket';

interface Response {
  textData: {[key: string]: string | number};
  lastData: SerialData;
  isLoading: boolean;
}

function useTextData(element: ElementSchema): Response {
  const dispatch = useDispatch();
  const {getElementData} = useElemnets();
  const {dataSource, id, aggregation, timeWindow} = element;
  const [textData, setChartData] = useState<{[key: string]: string | number}>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastData, setLastData] = useState();

  const fetchData = useCallback(() => {
    if (!dataSource) {
      return;
    }
    setIsLoading(true);
    getElementData(
      {id, dataSource},
      {lastValue: true},
      false,
    )
      .then(({data}) => {
        setChartData(data.reduce<{[key: string]: string | number}>((res, record) => {
          if (!res[record.key]) {
            res[record.key] = record.value;
          }
          return res;
        }, {}));
        setIsLoading(false);
        dispatch(
          addLog({
            type: LogTypes.INFO,
            message: `Fetching Data for component`,
            source: {
              type: 'ELEMENT',
              id,
            },
            // time: getCurrentTime(),
          }),
        );
      })
      .catch(e => {
        dispatch(
          addLog({
            type: LogTypes.ERROR,
            message: `Error while Fetching Data for component`,
            source: {
              type: 'ELEMENT',
              id,
            },
            // time: getCurrentTime(),
          }),
        );
        setIsLoading(false);
      });
  }, [dataSource, dispatch, getElementData, id]);

  const handleRealTimeData = useCallback(
    data => {
      if (dataSource) {
        const newValue = data.find(
          (record: MetricsData) => dataSource.dataKeys && dataSource.dataKeys.includes(record.key as string),
        );
        console.log('== newValue ==>>', newValue);
        if (newValue) {
          setChartData(values => ({
            ...values,
              [newValue.key]: newValue.value,
          }));
          setLastData({...newValue, time: new Date(newValue.time).getTime()});

          // setChartData(currentData => ([
          //   ...currentData,
          //   ...transformDataFunc(data),
          // ]));
        }
      }
    },
    [dataSource],
  );

  useEffect(() => {
    fetchData();
  }, [aggregation, fetchData]);

  useEffect(() => {
    if (dataSource) {
      const eventName = getDataSourceEventName(dataSource);
      if (eventName) {
        listenOnEvent(eventName, handleRealTimeData, id);
        return () => removeEventListener(id);
      }
    }
  }, [dataSource, handleRealTimeData, id]);

  return {textData, isLoading, lastData};
}

export default useTextData;
