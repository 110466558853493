import React, {useCallback, useEffect, useState} from 'react';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {SettingsElementProps} from 'interfaces/Settings';
import * as interfaces from './interfaces';
import Settings from '../../Control/Settings';
import FormItemSettings from '../FormItemSettings';
import Input from 'antd/es/input';
import ControlItemSettings from '../ControlItemSettings';

const FormItem = Form.Item;
const {Option} = Select;

interface Props extends SettingsElementProps<interfaces.Settings> {
  form: WrappedFormUtils;
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const types = {
  static: 'Static',
  dynamic: 'Dynamic',
};

function SettingsC(props: Props) {
  const {
    form,
    element,
    form: {getFieldDecorator, getFieldValue},
    element: {settings, parentId},
  } = props;
  const [items, setItems] = useState<interfaces.Item[]>([]);

  const addItem = useCallback(() => {
    setItems(prevRules => prevRules.concat({}));
  }, []);

  const removeItem = useCallback(
    (itemIdex: number) => {
      const newItems = (getFieldValue('settings.items') || []).filter(
        (rule: interfaces.Item, index: number) => index !== itemIdex,
      );
      setItems(newItems);
      props.setSettings({settings: {items: newItems}});
    },
    [getFieldValue, props],
  );

  useEffect(() => {
    if (settings && settings.items) {
      setItems(settings.items);
    }
  }, [settings]);

  return (
    <Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        {parentId && <FormItemSettings form={form} element={element} />}
        <ControlItemSettings {...props} />
      </div>
      {/*  <FormItem label="Items Value" style={{width: '300px'}} {...formItemLayout}>*/}
      {/*    {getFieldDecorator('settings.itemsValue', {*/}
      {/*      initialValue: settings && settings.itemsValue,*/}
      {/*    })(*/}
      {/*      <Select>*/}
      {/*        {Object.entries(types).map(([key, value]) => (*/}
      {/*          <Option value={key}>{value}</Option>*/}
      {/*        ))}*/}
      {/*      </Select>,*/}
      {/*    )}*/}
      {/*  </FormItem>*/}
      {/*</div>*/}
      {/*{getFieldValue('settings.itemsValue') === 'static' && (*/}
      <Row type="flex" justify="start">
        <Col style={{marginLeft: '10px'}}>
          <Button onClick={addItem} icon="plus" shape="circle" type="dashed" />
        </Col>
        <Col md={4} sm={8} xs={12} style={{margin: 'auto auto auto 10px'}}>
          Add Item
        </Col>
      </Row>
      {items.map((item, index) => {
        return (
          <Row gutter={24} key={index}>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.items[${index}].label`, {
                  initialValue: item.label,
                })(<Input placeholder="Label" />)}
              </FormItem>
            </Col>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.items[${index}].value`, {
                  initialValue: item.value,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col md={3} sm={16} xs={24}>
              <Button style={{marginBottom: 24}} onClick={() => removeItem(index)} icon="close" />
            </Col>
          </Row>
        );
      })}
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(SettingsC),
  },
];
