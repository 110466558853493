import React, {useCallback, useEffect, useRef} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import useKPI from '../useKPI';
import {ElementSchema} from 'interfaces/Elements';
import {Settings} from './interfaces';
import Spinner from '../../../../commons/Spinner';
import Label from 'amcharts/Label';

type Props = {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
};

am4core.useTheme(am4themes_animated);

function Gauge(props: Props) {
  const {
    style,
    element: {settings},
  } = props;
  const {value, isLoading} = useKPI({element: props.element});
  const chartElementRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<am4charts.GaugeChart>(null);
  const chartLabel = useRef<Label>(null);
  const chartHand = useRef<am4charts.ClockHand>(null);
  const maxValue = settings && settings.maxValue;
  const metrics = settings && settings.metrics;

  const initializeChart = useCallback(() => {
    if (!chartElementRef.current) {
      return;
    }

    const chart = am4core.create(chartElementRef.current, am4charts.GaugeChart);
    chart.innerRadius = am4core.percent(82);
    // @ts-ignore
    chartRef.current = chart;
  }, []);

  const createAxis = useCallback(() => {
    if (!chartRef.current) {
      return;
    }
    const chart = chartRef.current;
    // @ts-ignore
    const axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 100;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.length = 10;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = 40;
    return axis;
  }, []);

  const setGaugeHands = useCallback(() => {
    if (!chartRef.current) {
      return;
    }
    const axis = createAxis();
    const chart = chartRef.current;
    const hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis;
    hand.innerRadius = am4core.percent(20);
    hand.startWidth = 10;
    hand.pin.disabled = true;
    hand.value = 50;

    // @ts-ignore
    chartHand.current = hand;
  }, [createAxis]);

  const updateAxis = useCallback(() => {
    if (!chartHand.current || !maxValue) {
      return;
    }

    //@ts-ignore
    chartHand.current.axis.max = Number(maxValue);
  }, [maxValue]);

  const updateMatrics = useCallback(() => {
    if (!chartHand.current || !metrics) {
      return;
    }

    chartHand.current.axis.axisRanges.clear();
    metrics.forEach(metric => {
      // @ts-ignore
      const range0 = chartHand.current.axis.axisRanges.create();
      // @ts-ignore
      range0.value = metric.startValue;
      // @ts-ignore
      range0.endValue = metric.endValue;
      range0.axisFill.fillOpacity = 1;
      range0.axisFill.fill = am4core.color(metric.color);
      range0.axisFill.zIndex = -1;
    });
  }, [metrics]);

  const setLabel = useCallback(() => {
    if (!chartRef.current) {
      return;
    }
    const label = chartRef.current.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = 45;
    label.x = am4core.percent(50);
    label.y = am4core.percent(100);
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'bottom';
    label.text = '50%';

    // @ts-ignore
    chartLabel.current = label;
  }, []);

  const updateData = useCallback(() => {
    if (!chartLabel.current || !chartHand.current || !value) {
      return;
    }
    const label = chartLabel.current;
    const hand = chartHand.current;
    label.text = `${value}`;
    new am4core.Animation(
      hand,
      {
        property: 'value',
        to: Number(value),
      },
      1000,
      am4core.ease.cubicOut,
    ).start();
  }, [value]);

  useEffect(() => {
    initializeChart();
    setGaugeHands();
    setLabel();
  }, [setGaugeHands, initializeChart, setLabel]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  useEffect(() => {
    updateAxis();
  }, [updateAxis]);

  useEffect(() => {
    updateMatrics();
  }, [updateMatrics]);

  return (
    <div style={{backgroundColor: 'white', width: '100%', height: '100%', ...style}}>
      <Spinner style={{position: 'absolute'}} isLoading={Boolean(isLoading)} />
      <div ref={chartElementRef} style={{width: '100%', height: '100%'}} />
    </div>
  );
}

export default React.memo(Gauge);
