import React from 'react';
import useElements from '../../../Elements';
import Tree from 'antd/lib/tree';
import {ElementSchema, IEditorComponent} from 'interfaces/Elements';
import {useDispatch, useSelector} from 'react-redux';

const {TreeNode} = Tree;

function Debugger() {
  const dispatch = useDispatch();
  const {getElements, getElementsWithInjectedChildren, setSelectedElement} = useElements();
  const elements = useSelector(getElements);
  const renderedElements = useSelector(getElementsWithInjectedChildren);

  const handleOnSelect = (element: ElementSchema | null) => {
    setSelectedElement(element);
  };

  const renderTree = (elements: IEditorComponent[]) => {
    return elements.map(element => {
      if (element.childElements) {
        return <TreeNode>{renderTree(element.childElements)}</TreeNode>;
      }
      return <TreeNode key={element.id} title={element.type} />;
    });
  };

  return (
    <Tree onSelect={ids => handleOnSelect(elements[ids[0]])}>{renderTree(renderedElements)}</Tree>
  );
}

export default Debugger;
