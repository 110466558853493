import React, {useCallback} from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Slider from 'antd/es/slider';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {Settings} from './interfaces';
import {SettingsElementProps} from 'interfaces/Settings';
import ColorPicker, {ColorType} from 'commons/ColorPicker';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};
const {Option} = Select;
const marks = {
  0: '0',
  20: '20',
  40: '40',
  60: '60',
  80: '80',
  100: '100',
};
interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
}

function CardSettings(props: Props) {
  const {
    form: {getFieldDecorator, setFieldsValue},
    element: {settings},
  } = props;

  const handleComponentColorChange = useCallback(
    (color: ColorType) => {
      setFieldsValue({
        ['settings.textColor']: color,
      });
    },
    [setFieldsValue],
  );
  getFieldDecorator(`settings.textColor`, {initialValue: settings && settings.textColor});

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
      }}
    >
      <FormItem label="Icon URL" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.iconUrl', {
          initialValue: settings && settings.iconUrl,
        })(<Input />)}
      </FormItem>
      <FormItem label="Icon Position" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.iconPosition', {
          initialValue: (settings && settings.iconPosition) || 'right',
        })(
          <Select className="full-width">
            <Option value="left">Left</Option>
            <Option value="center">Center</Option>
            <Option value="right">Right</Option>
          </Select>,
        )}
      </FormItem>
      <FormItem label="Icon Width" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.iconWidth', {
          initialValue: (settings && settings.iconWidth) || 12,
        })(<Slider min={1} max={12} step={1} />)}
      </FormItem>
      <FormItem label="Icon Size" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.iconSize', {
          initialValue: (settings && settings.iconSize) || 100,
        })(<Slider marks={marks} step={10} />)}
      </FormItem>
      <FormItem label="Label Text" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.labelText', {
          initialValue: settings && settings.labelText,
        })(<Input />)}
      </FormItem>
      <FormItem label="Label Size" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.labelSize', {
          initialValue: (settings && settings.labelSize) || 40,
        })(<Slider min={0} max={100} />)}
      </FormItem>

      <FormItem label="Text Position" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.textPosition', {
          initialValue: (settings && settings.textPosition) || 'center',
        })(
          <Select className="full-width">
            <Option value="left">Left</Option>
            <Option value="center">Center</Option>
            <Option value="right">Right</Option>
          </Select>,
        )}
      </FormItem>
      <FormItem label="Text Size" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('settings.textSize', {
          initialValue: (settings && settings.textSize) || 50,
        })(<Slider min={0} max={100} />)}
      </FormItem>
      <FormItem label="Text Color" style={{width: '300px'}} {...formItemLayout}>
        <ColorPicker
          initialValue={settings && settings.textColor}
          handleColorChange={(color: ColorType) => {
            handleComponentColorChange(color);
          }}
        />
      </FormItem>
    </div>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(CardSettings),
  },
];
