import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import interact from 'interactjs';
import {
  getEditorSettings,
  setIsDragging,
  setIsResizing,
  setSelectedElementPosition,
} from '../Editor';
import {ElementSchema} from 'interfaces/Elements';
import {useDispatch, useSelector} from 'react-redux';
import useElements from '../../Elements';
import {setDebuggerTab, setDebuggerVisibility} from '../Debugger';
import {AvailableTabs} from '../Debugger/interfaces';
import {getCurrentState} from '../../../store';
import isEqual from 'lodash.isequal';

const AXIS_RANGE = 10;
const SNAPPING_RELATIVE_POINTS = [{x: 0, y: 0}];

type Props = {
  element: ElementSchema;
  selectedElement: ElementSchema | null;
  children: React.ReactNode;
  width: number | null;
  isLive?: boolean;
};

function RndComponent(props: Props) {
  const {element, selectedElement, isLive} = props;
  const elementNode = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const {updateElement, setSelectedElement} = useElements();
  const editorSettings = useSelector(getEditorSettings);
  // const editorBounding = width ? {width} : getEditorBounding();

  const style = useMemo(
    () =>
      selectedElement && selectedElement.id === element.id
        ? '1px dashed blue'
        : editorSettings.showElementBorders
        ? '1px dashed rgb(193, 193, 193)'
        : '1px solid rgba(255, 255, 255, 0)',
    [editorSettings.showElementBorders, element.id, selectedElement],
  );

  const siblingElements = useMemo(() => {
    const {elements} = getCurrentState();
    return selectedElement
      ? Object.values(elements).filter(
          ({id, parentId}) => parentId === selectedElement.parentId && id !== selectedElement.id,
        )
      : [];
  }, [selectedElement]);

  const getSnapCoords = useCallback(
    (sibElement: ElementSchema) => {
      const result: {x?: number; y?: number; range?: Number} = {};
      if (selectedElement && elementNode.current && typeof sibElement.width === 'number') {
        const siblingHeight = sibElement.height;
        const elementPos = {
          left: parseFloat(elementNode.current.getAttribute('data-x') || '0') || 0,
          top: parseFloat(elementNode.current.getAttribute('data-y') || '0') || 0,
        };
        const LL_Dist = Math.abs(elementPos.left - sibElement.left);
        const LR_Dist = Math.abs(elementPos.left - (sibElement.left + sibElement.width));
        const RL_Dist = Math.abs(
          elementPos.left + elementNode.current.offsetWidth - sibElement.left,
        );
        const RR_Dist = Math.abs(
          elementPos.left + elementNode.current.offsetWidth - (sibElement.left + sibElement.width),
        );
        const minL_Dist = Math.min(LL_Dist, LR_Dist);
        const minR_Dist = Math.min(RL_Dist, RR_Dist);

        //snap right or left
        if (minR_Dist < minL_Dist && minR_Dist <= AXIS_RANGE) {
          result.x = elementPos.left + minR_Dist;
        } else if (minL_Dist <= AXIS_RANGE) {
          result.x = elementPos.left - minL_Dist;
        }

        const TT_Dist = Math.abs(elementPos.top - sibElement.top);
        const TB_Dist = Math.abs(elementPos.top - (sibElement.top + siblingHeight));
        const BT_Dist = Math.abs(
          elementPos.top + elementNode.current.offsetHeight - sibElement.top,
        );
        const BB_Dist = Math.abs(
          elementPos.top + elementNode.current.offsetHeight - (sibElement.top + siblingHeight),
        );
        const minT_Dist = Math.min(TT_Dist, TB_Dist);
        const minBDist = Math.min(BT_Dist, BB_Dist);
        //snap top or bottom
        if (minT_Dist < minBDist && minT_Dist <= AXIS_RANGE) {
          result.y = elementPos.top - minT_Dist;
        } else if (minBDist <= AXIS_RANGE) {
          result.y = elementPos.top + minBDist;
        }
      }
      if (!result.y && !result.x) {
        result.range = 0;
        result.x = -100;
        result.y = -100;
      }
      return result;
    },
    [selectedElement],
  );

  const getSnapTargets = useCallback(() => {
    const snapPoints: any[] = [];
    siblingElements.forEach(sibElement => {
      snapPoints.push(() => {
        return getSnapCoords(sibElement);
      });
    });
    return snapPoints;
  }, [getSnapCoords, siblingElements]);

  const handleStartDrag = useCallback(() => {
    dispatch(setIsDragging(true));
  }, [dispatch]);

  const startResizingHandler = useCallback(() => {
    dispatch(setIsResizing({isResize: true, elementId: element.id}));
    setSelectedElement(element);
  }, [dispatch, element, setSelectedElement]);

  const dragMoveListener = useCallback(
    (event: any) => {
      const {target} = event;
      // keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
      const transform = `translate(${x}px, ${y}px)`;
      // const {width, height} = event.rect;
      // translate the element
      target.style.webkitTransform = transform;
      target.style.transform = transform;

      // update the position attributes
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);

      dispatch(
        setSelectedElementPosition({
          left: x,
          top: y,
          right: x + Number(element.width),
          bottom: y + Number(element.height),
          // width: width,
          // height: height,
        }),
      );
    },
    [dispatch, element.height, element.width],
  );

  const handleStopDrag = useCallback(
    event => {
      const {target} = event;
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
      dispatch(setIsDragging(false));
      updateElement(
        {
          ...element,
          top: y,
          left: x,
          // widthPerc: (event.rect.width * 100) / editorBounding.width,
          // leftPerc: (x * 100) / editorBounding.width,
        },
        true,
      );
    },
    [dispatch, element, updateElement],
  );

  const handleResizeElement = useCallback(
    event => {
      const {target} = event;
      let x = parseFloat(target.getAttribute('data-x')) || 0;
      let y = parseFloat(target.getAttribute('data-y')) || 0;
      const {width, height} = event.rect;
      // update the element's style
      target.style.width = `${width}px`;
      target.style.height = `${height}px`;

      // translate when resizing from top or left edges
      x += event.deltaRect.left;
      y += event.deltaRect.top;

      const transform = `translate(${x}px,${y}px)`;
      target.style.webkitTransform = transform;
      target.style.transform = transform;

      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);

      dispatch(
        setSelectedElementPosition({
          right: x + event.rect.width,
          bottom: y + event.rect.height,
          top: y,
          left: x,
          // width,
          // height,
        }),
      );
    },
    [dispatch],
  );

  const handleStopResize = useCallback(
    event => {
      // delta
      dispatch(setIsResizing({isResize: false, elementId: null}));
      const left = element.left <= 0 ? 0 : element.left;
      updateElement(
        {
          ...element,
          left,
          width: event.rect.width,
          height: event.rect.height,
          // widthPerc: (event.rect.width * 100) / editorBounding.width,
          // leftPerc: (left * 100) / editorBounding.width,
        },
        true,
      );
    },
    [dispatch, element, updateElement],
  );

  const handleComponentClick = useCallback(
    e => {
      e.stopPropagation();
      /**
       * Prevent unnecessary updates when clicking the same element
       */
      if (!selectedElement || selectedElement.id !== element.id) {
        setSelectedElement(element);
      }
    },
    [element, selectedElement, setSelectedElement],
  );

  const modifiers = useMemo(() => {
    const modifiers = [];
    if (interact.modifiers) {
      modifiers.push(
        // keep the element within the area of it's parent
        interact.modifiers.restrictRect({
          restriction: 'parent',
        }),
      );

      if (editorSettings.enableSnap) {
        modifiers.push(
          interact.modifiers.snap({
            targets: getSnapTargets(),
            endOnly: true,
            offset: 'parent',
            range: Infinity,
            relativePoints: SNAPPING_RELATIVE_POINTS,
          }),
        );
      }

      // // @ts-ignore
      // const gridTarget = interact.createSnapGrid({
      //   x: 30,
      //   y: 30,
      //   range: 5,
      // });
      //
      // // @ts-ignore
      // modifiers.push(interact.modifiers.snap({targets: [gridTarget],
      //     offset: 'parent',
      //     endOnly: true,
      //     relativePoints: [{x: 0, y: 0}, {x: 0.5, y: 0.5}, {x: 1, y: 1}],
      //   }),
      // );
    }
    return modifiers;
  }, [editorSettings.enableSnap, getSnapTargets]);

  const resizeModifiers = useMemo(() => {
    const modifiers = [];
    if (interact.modifiers) {
      modifiers.push(
        interact.modifiers.restrictEdges({
          outer: 'parent',
        }),
      );
    }
    return modifiers;
  }, []);

  const handleDoubleClick = useCallback(() => {
    dispatch(setDebuggerVisibility(true));
    dispatch(setDebuggerTab(AvailableTabs.SETTINGS));
  }, [dispatch]);

  useEffect(() => {
    if (elementNode.current !== null) {
      const instance = interact(elementNode.current || '')
        .draggable({
          modifiers,
          // enable autoScroll
          //enabled: !isInteractive && element.canInteract,
          enabled: !isLive,
          autoScroll: true,
          onstart: handleStartDrag,
          onmove: dragMoveListener,
          onend: handleStopDrag,
        })
        .resizable({
          // resize from all edges and corners
          enabled: !isLive,
          edges: {left: true, right: true, bottom: true, top: true},
          onstart: startResizingHandler,
          onmove: handleResizeElement,
          onend: handleStopResize,
          modifiers: resizeModifiers,
        });

      return () => instance.unset();
    }
  }, [
    dragMoveListener,
    element,
    handleResizeElement,
    handleStartDrag,
    handleStopDrag,
    handleStopResize,
    isLive,
    modifiers,
    resizeModifiers,
    setSelectedElement,
    startResizingHandler,
  ]);

  return (
    <div
      onDoubleClick={() => handleDoubleClick()}
      data-x={element.left}
      data-y={element.top}
      onMouseDown={handleComponentClick}
      style={{
        border: isLive ? '' : style,
        width: Number(element.width),
        height: Number(element.height),
        transform: `translate(${element.left}px, ${element.top}px)`,
        position: 'absolute',
        boxSizing: 'border-box',
        maxWidth: '100%',
        maxHeight: '100%',
      }}
      ref={elementNode}
    >
      {props.children}
    </div>
  );
}
// RndComponent.whyDidYouRender = Boolean(process.env.REACT_APP_ENABLE_DEBUG);
function isSelectedComponent(prevProps: Props, nextProps: Props) {
  if (
    (prevProps.selectedElement &&
      prevProps.selectedElement.id === prevProps.element.id &&
      (nextProps.selectedElement && nextProps.selectedElement.id !== nextProps.element.id)) ||
    (prevProps.selectedElement &&
      prevProps.selectedElement.id === prevProps.element.id &&
      !nextProps.selectedElement) ||
    prevProps.width !== nextProps.width ||
    !isEqual(nextProps.element.childElements, prevProps.element.childElements) ||
    nextProps.isLive
  ) {
    return false;
  }
  return (
    nextProps.element.id !== (nextProps.selectedElement && nextProps.selectedElement.id) &&
    isEqual(prevProps.element, nextProps.element)
  );
}

export default React.memo(RndComponent, isSelectedComponent);
