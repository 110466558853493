import React, {useEffect, useCallback, useRef} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import Spinner from 'commons/Spinner';
import useChart from '../useChartData';
import {ElementSchema} from 'interfaces/Elements';
import {Settings} from '../interfaces';
import {SerialData} from '../../../../interfaces/Data';

type Props = {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
};

function PieChart(props: Props) {
  const {chartData, isLoading, lastData} = useChart(props.element, false);
  const {
    element: {settings},
    style,
  } = props;
  const chartElementRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<am4charts.PieChart>(null);
  const isLegendEnabled = settings && settings.legendEnabled;
  const isExportEnabled = settings && settings.enableExport;
  const legendPosition = settings && settings.legendPosition;
  am4core.useTheme(am4themes_animated);

  const handleFetchDataResponse = useCallback((data: SerialData[]): {[key: string]: SerialData} => {
    if (!data) {
      return {};
    }
    // return data.reduce((result: {[key: string]: SerialData}, record) => {
    //   if (!result[record.key]) {
    //     result[record.key] = record;
    //   }
    //   return result;
    // }, {});
    return {
      key1: {
        key: 'key1',
        value: 12,
        time: 231321,
      },
      key2: {
        key: 'key2',
        value: 124,
        time: 2313212,
      },
    };
  }, []);

  const setChartData = (data: any) => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    chart.data = data;
  };

  const initializeChart = useCallback(() => {
    if (!chartElementRef.current) {
      return;
    }
    // @ts-ignore
    chartRef.current = am4core.create(chartElementRef.current, am4charts.PieChart);
  }, []);

  const createChartSeries = useCallback((): void => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }
    const series = chart.series.push(new am4charts.PieSeries());
    series.dataFields = {
      value: 'value',
      category: 'key',
    };

    chart.innerRadius = am4core.percent(30);
    series.slices.template.stroke = am4core.color('#fff');
    series.slices.template.strokeWidth = 2;
    series.slices.template.strokeOpacity = 1;
    series.slices.template.cursorOverStyle = [
      {
        property: 'cursor',
        value: 'pointer',
      },
    ];

    series.alignLabels = false;
    series.labels.template.bent = true;
    series.labels.template.radius = 3;
    series.labels.template.padding(0, 0, 0, 0);

    series.ticks.template.disabled = true;

    const shadow = series.slices.template.filters.push(new am4core.DropShadowFilter());
    shadow.opacity = 0;

    const hoverState = series.slices.template.states.getKey('hover');
    if (hoverState) {
      const hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 5;
    }
    chart.exportable = true;
    // @ts-ignore
    chartRef.current = chart;
  }, []);

  const updateChartSettings = useCallback(() => {
    if (!chartRef.current) {
      return;
    }
    if (chartRef.current.legend) {
      chartRef.current.legend.disposeData();
      chartRef.current.legend.dispose();
    }

    if (isLegendEnabled) {
      chartRef.current.legend = new am4charts.Legend();
      chartRef.current.legend.position = legendPosition || 'right';
    }
    if (isExportEnabled) {
      chartRef.current.exporting.menu = new am4core.ExportMenu();
      chartRef.current.exporting.menu.align = 'left';
      chartRef.current.exporting.menu.verticalAlign = 'top';
    } else if (chartRef.current.exporting.menu) {
      chartRef.current.exporting.menu.dispose();
    }
  }, [isExportEnabled, isLegendEnabled, legendPosition]);

  const updateChartData = useCallback((data: SerialData) => {
    const chart = chartRef.current;
    if (!chart || !data) {
      return;
    }
    chart.addData(data, 1);
  }, []);

  useEffect(() => {
    initializeChart();
    createChartSeries();
  }, [createChartSeries, initializeChart]);

  useEffect(() => {
    setChartData(chartData);
  }, [chartData]);

  useEffect(() => {
    updateChartData(lastData);
  }, [lastData, updateChartData]);

  useEffect(() => {
    updateChartSettings();
  }, [updateChartSettings]);
  return (
    <>
      <Spinner style={{position: 'absolute'}} isLoading={Boolean(isLoading)} />
      <div ref={chartElementRef} style={{width: '100%', height: '100%', ...style}} />
    </>
  );
}

export default PieChart;
