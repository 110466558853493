import React from 'react';
import Icon from 'antd/es/icon';
import List from 'antd/es/list';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Tag from 'antd/es/tag';
import Modal from 'antd/es/modal';
import {hotKeysMap} from '../Editor/hooks/HotKeys';

const {Item} = List;

type Props = {
  visible: boolean;
  onClose: Function;
};

function HotKeysModal(props: Props) {
  const {visible, onClose} = props;

  const modalTitle = (
    <span>
      <Icon type="menu" />
      {`   Hot Keys`}
    </span>
  );

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={() => onClose()}
      footer={null}
      width={500}
    >
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={hotKeysMap}
        renderItem={item => (
          <Item>
            <Row style={{width: '100%'}}>
              <Col span={12}>{item.name}</Col>
              <Col span={12}>
                {Array.isArray(item.keys) ? (
                  item.keys.map(key => (
                    <span key={key}>
                      <Tag color="volcano">{key}</Tag>
                    </span>
                  ))
                ) : (
                  <span key={item.keys}>
                    <Tag color="volcano">{item.keys}</Tag>
                  </span>
                )}
              </Col>
            </Row>
          </Item>
        )}
      />
    </Modal>
  );
}

export default HotKeysModal;
