import React, {useCallback, useContext} from 'react';
import uuid from 'uuid';
import immer from 'immer';
import {useDispatch} from 'react-redux';
import {createSelector} from 'reselect';
import debounce from 'lodash.debounce';
import elementsApi from './api';
import {Elements, ElementSchema, ElementsOperations, RequestDataOptions} from 'interfaces/Elements';
import {SerialData} from '../../interfaces/Data';
import {addLog} from '../EditMode/Debugger';
import {setChangesStatus, setLoaderVisiblility, setSavingLoaderStatus} from '../EditMode/Editor';
import {LogTypes} from '../EditMode/Debugger/store/interfaces';
import {OperationTypes} from '../EditMode/Editor/store/interfaces';
import {Actions} from './ElementsStore/interfaces';
import {getCurrentState, getElementsStore} from 'store';
import useData from 'modules/Data';
import {AppContext} from 'App';

const EMPTY_DATA_RESPONSE = {
  data: [],
  pageSize: 0,
  pageNumber: 0,
  totalCount: 0,
};

export const transformDataFunc = (
  data: SerialData[],
  options: RequestDataOptions = {},
): SerialData[] => {
  return data.map(dataPoint => {
    const {key, value, ...rest} = dataPoint;
    return {
      [key]: options.transformToNumber ? Number(value) : value,
      ...rest,
    };
  });
};

function useElements(): ElementsOperations {
  const dispatch = useDispatch();
  const {addElementData} = useData();
  const {params} = useContext(AppContext);
  const {visualizationId, publicId, organizationId, applicationId} = params;
  /**
   * Elements Selectors
   */

  const getElements = createSelector(
    [getElementsStore],
    elementsStore => elementsStore.elements,
  );

  const injectChildren = (elements: {[id: string]: ElementSchema}) => {
    const injectedElements = immer(elements, draft => {
      Object.values(draft).forEach(element => {
        if (element.parentId) {
          const parentElement = draft[element.parentId];
          if (parentElement) {
            parentElement.childElements = (parentElement.childElements || []).filter(
              ele => ele.id !== element.id,
            );
            parentElement.childElements = [...parentElement.childElements, element];
          }
          delete draft[element.id];
        }
      });
    });

    return Object.values(injectedElements);
  };

  const getElementsWithInjectedChildren = createSelector(
    [getElementsStore],
    elementsStore => injectChildren(elementsStore.elements),
  );

  const getSelectedElement = createSelector(
    [getElementsStore],
    elementsStore => elementsStore.selectedElement,
  );

  /**
   * Elements Actions
   */
  const setSelectedElement = useCallback(
    (element: ElementSchema | null) => {
      const {selectedElement} = getCurrentState();
      if (!selectedElement || !element || element.id !== selectedElement.id) {
        dispatch({
          type: Actions.SET_SELECTED_ELEMENT,
          payload: element,
        });
      }
    },
    [dispatch],
  );

  const setElements = useCallback(
    (elements: Elements) => {
      dispatch({
        type: Actions.SET_ELEMENTS,
        payload: elements,
      });
    },
    [dispatch],
  );

  /**
   * Elements APIs Func
   */

  const fetchElements = useCallback(() => {
    const {currentPage} = getCurrentState();
    const currentPageId = currentPage ? currentPage.id : null;
    if (!currentPageId) {
      return;
    }

    dispatch(setLoaderVisiblility(true));
    dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.FETCHING_ELEMENTS}));
    const api = publicId ? elementsApi.getAllPublicElements : elementsApi.getAll;
    api({pageId: currentPageId, visualizationId, publicId})
      .then(elements => {
        const elementsObj = elements.reduce<{[id: string]: ElementSchema}>((result, element) => {
          Object.keys(element).forEach((key: string) => {
            // @ts-ignore
            if (element[key] === null) {
              // @ts-ignore
              delete element[key];
            }
          });
          result[element.id] = element;
          return result;
        }, {});

        setElements(elementsObj);
        dispatch(setLoaderVisiblility(false));
      })
      .catch(e => {
        dispatch(setLoaderVisiblility(false));
        dispatch(addLog({type: LogTypes.ERROR, message: 'Error while Fetching elements'}));
      });
  }, [dispatch, publicId, setElements, visualizationId]);

  const updateElement = useCallback(
    (element: ElementSchema, syncWithBackend: boolean) => {
      const {currentPage} = getCurrentState();
      delete element.childElements;
      const pageId = currentPage ? currentPage.id : null;
      if (!pageId) {
        return;
      }
      if (syncWithBackend) {
        dispatch(
          addLog({
            type: LogTypes.INFO,
            message: OperationTypes.UPDATE_ELEMENT,
            source: {type: 'ELEMENT', id: element.id},
          }),
        );
        elementsApi
          .updateComponent(element, {pageId, componentId: element.id, ...params})
          .then(() => {
            dispatch({
              type: Actions.UPDATE_ELEMENT,
              payload: element,
            });
          })
          .catch(e => {
            dispatch(
              addLog({
                type: LogTypes.ERROR,
                message: e.message,
                source: {
                  type: 'ELEMENT',
                  id: element.id,
                },
              }),
            );
          });
      } else {
        console.log({element})
        dispatch(setChangesStatus(true));
        dispatch({
          type: Actions.UPDATE_ELEMENT,
          payload: element,
        });
      }
    },
    [dispatch, params],
  );

  const updateElements = useCallback(
    newElements => {
      const {currentPage} = getCurrentState();

      const pageId = currentPage ? currentPage.id : null;
      if (!pageId) {
        return;
      }
      dispatch(
        addLog({
          type: LogTypes.INFO,
          message: OperationTypes.UPDATE_ELEMENT,
          // source: {type: 'ELEMENT', id: element.id},
        }),
      );
      dispatch({
        type: Actions.UPDATE_ELEMENTS,
        payload: newElements,
      });
      const {elements} = getCurrentState();
      elementsApi
        .savePageComponents(Object.values(elements), {
          pageId,
          visualizationId: params.visualizationId,
        })
        .catch(e => {
          dispatch(
            addLog({
              type: LogTypes.ERROR,
              message: e.message,
              // source: {
              //   type: 'ELEMENT',
              //   id: element.id,
              // },
            }),
          );
        });
    },
    [dispatch, params],
  );

  const updateElementStyle = useCallback(
    (id, styles) => {
      const {elements} = getCurrentState();
      const element = {...elements[id], styles: {...elements[id].styles, ...styles}};
      updateElement(element, false);
    },
    [updateElement],
  );

  const updateElementDataSource = useCallback(
    debounce<ElementsOperations['updateElementDataSource']>((elementId, dataSource) => {
      const {elements} = getCurrentState();
      const element = {...elements[elementId], id: elementId, ...dataSource};
      updateElement(element, true);
    }, 500),
    [],
  );

  const getElementData = useCallback(
    async ({id, dataSource}, options, transformData = true) => {
      const {currentPage} = getCurrentState();
      const pageId = currentPage ? currentPage.id : null;
      if (!dataSource || !pageId) {
        return EMPTY_DATA_RESPONSE;
      }

      const prms = {...options};
      if (options.lastValue) {
        prms.aggregation = 'last';
      }

      try {
        let result;
        if (params.publicId) {
          result = await elementsApi.getPublicComponentData({publicId, componentId: id}, options);
        } else {
          result = await elementsApi.getComponentData(
            {pageId, componentId: id, visualizationId, organizationId, applicationId},
            prms,
          );
        }
        if (!result) {
          return EMPTY_DATA_RESPONSE;
        }
        let transFormedData = result.data;
        if (transformData) {
          transFormedData = transformDataFunc(result.data, options);
        }
        addElementData({id, data: transFormedData});
        return {
          ...result,
          data: transFormedData,
        };
      } catch (e) {
        console.log('=== Error...', e.message);
        // Log error
        throw e;
      }
    },
    [params.publicId, addElementData, publicId, visualizationId, organizationId, applicationId],
  );

  const saveElements = debounce(
    useCallback(() => {
      const {currentPage, elements} = getCurrentState();
      if (!currentPage) {
        return;
      }
      dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.SAVING_ELEMENTS}));
      dispatch(setSavingLoaderStatus(true));
      elementsApi
        .savePageComponents(Object.values(elements), {pageId: currentPage.id, ...params})
        .then(() => {
          dispatch(setChangesStatus(false));
          dispatch(setSavingLoaderStatus(false));
        })
        .catch(() => {
          dispatch(addLog({type: LogTypes.ERROR, message: 'Error while Saving elements'}));
          dispatch(setSavingLoaderStatus(false));
        });
    }, [dispatch, params]),
    500,
  );

  const debounceSaveElements = debounce(
    useCallback(() => {
      saveElements();
    }, [saveElements]),
    10000,
  );

  const addElement = useCallback(
    element => {
      const {currentPage} = getCurrentState();
      if (!currentPage) {
        return;
      }
      const id = uuid.v4();
      const elementClone: ElementSchema = {
        ...element,
        id,
        // widthPerc: 0,
        pageId: currentPage.id,
      };
      dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.CREATE_ELEMENT}));
      dispatch({
        type: Actions.ADD_ELEMENT,
        payload: elementClone,
      });
      saveElements();
      return elementClone;
    },
    [dispatch, saveElements],
  );

  const deleteElement = useCallback(
    (elementId: string) => {
      const {elements} = getCurrentState();
      const newElements = immer(elements, draftState => {
        Object.values(draftState).forEach(element => {
          // Delete all child elements
          if (element.parentId && element.parentId === elementId) {
            delete draftState[element.id];
          }
        });
        delete draftState[elementId];
      });
      dispatch(setChangesStatus(true));
      dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.DELETE_ELEMENT}));
      setElements(newElements);
    },
    [dispatch, setElements],
  );

  const clearElements = useCallback(() => {
    const {currentPage} = getCurrentState();
    if (!currentPage) {
      return;
    }
    dispatch(addLog({type: LogTypes.INFO, message: OperationTypes.CLEAR_ELEMENTS}));
    setElements({});
    saveElements();
  }, [dispatch, saveElements, setElements]);

  const updateElementSettings = useCallback(
    (id, settings) => {
      const {elements} = getCurrentState();
      const height = settings.height || elements[id].height;
      const width = settings.width || elements[id].width;
      const widthPerc = (width * 100) / (window.innerWidth - 60);
      const element = {
        ...elements[id],
        height,
        width,
        widthPerc,
        settings: {...elements[id].settings, ...settings},
      };
      updateElement(element, false);
    },
    [updateElement],
  );

  return {
    setSelectedElement,
    getSelectedElement,
    getElementsWithInjectedChildren,
    getElements,
    fetchElements,
    setElements,
    updateElement,
    updateElements,
    updateElementStyle,
    updateElementSettings,
    addElement,
    deleteElement,
    updateElementDataSource,
    getElementData,
    clearElements,
    saveElements,
    debounceSaveElements,
  };
}

// useElements.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;
export default useElements;
