import styled from 'styled-components/macro';
import colors from '../../../../utils/colors';

interface CanvasProps {
  EDITOR_HEIGHT: number;
  showGrid: boolean;
  styles?: string;
  colors: {
    gridBackgroundColor: string;
  };
}

// height: ${props => props.EDITOR_HEIGHT}px;
export const Canvas = styled.div<CanvasProps>`
  border-right: 1px solid ${colors.border};
  border-left: 1px solid ${colors.border};
  border-bottom: 1px solid ${colors.border};
  background-color: ${props => props.colors.gridBackgroundColor};
  min-height: 100vh; // TODO: Get the top panel height value from props
  background-size: 28px 28px;
  background-image: ${props =>
    props.showGrid &&
    `linear-gradient(to right, ${colors.border} 1px, transparent 1px),
linear-gradient(to bottom, ${colors.border} 1px, transparent 1px)`};
  ${props => props.styles};
`;

// export const StyledEditor = styled.div`
//   //padding: 1px 50px;
//   //width: 94vw;
//   //background-color: whitesmoke;
//   //margin: auto;
//   //background-size: 40px 40px;
//   //background-image: linear-gradient(to right, grey 1px, transparent 1px),
//   //  linear-gradient(to bottom, grey 1px, transparent 1px);
// `;
