import React, {useRef} from 'react';
import {useDrop, XYCoord} from 'react-dnd';
import {DroppableElement} from 'interfaces/Editor';
import components from '../../index';
import useElements from 'modules/Elements';
import {ElementSchema} from 'interfaces/Elements';
import Rnd from 'modules/EditMode/EditorComponent/Rnd';
import EditorComponent from 'modules/EditMode/EditorComponent';
import {getCurrentState} from '../../../../store';

type Props = {
  element: ElementSchema<{[key: string]: any}>;
  handleDropElement: Function;
  width: number | null;
  canDrop?: boolean;
  isLive?: boolean;
};

function Container(props: Props) {
  const {element, handleDropElement, isLive} = props;
  const {updateElement} = useElements();
  const containerRef = useRef<HTMLDivElement>(null);

  function onDrop(newElement: DroppableElement, position: XYCoord | null): void {
    if (position) {
      const elementWithPosition: DroppableElement = {
        ...newElement,
        ...position,
        parentId: element.id,
        width: 100,
        height: 100,
      };
      const childElement = handleDropElement(elementWithPosition);
      const {elements} = getCurrentState();
      updateElement(
        {...elements[element.id], childrenIds: [...(element.childrenIds || []), childElement.id]},
        false,
      );
    }
  }

  const [collectedProps, drop] = useDrop({
    accept: Object.keys(components),
    canDrop: () => {
      return true;
    },
    drop(item: DroppableElement, monitor) {
      const position = monitor.getClientOffset();
      if (containerRef.current && position) {
        const boundingClientRect = containerRef.current.getBoundingClientRect();
        onDrop(item, {x: position.x - boundingClientRect.x, y: position.y - boundingClientRect.y});
      }
      return {};
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggingColor: monitor.getItemType(),
    }),
  });

  const styles = {
    width: '100%',
    height: '100%',
    backgroundColor: collectedProps && collectedProps.isOver ? 'yellow' : '',
  };

  return (
    <div ref={containerRef} style={{width: '100%', height: '100%'}}>
      <div ref={isLive ? null : drop} style={styles}>
        {element.childElements &&
          element.childElements.map(element => (
            <Rnd
              isLive={isLive}
              selectedElement={null}
              width={null}
              element={element}
              key={element.id}
            >
              <EditorComponent {...props} element={element} index={element.index} />
            </Rnd>
          ))}
      </div>
    </div>
  );
}

export default Container;
