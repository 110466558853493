import React, {useCallback, useRef} from 'react';
import Menu from 'antd/lib/menu';
import {ElementCommandsEnum} from 'interfaces/Elements';
import {setDebuggerVisibility} from '../Debugger';
import useCommands from '../Commands/useCommands';
import {StyledMenu} from './styledComponents';
import {useDispatch, useSelector} from 'react-redux';
import {elementInCopySelector} from 'modules/EditMode/Editor';

type Props = {
  setIsVisible: Function;
  // getEditorBounding(): {x: number; y: number; width: number; height: number};
};

interface ClickEvent extends Event {
  clientX?: number;
  clientY?: number;
}

const PageMenu = (props: Props) => {
  const {setIsVisible} = props;
  const dispatch = useDispatch();
  const {commands} = useCommands();
  const elementInCopy = useSelector(elementInCopySelector);
  const ref = useRef<HTMLDivElement>(null);

  const handleContextMenuClick = (key: string): void => {
    setIsVisible(false);
    switch (key) {
      case ElementCommandsEnum.debugger:
        dispatch(setDebuggerVisibility(true));
        break;
      case ElementCommandsEnum.paste:
        // @ts-ignore
        const {x, y} = ref.current ? ref.current.getBoundingClientRect() : {x: 0, y: 0};
        // const editorPosition = getEditorBounding();
        const position = {
          left: x,
          top: y,
        };
        commands.paste(position);
        break;
      default:
        alert(`Action ${key} is not supported!`);
    }
  };

  return (
    <div ref={ref}>
      <StyledMenu onClick={({key}) => handleContextMenuClick(key)}>
        <Menu.Item key={ElementCommandsEnum.debugger}>Inspector</Menu.Item>
        {elementInCopy && <Menu.Item key={ElementCommandsEnum.paste}>Paste Element</Menu.Item>}
      </StyledMenu>
    </div>
  );
};

export default PageMenu;
