import React, {useState} from 'react';
import {useSelector} from 'react-redux';
// import {SizeMe} from 'react-sizeme';
import {Canvas} from '../styles';
import usePages from '../../../Pages';
import {getEditorSettings} from '../index';
// import OverlapLines from './OverlapLines';
// import useEditorPosition from './useEditorPosition';

type Props = {
  handleLayoutClick(): void;
  children: React.ReactNode;
};

const EDITOR_HEIGHT: number = 1300;
const baseColors = {
  gridBackgroundColor: 'white',
};

function EditorViewPort(props: Props) {
  const [colors, setColors] = useState(baseColors);
  const editorSettings = useSelector(getEditorSettings);
  const {currentPageSelector} = usePages();
  const currentPage = useSelector(currentPageSelector);
  const {style} = currentPage || {};
  const {content = '', ...defaultStyle} = style || {};
  const styles = content.concat(
    Object.entries(defaultStyle || {})
      .map(([key, value]) => (value ? `${key}:${value}` : ''))
      .join(';'),
  );

  return (
    <div onMouseDown={() => props.handleLayoutClick()}>
      <Canvas
        colors={colors}
        EDITOR_HEIGHT={editorSettings.height || EDITOR_HEIGHT}
        showGrid={Boolean(editorSettings.showGrid)}
        styles={styles}
      >
        {props.children}
        {/*<OverlapLines />*/}
      </Canvas>
    </div>
  );
}

// EditorViewPort.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;
export default React.memo(EditorViewPort);
