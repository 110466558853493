import React, {useCallback, useRef, useState} from 'react';
import Dropdown from 'antd/es/dropdown';
import PageMenu from './PageMenu';
import ElementMenu from './ElementMenu';
import useElements from '../../Elements';
import {useSelector} from 'react-redux';

type Props = {
  children: React.ReactNode;
  // getEditorBounding(): {x: number; y: number; height: number; width: number};
};

function ContextMenu(props: Props) {
  const {children} = props;
  const [isVisible, setIsVisible] = useState(false);
  const {getSelectedElement} = useElements();
  const selectedElement = useSelector(getSelectedElement);
  const getContextMenu = useCallback(() => {
    if (!isVisible) {
      return <></>;
    }
    return selectedElement ? (
      <ElementMenu setIsVisible={setIsVisible} />
    ) : (
      <PageMenu setIsVisible={setIsVisible} />
    );
  }, [isVisible, selectedElement]);

  return (
    <Dropdown
      visible={isVisible}
      onVisibleChange={setIsVisible}
      overlay={getContextMenu()}
      trigger={['contextMenu']}
    >
      <div>{children}</div>
    </Dropdown>
  );
}

// ContextMenu.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;
export default ContextMenu;
