import React from 'react';
import ReactJson from 'react-json-view';
import {useSelector} from 'react-redux';
import useData from 'modules/Data';

interface Props {
  elementId: string;
  height: number;
}

function Data(props: Props) {
  const {height, elementId} = props;
  const {getElementData} = useData();
  const elementData = useSelector(getElementData(elementId));

  return (
    <div style={{height: `calc(${props.height}% - 80px)`}}>
      <ReactJson src={elementData} />
    </div>
  );
}

export default Data;
