import React, {useEffect, useMemo, useRef} from 'react';
import 'amcharts3/amcharts/serial';
import AmCharts from '@amcharts/amcharts3-react';
import 'amcharts3/amcharts/pie';
import 'amcharts3/amcharts/themes/light';
import 'amcharts3/amcharts/plugins/export/export.min.js';
import 'amcharts3/amcharts/plugins/export/export.css';
import {ElementSchema} from 'interfaces/Elements';
import useChart from '../useChartData';
import Spinner from 'commons/Spinner';
import {Settings} from '../interfaces';

interface Props {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
  type: 'smoothedLine' | 'column';
}

const initialDataKeys: [] = [];

function SerialChartV3(props: Props) {
  const chart = useRef(null);
  const {chartData, isLoading, lastData} = useChart(props.element, true);
  const {
    element: {settings = {}, dataSource},
    type,
    style,
  } = props;
  const dataKeys = dataSource ? dataSource.dataKeys : initialDataKeys;
  const {categoryField} = settings;

  const getYAxisGraphsByKeys = useMemo(() => {
    if (!dataKeys) {
      return [];
    }
    let filteredDataKeys = dataKeys;
    if (categoryField) {
      filteredDataKeys = dataKeys.filter(key => key !== categoryField);
    } else {
      filteredDataKeys = dataKeys;
    }
    return filteredDataKeys.map(key => ({
      id: key,
      balloon: {
        drop: true,
        adjustBorderColor: false,
        color: '#ffffff',
      },
      type,
      bullet: 'round',
      bulletBorderAlpha: 1,
      bulletColor: '#FFFFFF',
      bulletSize: 5,
      hideBulletsCount: 50,
      lineThickness: 2,
      title: key,
      useLineColorForBulletBorder: true,
      valueField: key,
      fillAlphas: type === 'column' ? 1 : 0,
    }));
  }, [categoryField, dataKeys, type]);

  useEffect(() => {
    if (lastData && chart && chart.current) {
      // @ts-ignore
      chart.current.dataProvider.push(lastData.value);
    }
  }, [lastData]);

  const parsedData = categoryField
    ? chartData
        .filter(record => categoryField in record)
        .map(record => {
          let date;
          if (Number.isNaN(Number(record[categoryField]))) {
            date = new Date(record[categoryField]).getTime();
          } else {
            date = Number(record[categoryField]);
          }
          return {
            ...record,
            [categoryField]: date,
          };
        })
    : chartData;

  return (
    <Spinner isLoading={isLoading}>
      <AmCharts.React
        hideCredits={true}
        style={{width: '100%', height: '100%', ...style}}
        options={{
          path: `${process.env.PUBLIC_URL}/amcharts/`,
          type: 'serial',
          theme: 'light',
          categoryField: categoryField || 'time',
          zoomOutOnDataUpdate: true,
          synchronizeGrid: true,
          precision: settings.precision || -1,
          categoryAxis: {
            title: settings.xAxisTitle || '',
            labelRotation: settings.xAxisLabelRotation || 0,
            equalSpacing: true,
            minPeriod: 'ss',
            parseDates: true,
            dashLength: 1,
            minorGridEnabled: true,
          },
          chartScrollbar: {
            enabled: Boolean(settings && settings.isScrollEnabled),
            dragIconHeight: 25,
            dragIconWidth: 25,
          },
          chartCursor: {
            enabled: settings && settings.isCursorEnabled,
            categoryBalloonDateFormat: 'D/M/YYYY J:N:S',
            categoryBalloonAlpha: 0.7,
          },
          graphs: getYAxisGraphsByKeys,
          legend: {
            enabled: settings && settings.legendEnabled,
          },
          export: {
            enabled: settings && settings.enableExport,
          },
          listeners: [
            {
              event: 'init',
              method: (e: any) => {
                chart.current = e.chart;
              },
            },
          ],
          dataProvider: parsedData,
        }}
      />
    </Spinner>
  );
}
export default React.memo(SerialChartV3);
