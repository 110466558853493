export enum operators {
  gt= 'gt',
  gte= 'gte',
  lt= 'lt',
  lte= 'lte',
  eq= 'eq',
  ne= 'ne',
  between= 'between',
  notBetween= 'notBetween',
  like= 'like',
  notLike='notLike',
  iLike= 'iLike',
  is= 'is',
  isNot= 'isNot',
  not= 'not',
  notILike= 'notILike',
  in= 'in',
  notIn= 'notIn',
}

export interface Filters {
  [key: string]: {
    operator :  operators,
    values: (string | string[] | Value) []
  }
}
interface Value {
  operator: operators,
  value?: string | string[] | {any?: string[]},
}

export interface Filter {
  key?: string,
  operator?: operators,
  value?:  string | string[] | {any?: string[]},
}

function instanceOfValue(object: any): object is Value {
  return 'operator' in object;
}

export const transformFilters = (filtersObj : Filters) : Filter[] => {
  return Object.entries(filtersObj).reduce(
    (filtersArr : Filter[],
      [key, {operator = operators.eq, values}]
    ) =>
      filtersArr.concat(
        values.map(value => {
          let filter : Filter = {key};

          if (instanceOfValue(value)) {
            filter.operator = value.operator;
            filter.value = [
              operators.like,
              operators.iLike,
              operators.notLike,
              operators.notILike,
            ].includes(value.operator)
              ? Array.isArray(value.value)
                ? {any: value.value.map(item => `%${item}%`)}
                : `%${value.value}%`
              : value.value;
          } else {
            filter.operator = operator;
            filter.value = [
              operators.like,
              operators.iLike,
              operators.notLike,
              operators.notILike,
            ].includes(operator)
              ? Array.isArray(value)
                ? {any: value.map(item => `%${item}%`)}
                : `%${value}%`
              : value;
          }
          return filter;
        }),
      ),
    [],
  );
};
