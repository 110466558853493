import {ComponentCharacteristics} from 'interfaces/Elements';
import Component from './Tabs';
import Settings from './Settings';
const TabsBluePrint: ComponentCharacteristics<{}> = {
  type: 'tab',
  name: 'Tabs',
  category: 'Common',
  Component,
  settings: Settings,
  isDroppable: true,
  width: 6,
  height: 6,
};
export default TabsBluePrint;
