import React from 'react';
import Menu from 'antd/lib/menu';
import {ElementCommandsEnum} from 'interfaces/Elements';
import useCommands from '../Commands/useCommands';
import {setDebuggerVisibility} from '../Debugger';
import {StyledMenu} from './styledComponents';
import {useDispatch} from 'react-redux';

type Props = {
  setIsVisible(value: boolean): void;
};

const ElementMenu = ({setIsVisible}: Props) => {
  const {commands} = useCommands();
  const dispatch = useDispatch();

  const handleContextMenuClick = (action: string): void => {
    setIsVisible(false);
    switch (action) {
      case ElementCommandsEnum.debugger:
        dispatch(setDebuggerVisibility(true));
        break;
      case ElementCommandsEnum.copy:
        commands.copy();
        break;
      case ElementCommandsEnum.cut:
        commands.cut();
        break;
      case ElementCommandsEnum.delete:
        commands.hide();
        break;
      case ElementCommandsEnum.bringForward:
        commands.forward();
        break;
      case ElementCommandsEnum.bringBack:
        commands.back();
        break;
      default:
        alert(`Action ${action} is not supported!`);
    }
  };

  return (
    <StyledMenu onClick={({key}) => handleContextMenuClick(key)}>
      <Menu.Item key={ElementCommandsEnum.debugger}>Inspect</Menu.Item>
      <Menu.Item key={ElementCommandsEnum.copy}>Copy Element</Menu.Item>
      <Menu.Item key={ElementCommandsEnum.cut}>Cut Element</Menu.Item>
      <Menu.Item key={ElementCommandsEnum.delete}>Delete Element</Menu.Item>
      <Menu.Item key={ElementCommandsEnum.bringForward}>Bring Forward</Menu.Item>
      <Menu.Item key={ElementCommandsEnum.bringBack}>Bring Back</Menu.Item>
    </StyledMenu>
  );
};

export default ElementMenu;
