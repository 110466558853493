import Component from './input';
import {ComponentCharacteristics} from 'interfaces/Elements';
import Settings from './Settings';
const InputBluePrint: ComponentCharacteristics<{}> = {
  type: 'input',
  name: 'input',
  category: 'Form',
  Component,
  settings: Settings,
  width: 6,
  height: 6,
};
export default InputBluePrint;
