import React, {ReactNode} from 'react';
import {useDrop, XYCoord} from 'react-dnd';
import {DroppableElement} from '../../../../interfaces/Editor';
import components from '../../../Components';

interface TargetBoxProps {
  handleDropElement(element: DroppableElement): void;
  children: ReactNode;
}

function TargetBox({children, handleDropElement}: TargetBoxProps) {
  function onDrop(element: DroppableElement, position: XYCoord | null): void {
    if (position) {
      const {initialSettings} = components[element.elementType];
      const elementWithPosition: DroppableElement = {
        ...element,
        type: '',
        ...position,
        settings: initialSettings,
        // width: '100',
        // height: '100',
      };
      handleDropElement(elementWithPosition);
    }
  }

  const [{draggingColor}, drop] = useDrop({
    accept: Object.keys(components),
    canDrop: () => {
      return true;
    },
    drop(item: DroppableElement, monitor) {
      const position = monitor.getClientOffset();
      onDrop(item, position);
      return {};
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggingColor: monitor.getItemType(),
    }),
  });

  // let backgroundColor = '#fff';
  // switch (draggingColor) {
  //   case 'blue':
  //     backgroundColor = 'lightblue';
  //     break;
  //   case 'yellow':
  //     backgroundColor = 'lightgoldenrodyellow';
  //     break;
  //   default:
  //     break;
  // }
  return <div ref={drop}>{children}</div>;
}
// @ts-ignore
// TargetBox.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;
export default React.memo(TargetBox);
