import React, {useEffect} from 'react';
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {SettingsElementProps} from 'interfaces/Settings';

const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'embedded',
    // 'image',
    'remove',
    'history',
  ],
  inline: {
    options: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'monospace',
      // 'superscript',
      // 'subscript',
    ],
  },
};

interface Settings {
  text: string;
}

function TextSettings(props: SettingsElementProps<Settings>) {
  const {
    element: {settings},
  } = props;
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const content = editorState.getCurrentContent();
    const trimmedContent = content.getPlainText().trim();

    if (content.hasText() && trimmedContent.length) {
      props.setSettings({
        settings: {
          text: draftToHtml(convertToRaw(content)),
        },
      });
    } else {
      props.setSettings({settings: {text: ''}});
    }
  };

  useEffect(() => {
    if (settings && settings.text) {
      const {contentBlocks, entityMap} = htmlToDraft(settings.text);
      const state = ContentState.createFromBlockArray(contentBlocks, entityMap);

      const editorState = EditorState.createWithContent(state);
      setEditorState(editorState);
    }
  }, [settings]);

  return (
    <Editor
      editorStyle={{backgroundColor: 'whitesmoke', minHeight: 100}}
      editorState={editorState}
      toolbar={toolbarOptions}
      onEditorStateChange={onEditorStateChange}
    />
  );
}

export default TextSettings;
