import Component from './AlarmsTable';
import Settings from '../TableSettings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from '../interfaces';
import {DataSourceFields, DataTypes} from 'interfaces/DataSources';
const AlarmTableBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'alarmtable',
  name: 'Alarms Table',
  category: 'Tables',
  Component,
  dataSourcesOptions: {
    disabledFields: [DataSourceFields.DataType],
    visibleFields: [
      DataSourceFields.DataType,
      DataSourceFields.DataSourceType,
      DataSourceFields.DataSourceValue,
    ],
    initialValue: {
      dataType: DataTypes.ALARMS,
    },
  },
  isTimeWindowEnabled: true,
  settings: Settings,
  height: 6,
  width: 6,
};
export default AlarmTableBluePrint;
