import {useEffect, useState, useCallback} from 'react';
import {ElementSchema} from 'interfaces/Elements';
import {MetricsData, SerialData} from 'interfaces/Data';
import useElemnets, { transformDataFunc } from "../../Elements";
import {LogTypes} from 'modules/EditMode/Debugger/store/interfaces';
import {useDispatch} from 'react-redux';
import {addLog} from '../../EditMode/Debugger';
import {getDataSourceEventName} from '../../../utils/dataSources';
import {listenOnEvent, removeEventListener} from '../../../adaptors/webSocket';

interface Response {
  chartData: SerialData[];
  lastData: SerialData;
  isLoading: boolean;
}

function useCharts(element: ElementSchema, transformData: boolean = true): Response {
  const dispatch = useDispatch();
  const {getElementData} = useElemnets();
  const {dataSource, id, aggregation, timeWindow} = element;
  const [chartData, setChartData] = useState<SerialData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastData, setLastData] = useState();

  const fetchData = useCallback(() => {
    if (!dataSource) {
      return;
    }
    setIsLoading(true);
    getElementData(
      {id, dataSource},
      {pageSize: 5000, sort: 'time desc'},
      transformData,
    )
      .then(({data}) => {
        setChartData(data.map(record => ({...record, time: new Date(record.time).getTime()})).reverse());
        setIsLoading(false);
        dispatch(
          addLog({
            type: LogTypes.INFO,
            message: `Fetching Data for component`,
            source: {
              type: 'ELEMENT',
              id,
            },
            // time: getCurrentTime(),
          }),
        );
      })
      .catch(e => {
        dispatch(
          addLog({
            type: LogTypes.ERROR,
            message: `Error while Fetching Data for component`,
            source: {
              type: 'ELEMENT',
              id,
            },
            // time: getCurrentTime(),
          }),
        );
        setIsLoading(false);
      });
  }, [dataSource, dispatch, getElementData, id, transformData]);

  const handleRealTimeData = useCallback(
    data => {
      if (dataSource) {
        const newValue = data.find(
          (record: MetricsData) => dataSource.dataKeys && dataSource.dataKeys.includes(record.key as string),
        );
        if (newValue) {
          setLastData({...newValue, time: new Date(newValue.time).getTime()});

          setChartData(currentData => ([
            ...currentData,
            ...transformDataFunc(data),
          ]));
        }
      }
    },
    [dataSource],
  );

  useEffect(() => {
    fetchData();
  }, [aggregation, fetchData]);

  useEffect(() => {
    if (dataSource && (timeWindow && timeWindow.type === 'REAL_TIME')) {
      const eventName = getDataSourceEventName(dataSource);
      if (eventName) {
        listenOnEvent(eventName, handleRealTimeData, id);
        return () => removeEventListener(id);
      }
    }
  }, [dataSource, handleRealTimeData, id]);

  return {chartData, isLoading, lastData};
}

export default useCharts;
