import React from 'react';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {useSelector} from 'react-redux';
import {getEditorSettings} from 'modules/EditMode/Editor';

interface Props {
  form: WrappedFormUtils;
  setSettings: Function;
}

function EditorSettings(props: Props) {
  const {form} = props;
  const {getFieldDecorator} = form;

  const editorSettings = useSelector(getEditorSettings);

  return (
    <Form layout="vertical" labelAlign="left">
      <Form.Item style={{margin: '0px'}} wrapperCol={{span: 24}}>
        {getFieldDecorator('editor.showGrid', {
          initialValue: editorSettings.showGrid,
          valuePropName: 'checked',
        })(<Checkbox>Show Grid</Checkbox>)}
      </Form.Item>
      <Form.Item style={{margin: '0px'}} wrapperCol={{span: 24}}>
        {getFieldDecorator('editor.enableSnap', {
          initialValue: editorSettings.enableSnap,
          valuePropName: 'checked',
        })(<Checkbox>Enable Snap</Checkbox>)}
      </Form.Item>
      <Form.Item style={{margin: '0px'}} wrapperCol={{span: 24}}>
        {getFieldDecorator('editor.showElementBorders', {
          initialValue: editorSettings.showElementBorders,
          valuePropName: 'checked',
        })(<Checkbox>Show Element Borders</Checkbox>)}
      </Form.Item>
      <Form.Item
        style={{margin: '0px'}}
        label={<div style={{marginTop: '7px'}}>Height</div>}
        labelCol={{span: 6}}
        wrapperCol={{span: 16}}
      >
        {getFieldDecorator('editor.height', {
          initialValue: editorSettings.height,
        })(<InputNumber min={window.innerHeight} style={{width: '100%'}} />)}
      </Form.Item>
    </Form>
  );
}

export default EditorSettings;
