import {SerialData} from 'interfaces/Data';

export enum LogTypes {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum LogActions {
  ADD ='ADD_LOG',
  CLEAR = 'CLEAR',
  ADD_LOG = 'ADD_LOG',
  CLEAR_LOGS = 'CLEAR_LOGS',
  ADD_ERROR = 'ADD_ERROR',
  CLEAR_ERRORS = 'CLEAR_ERRORS',
  //ADD_ELEMENT_DATA = 'ADD_ELEMENT_DATA',
  SET_DEBUGGER_VISIBILITY = 'SET_DEBUGGER_VISIBILITY',
  SET_DEBUGGER_TAB = 'SET_DEBUGGER_TAB',
}

export interface ElementDataStore {
  [elementId: string]: SerialData[];
}

export interface Log {
  type: LogTypes,
  message: string;
  time: Date | string;
  source?: {
    type: 'PAGE' | 'ELEMENT' | 'EDITOR';
    id?: string;
    name?: string;
  }
}

// export interface LogMessage extends Log {
//   type: Exclude<LogTypes, 'ERROR'>;
// }
//
// export interface ErrorMessage extends Log {
//   type: LogTypes.ERROR;
// }

export interface DebuggerStore {
  // elementsData: ElementDataStore;
  logs: Log[];
  isVisible: boolean;
  activeTab: string;
}
