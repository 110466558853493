import React from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';
import Styled from 'styled-components';
import {WrappedFormUtils} from 'antd/es/form/Form';

const FormLayout = Styled.div`
    width: 45%;
    margin: auto;
    border: 2px solid whitesmoke;
    margin-top: 60px;
    padding: 20px;
    text-align: center;
`;

interface Props {
  form: WrappedFormUtils;
  verifyPin: (pin: string) => void;
  pinFormError?: string;
}
function PinForm(props: Props) {
  const {form, verifyPin, pinFormError} = props;
  const {getFieldDecorator} = form;

  const handleSubmit = () => {
    const {form} = props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        verifyPin(values.pin);
      }
    });
  };

  return (
    <FormLayout>
      <h2>Enter PIN</h2>
      <Form className="login-form">
        <Form.Item>
          {getFieldDecorator('pin', {
            rules: [{required: true, message: 'Please input PIN!'}],
          })(
            <Input
              prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
        {pinFormError && <p style={{textAlign: 'center', color: 'red'}}>Invalid PIN!</p>}
        <Form.Item>
          <Button
            onClick={handleSubmit}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </FormLayout>
  );
}

export default Form.create<Props>()(PinForm);
