import {SerialData} from '../../../interfaces/Data';
import {OutputSelector} from "reselect";
import {Store} from "store/interfaces";

export enum DataActions {
  ADD ='ADD_ELEMENT_DATA',
  UPDATE = 'UPDATE_ELEMENT_DATA',
  DELETE = 'DELETE_ELEMENT_DATA',
  SET = 'SET_ELEMENTS_DATA',
}

export type ElementData = {id: string, data: SerialData[]};

export interface ElementDataStore {
  [id: string]: SerialData[];
}

export  interface DataOperations {
  getElementData(id: string): OutputSelector<Store, SerialData[], Function>;
  addElementData(data: ElementData): void;
}
