import LiquidGauge from './LiquidGauge';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from './interfaces';
import {DataTypes} from '../../../../interfaces/DataSources';

const LiquidGuagesBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'liquidgauge',
  name: 'Liquid Guage',
  category: 'KPI',
  Component: LiquidGauge,
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.TELEMETRIES, DataTypes.ATTRIBUTES],
    maxNumberOfKeys: 1,
    selectMode: 'default',
  },
  settings: Settings,
  width: 6,
  height: 6,
};
export default LiquidGuagesBluePrint;
