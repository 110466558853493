import React, {useCallback, useEffect, useState} from 'react';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Col from 'antd/es/col';
import {SettingsElementProps} from '../../../../interfaces/Settings';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {ISettings, ITab} from './interfaces';

const {Item: FormItem} = Form;
interface Props extends SettingsElementProps<ISettings> {
  form: WrappedFormUtils;
}

function Settings(props: Props) {
  const {
    form: {getFieldDecorator, getFieldValue},
    element: {settings},
  } = props;

  const [tabs, setTabs] = useState<ITab[]>([]);

  const addItem = useCallback(() => {
    setTabs(prevTabs => prevTabs.concat({}));
  }, []);

  const removeItem = useCallback(
    (itemIdex: number) => {
      const newItems = (getFieldValue('settings.tabs') || []).filter(
        (rule: ITab, index: number) => index !== itemIdex,
      );
      setTabs(newItems);
      props.setSettings({settings: {tabs: newItems}});
    },
    [getFieldValue, props],
  );

  useEffect(() => {
    if (settings && settings.tabs) {
      setTabs(settings.tabs);
    }
  }, [settings]);

  return (
    <Form>
      <Row type="flex" justify="start">
        <Col style={{marginLeft: '10px'}}>
          <Button onClick={addItem} icon="plus" shape="circle" type="dashed" />
        </Col>
        <Col md={4} sm={8} xs={12} style={{margin: 'auto auto auto 10px'}}>
          Add Item
        </Col>
      </Row>
      {tabs.map((item, index) => {
        return (
          <Row gutter={24} key={index}>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.tabs[${index}].name`, {
                  initialValue: item.name,
                })(<Input placeholder="Name" />)}
              </FormItem>
            </Col>
            <Col md={3} sm={16} xs={24}>
              <Button style={{marginBottom: 24}} onClick={() => removeItem(index)} icon="close" />
            </Col>
          </Row>
        );
      })}
    </Form>
  );
}
export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(Settings),
  },
];
