import {useState, useEffect, useCallback, useContext} from 'react';
import useElements from '../../Elements';
import {ElementSchema} from 'interfaces/Elements';
import APIs from './apis';
import {AppContext} from '../../../App';

type Props = {
  element: ElementSchema;
};

interface Response {
  value: string | boolean | number;
  sendAction(data: {value: string | boolean | number; name: string}): void;
}

function Controllers(props: Props): Response {
  const {params} = useContext(AppContext);
  const {dataSource, id} = props.element;
  const {getElementData} = useElements();
  const [value, setValue] = useState<string | boolean | number>(0);
  // const listenerId = Date.now();

  const fetchData = useCallback(() => {
    getElementData({id, dataSource}).then(result => {
      const {data} = result;
      if (data && data.length) {
        const [{value}] = data;
        setValue(`${value}`);
      }
    });
  }, [dataSource, getElementData, id]);

  useEffect(() => {
    fetchData();
    // listenOnData();
  }, [fetchData]);

  /*   const listenOnData = () => {
    if (props.params.publicId) {
      return;
    }
    const {listenOnEvent} = props;
    const eventName = `${dataSourceValue.identifier}-${dataType}`;
    removeDataListener();
    listenOnEvent(
      eventName,
      ({content}) => {
        const [newData] = content;
        if (newData.key === dataKeys[0]) {
          setValue(newData.value);
        }
      },
      listenerId,
    );
  };

  const removeDataListener = () => {
    const {removeEventListener} = props;
    removeEventListener(listenerId);
  }; */

  const sendAction = (data: {value: string | boolean | number; name: string}): void => {
    setValue(data.value);
    if (data.name && params.organizationId && params.applicationId) {
      APIs.sendAction<{organizationId: string; applicationId: string}>(data, {
        organizationId: params.organizationId,
        applicationId: params.applicationId,
      });
    }
  };

  return {value, sendAction};
}

export default Controllers;
