export enum AggregationFunctions {
  min = 'MIN',
  max = 'MAX',
  avg ='AVG',
  sum = 'SUM',
  count = 'COUNT',
  last = 'LAST'
}

export enum  AvailableUnits {
  SECONDS ='SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

export interface AggregationTimeBucket {
  value: number;
  unit: AvailableUnits;
}

export interface Aggregation {
  func?: AggregationFunctions;
  interval?: AggregationTimeBucket;
}

export interface AggregationOptions {
  aggregationFunctions?: AggregationFunctions[];
  enableTimeBucket?: boolean;
  // enableFunc?: boolean;
}
