import React, {useCallback, useEffect, useState} from 'react';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {SettingsElementProps} from 'interfaces/Settings';
import * as interfaces from './interfaces';
import Settings from '../../Control/Settings';
import FormItemSettings from '../FormItemSettings';
import ControlItemSettings from '../ControlItemSettings';

const FormItem = Form.Item;
const {Option} = Select;

interface Props extends SettingsElementProps<interfaces.Settings> {
  form: WrappedFormUtils;
}
const types = {
  Required: 'required',
  Max: 'max',
  Min: 'min',
  Pattern: 'pattern',
  WhiteSpace: 'whitespace',
};

function SettingsC(props: Props) {
  const {
    form,
    element,
    form: {getFieldDecorator, getFieldValue},
    element: {settings},
  } = props;
  const [rules, setRules] = useState<interfaces.Rule[]>([]);

  const addRule = useCallback(() => {
    setRules(prevRules => prevRules.concat({}));
  }, []);

  const removeRule = useCallback(
    (itemIdex: number) => {
      const newRules = getFieldValue('settings.rules').filter(
        (rule: interfaces.Rule, index: number) => index !== itemIdex,
      );
      setRules(newRules);
      props.setSettings({settings: {rules: newRules}});
    },
    [getFieldValue, props],
  );

  useEffect(() => {
    if (settings && settings.rules) {
      setRules(settings.rules);
    }
  }, [settings]);

  return (
    <Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
        }}
      >
        <FormItemSettings form={form} element={element} />
        <ControlItemSettings {...props} />
      </div>
      <Row type="flex" justify="start">
        <Col style={{marginLeft: '10px'}}>
          <Button onClick={addRule} icon="plus" shape="circle" type="dashed" />
        </Col>
        <Col md={4} sm={8} xs={12} style={{margin: 'auto auto auto 10px'}}>
          New
        </Col>
      </Row>
      {rules.map((rule, index) => {
        return (
          <Row gutter={24} key={index}>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.rules[${index}].type`, {
                  initialValue: rule.type,
                })(
                  <Select placeholder="Type">
                    {Object.entries(types).map(([key, value]) => (
                      <Option value={value} key={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.rules[${index}].typeValue`, {
                  initialValue: rule.typeValue,
                })(<Input placeholder="Type Value" />)}
              </FormItem>
            </Col>
            <Col md={7} sm={16} xs={24}>
              <FormItem>
                {getFieldDecorator(`settings.rules[${index}].message`, {
                  initialValue: rule.message,
                })(<Input placeholder="Message" />)}
              </FormItem>
            </Col>
            <Col md={3} sm={16} xs={24}>
              <Button style={{marginBottom: 24}} onClick={() => removeRule(index)} icon="close" />
            </Col>
          </Row>
        );
      })}
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(SettingsC),
  },
];
