import instance, {injectParamsIntoUrl} from '../../adaptors/httpClient';
import {Page} from 'interfaces/Pages';
import {IMenu} from 'interfaces/NavigationMenu';

const urls = {
  createPage: '/visualizations/:visualizationId/pages',
  pages: '/visualizations/:visualizationId',
  page: '/visualizations/:visualizationId/pages/:pageId',
  pagesSettings: '/visualizations/:visualizationId',
};

const publicUrls = {
  pages: '/',
  page: '/pages/:pageId',
};

interface Response {
  isPublic: boolean;
  isProtected: boolean;
  pages: Page[];
  menu: IMenu;
  name: string;
  protectionType: string;
}

export default {
  getAll: <P = {visualizationId: string}>(params: P): Promise<Response> => {
    const url = injectParamsIntoUrl<P>(urls.pages, params);
    return instance.get(url).then(({data}) => data.result);
  },
  getPage: <P = {visualizationId: string; pageId: string}>(params: P): Promise<Response> => {
    const url = injectParamsIntoUrl<P>(urls.page, params);
    return instance.get(url).then(({data}) => data.result);
  },
  getAllPublicPages: <P = {publicId: string}>(params: P): Promise<Response> => {
    const url = injectParamsIntoUrl<P>(publicUrls.pages, params);
    return instance.get(url).then(({data}) => data.result);
  },
  get: <P = {visualizationId: string; pageId: string}>(params: P) => {
    const url = injectParamsIntoUrl<P>(publicUrls.pages, params);
    return instance.get(url);
  },
  create: <P = {visualizationId: string}>(page: Page, params: P) => {
    const url = injectParamsIntoUrl<P>(urls.createPage, params);
    return instance.post(url, page);
  },
  update: <P = {visualizationId: string; pageId: string}>(page: Page, params: P) => {
    const url = injectParamsIntoUrl<P>(urls.page, params);
    return instance.put(url, page);
  },
  remove: <P = {visualizationId: string; pageId: string}>(params: P) => {
    const url = injectParamsIntoUrl<P>(urls.page, params);
    return instance.delete(url);
  },
  updateMenuSettings: <P = {visualizationId: string}>({menu, params}: {menu: IMenu; params: P}) => {
    const url = injectParamsIntoUrl<P>(urls.pagesSettings, params);
    return instance.put(url, {menu});
  },
};
