import instance, {injectParamsIntoUrl} from 'adaptors/httpClient';
import {Params, PublicParams} from 'interfaces/Elements';
import {dashboardUrl} from '../../../adaptors/config';
const urls = {
  getActions: '/v1/organizations/:organizationId/applications/:applicationId/actions',
  sendAction: '/v1/organizations/:organizationId/applications/:applicationId/actions/send',
};

const publicUrls = {
  sendAction: '/v1/visualizations/public/:publicId/pages/:pageId/components',
};

interface SParams {
  value: number | string | boolean;
  name: string;
}

interface Response {
  data: Array<{name: string}>;
  totalCount?: number;
  pageNumber?: number;
}

export default {
  sendAction: <P>(data: SParams, params: P) => {
    const url = injectParamsIntoUrl<P>(urls.sendAction, params);
    return instance.post(url, data, {
      baseURL: dashboardUrl,
    });
  },
  fetchActions: <P = {organizationId: string; applicationId: string}>(
    params: P,
  ): Promise<Response> => {
    const url = injectParamsIntoUrl<P>(urls.getActions, params);
    return instance
      .get(url, {
        baseURL: dashboardUrl,
      })
      .then(({data}) => data.result);
  },
};
