// @flow
import * as React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import Checkbox from 'antd/es/checkbox';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {Settings} from './interfaces';
import {SettingsElementProps} from 'interfaces/Settings';
import {WrappedFormUtils} from 'antd/lib/form/Form';

const {Item: FormItem} = Form;
const {Option} = Select;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
};

interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
}

function TableSettings(props: Props) {
  const {
    form: {getFieldDecorator, getFieldValue},
    element: {settings},
  } = props;

  return (
    <Form labelAlign="left" layout="inline">
      <Row>
        <Row gutter={24}>
          <Col md={8} sm={16} xs={24}>
            <FormItem label="Show Device Name Column" {...formItemLayout} className="full-width">
              {getFieldDecorator('settings.showDeviceNameColumn', {
                initialValue: settings && settings.showDeviceNameColumn,
                valuePropName: 'checked',
              })(<Checkbox />)}
            </FormItem>
          </Col>
          <Col md={8} sm={16} xs={24}>
            <FormItem label="Show Asset Name Column" className="full-width" {...formItemLayout}>
              {getFieldDecorator('settings.showAssetNameColumn', {
                initialValue: settings && settings.showAssetNameColumn,
                valuePropName: 'checked',
              })(<Checkbox />)}
            </FormItem>
          </Col>
          <Col md={8} sm={16} xs={24}>
            <FormItem label="Show Time Column" className="full-width" {...formItemLayout}>
              {getFieldDecorator('settings.showTimeColumn', {
                initialValue: settings && settings.showTimeColumn,
                valuePropName: 'checked',
              })(<Checkbox />)}
            </FormItem>
          </Col>
        </Row>
      </Row>
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem
            label="Custom Device Name Column Label"
            className="full-width"
            {...formItemLayout}
          >
            {getFieldDecorator('settings.deviceNameColumnLabel', {
              initialValue: settings && settings.deviceNameColumnLabel,
            })(<Input disabled={!getFieldValue('settings.showDeviceNameColumn')} />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Asset Name Column Label" className="full-width" {...formItemLayout}>
            {getFieldDecorator('settings.assetNameColumnLabel', {
              initialValue: settings && settings.assetNameColumnLabel,
            })(<Input disabled={!getFieldValue('settings.showAssetNameColumn')} />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Custom Time Column Label" className="full-width" {...formItemLayout}>
            {getFieldDecorator('settings.timeColumnLabel', {
              initialValue: settings && settings.timeColumnLabel,
            })(<Input disabled={!getFieldValue('settings.showTimeColumn')} />)}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Rows Count" className="full-width" {...formItemLayout}>
            {getFieldDecorator('settings.rowsCount', {
              initialValue: settings && settings.rowsCount,
            })(<InputNumber min={5} max={20} />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Table Size" className="full-width" {...formItemLayout}>
            {getFieldDecorator('settings.tableSize', {
              initialValue: settings && settings.tableSize,
            })(
              <Select>
                <Option value="small">Small</Option>
                <Option value="large">Large</Option>
              </Select>,
            )}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(TableSettings),
  },
];
