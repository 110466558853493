import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import debounce from 'lodash.debounce';
import Leaflet from 'leaflet';
import 'leaflet-routing-machine';
import {Map, TileLayer, WMSTileLayer} from 'react-leaflet';
import Marker from './Marker';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import './mapsUtils/style.css';
import {getMapTilesUrl, getMapContribution, getMarkerIcon} from './mapsUtils/utils';
import {MapOptions, Settings, Coordinates} from './interfaces';

interface Props {
  style?: {[key: string]: any};
  mapOptions?: MapOptions;
  settings: Settings;
  coordinatesData: {coordinates: Coordinates[]; data: {[key: string]: string | number}}[];
}

function MapComponent(props: Props) {
  const mapRef = useRef<Map>(null);
  const {
    style,
    mapOptions,
    settings,
    settings: {
      tilesKey = 'wikimedia',
      tilesCustomUrl = '',
      mapboxAPIKey = '',
      OSRMCustomUrl,
      markerPopupText,
    },
  } = props;
  const mapWidth = mapOptions && mapOptions.style && mapOptions.style.width;
  const mapHeight = mapOptions && mapOptions.style && mapOptions.style.height;

  const resize = useRef(
    debounce(() => {
      mapRef.current && mapRef.current.leafletElement.invalidateSize();
    }, 500),
  );

  const getContent = useCallback(
    ({content, data}: {content?: string; data: {[key: string]: number | string}}) => {
      const tester = /{{(.*?)}}/g;
      if (!content) {
        return '';
      }
      return ` ${content.replace(tester, (fullmatch: string, originalKey: string): string => {
        return data[originalKey] ? `${data[originalKey]}` : 'N/A';
      })}`;
    },
    [],
  );

  const markers = useMemo(() => {
    const filteredData = props.coordinatesData.filter(({coordinates}) => coordinates.length);
    return filteredData.map(({coordinates, data}) => {
      const [location] = coordinates;
      const icon = getMarkerIcon({
        iconNumber: settings.iconNumber || 0,
        rules: settings.rules || [],
        data,
      });
      return {
        position: location,
        name: location.markerTitle.concat(getContent({content: markerPopupText, data})),
        icon,
      };
    });
  }, [getContent, markerPopupText, props.coordinatesData, settings.iconNumber, settings.rules]);

  useEffect(() => {
    resize.current();
  }, [mapWidth, mapHeight, resize]);

  const mapCenter: [number, number] = useMemo(() => {
    if (!markers[0]) {
      return [0, 0];
    }
    return [markers[0].position.lat, markers[0].position.lng];
  }, [markers]);

  useEffect(() => {
    if (markers.length > 1 && mapRef.current) {
      const bounds = new Leaflet.LatLngBounds(
        markers.map(marker => [marker.position.lat, marker.position.lng]),
      );
      mapRef.current.leafletElement.fitBounds(bounds);
    }
  }, [mapRef, markers]);

  useEffect(() => {
    if (!mapRef.current || !OSRMCustomUrl) {
      return;
    }
    props.coordinatesData.forEach(({coordinates}) => {
      if (mapRef.current) {
        Leaflet.Routing.control({
          router: Leaflet.Routing.osrmv1({serviceUrl: OSRMCustomUrl}),
          waypoints: coordinates.map(waypoint =>
            Leaflet.latLng(waypoint.lat || 31, waypoint.lng || 30),
          ),
          routeWhileDragging: false,
        }).addTo(mapRef.current.leafletElement);
      }
    });
  }, [OSRMCustomUrl, mapRef, props.coordinatesData]);

  return (
    <div style={style}>
      <Map ref={mapRef} center={mapCenter} {...mapOptions} maxZoom={25} dragging={false}>
        {tilesKey === 'custom-wms' ? (
          <WMSTileLayer
            attribution={getMapContribution(tilesKey)}
            layers={(tilesCustomUrl.split('?')[1] || '').split('=')[1]}
            url={tilesCustomUrl.split('?')[0]}
          />
        ) : (
          <TileLayer
            attribution={getMapContribution(tilesKey)}
            url={
              tilesKey === 'custom-xyz' && tilesCustomUrl !== undefined
                ? tilesCustomUrl
                : getMapTilesUrl(tilesKey, mapboxAPIKey)
            }
          />
        )}
        <MarkerClusterGroup>
          {markers.map((marker, i) => (
            <Marker key={i} {...marker} position={[marker.position.lat, marker.position.lng]} />
          ))}
        </MarkerClusterGroup>
      </Map>
    </div>
  );
}
MapComponent.whyDidYouRender = Boolean(process.env.REACT_APP_ENABLE_DEBUG);
export default React.memo(MapComponent);
