import text from './Common/Text';
import image from './Common/Image';
import code from './Code';
import Button from './Form/Button';
import container from './Common/Container';
import Tabs from './Common/Tabs';
import lineChart from './Charts/SerialCharts/LineChart';
import columnChart from './Charts/SerialCharts/ColumnChart';
import pieChart from './Charts/PieChart';
import telemetryTable from './Tables/TelemetryTable';
import alarmsTable from './Tables/AlarmsTable';
import eventsTable from './Tables/EventsTable';
import Card from './KPIs/Card';
import Gauge from './KPIs/Gauge';
import LinearGauge from './KPIs/LinearGauge';
import LiquidGauge from './KPIs/LiquidGauge';
import MarkerMap from './Maps/MarkerMaps';
import RealTimeMaps from './Maps/RealTimeMaps';
import Form from './Form';
import Input from './Form/input';
import Radio from './Form/Radio';
import CheckBox from './Form/CheckBox';
import Select from './Form/Select';
import InputNumber from './Form/InputNumber';
import Switch from './Form/Switch';
import Slider from './Form/Slider';

const componentsArr = [
  text,
  image,
  code,
  container,
  lineChart,
  columnChart,
  pieChart,
  RealTimeMaps,
  MarkerMap,
  telemetryTable,
  alarmsTable,
  //dataTable,
  Tabs,
  Form,
  Input,
  Radio,
  CheckBox,
  Select,
  InputNumber,
  Button,
  eventsTable,
  Switch,
  Slider,
  Card,
  Gauge,
  LinearGauge,
  LiquidGauge,
];

export default componentsArr.reduce((result: {[type: string]: any}, component) => {
  result[component.type] = component;
  return result;
}, {});

// export default components;
