import React, {useCallback, useEffect, useMemo} from 'react';
import Table from 'antd/es/table';
import useTableColumns from '../useColumns';
import useTable from '../useTable';
import {ElementSchema} from 'interfaces/Elements';
import * as interfaces from '../interfaces';
import {groupDataByTime} from '../../../../utils/dataSources';

type Props = {
  element: ElementSchema<interfaces.Settings>;
  style: {[key: string]: any};
};

type Column = {
  [propName: string]: string | number | Function | {}[];
};

function TelemetryTable(props: Props) {
  const {
    data: {data, totalCount},
    isLoading,
    updatePagination,
  } = useTable({...props, transformData: true});
  const {
    element: {settings, dataSource},
    style,
  } = props;

  const onPaginationChange = useCallback(
    pageNumber => {
      updatePagination({pageNumber});
    },
    [updatePagination],
  );
  const initialColumns: Column[] = useMemo(
    () =>
      dataSource && dataSource.dataKeys
        ? dataSource.dataKeys.map((key: string) => {
            return {
              title: key,
              dataIndex: key,
              className: 'telemetry-value',
              key,
            };
          })
        : [],
    [dataSource],
  );

  const [columns] = useTableColumns({element: props.element, initialColumns});
  return (
    <Table
      key="resourceId"
      loading={isLoading}
      style={{
        height: '100%',
        backgroundSize: 'cover',
        pointerEvents: 'auto',
        ...style,
      }}
      pagination={{
        total: totalCount,
        pageSize: settings && settings.rowsCount,
        onChange: onPaginationChange,
      }}
      dataSource={groupDataByTime(data)}
      size={settings && settings.tableSize}
      rowKey="time"
      columns={columns}
    />
  );
}
TelemetryTable.whyDidYouRender = Boolean(process.env.REACT_APP_ENABLE_DEBUG);
export default React.memo(TelemetryTable);
