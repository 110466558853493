import React, {useCallback, useEffect, useState} from 'react';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import {SettingsElementProps} from '../../../interfaces/Settings';
import {Settings, IRule} from './interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Input from 'antd/es/input';

const {Option} = Select;
interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
  ruleType: 'telemetries' | 'attributes';
}

const FormItem = Form.Item;

const types: {[key: string]: string} = {
  telemetries: 'Telemetries',
  attributes: 'Attributes',
};

const operators = ['>', '>=', '<', '<=', '===', '!=='];

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function RulesSettings(props: Props) {
  const {
    form: {getFieldDecorator, getFieldValue},
    element: {settings, dataSource},
    ruleType,
  } = props;
  const [rules, setRules] = useState<IRule[]>([]);
  const keys = dataSource ? dataSource.dataKeys || [] : [];

  const addRule = useCallback(() => {
    setRules(preRules => preRules.concat({}));
  }, []);

  const removeRule = useCallback(
    (itemIdex: number) => {
      const newRules = getFieldValue('settings.rules').filter(
        (metric: IRule, index: number) => index !== itemIdex,
      );
      setRules(newRules);
      props.setSettings({settings: {rules: newRules}});
    },
    [getFieldValue, props],
  );

  useEffect(() => {
    if (settings && settings.rules) {
      setRules(settings.rules);
    }
  }, [settings]);

  return (
    <Form layout="inline" labelAlign="left">
      <Row type="flex" justify="start">
        <Col style={{marginLeft: '10px'}}>
          <Button onClick={addRule} icon="plus" shape="circle" type="dashed" />
        </Col>
        <Col md={4} sm={8} xs={12} style={{margin: 'auto auto auto 10px'}}>
          New
        </Col>
      </Row>
      {rules.map((rule, index) => {
        const iconNumber =
          getFieldValue(`settings.rules[${index}].markerNumber`) || rule.markerNumber || 0;
        const img =
          Number(iconNumber) !== 8 &&
          // eslint-disable-next-line global-require
          require(`./mapsUtils/images/markers/marker-icon-${iconNumber}.png`);

        return (
          <Row gutter={24} key={index} type="flex" justify="space-between">
            <Col md={4} sm={16} xs={24}>
              <FormItem label="Data Type" className="full-width" {...formItemLayout}>
                {getFieldDecorator(`settings.rules[${index}].type`, {
                  initialValue: types[ruleType],
                })(
                  <Select style={{width: '100%'}} disabled={true}>
                    {Object.entries(types).map(([key, type]) => (
                      <Option title={type} key={key}>
                        {type}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col md={4} sm={16} xs={24}>
              <FormItem label="Key" className="full-width" {...formItemLayout}>
                {getFieldDecorator(`settings.rules[${index}].key`, {
                  initialValue: rule.key,
                  rules: [{required: true}],
                })(
                  <Select style={{width: '100%'}}>
                    {keys.map(key => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col md={4} sm={16} xs={24}>
              <FormItem label="Operator" className="full-width" {...formItemLayout}>
                {getFieldDecorator(`settings.rules[${index}].operator`, {
                  initialValue: rule.operator,
                  rules: [{required: true}],
                })(
                  <Select style={{width: '100%'}} placeholder="Operator">
                    {operators.map(operator => (
                      <Option key={operator}>{operator}</Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col md={4} sm={16} xs={24}>
              <FormItem label="Value" className="full-width" {...formItemLayout}>
                {getFieldDecorator(`settings.rules[${index}].ruleValue`, {
                  initialValue: rule.ruleValue,
                  rules: [{required: true}],
                })(<Input style={{width: '100%'}} />)}
              </FormItem>
            </Col>
            <Col md={4} sm={16} xs={24}>
              <FormItem label="Icon" className="full-width" {...formItemLayout}>
                {getFieldDecorator(`settings.rules[${index}].markerNumber`, {
                  initialValue: rule.markerNumber,
                  rules: [{required: true}],
                })(
                  <Select style={{width: '100%'}}>
                    <Option value="0">Default Icon</Option>
                    <Option value="1">Red Marker</Option>
                    <Option value="2">Chip</Option>
                    <Option value="3">Wifi</Option>
                    <Option value="4">Car</Option>
                    <Option value="5">Truck</Option>
                    <Option value="6">Green OK</Option>
                    <Option value="7">Warning</Option>
                    <Option value="8">Hidden</Option>
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col md={1} sm={4} xs={6}>
              {img && <img src={img} alt="marker" />}
            </Col>
            <Col md={1} sm={16} xs={24}>
              <Button style={{marginBottom: 24}} onClick={() => removeRule(index)} icon="close" />
            </Col>
          </Row>
        );
      })}
    </Form>
  );
}

export default React.memo(
  Form.create({
    onValuesChange: (props: Props, changedValues, allValues) => {
      const {
        settings: {rules},
      } = allValues;
      const isNotValid = rules.some(
        (rule: IRule) => !rule.markerNumber || !rule.ruleValue || !rule.operator || !rule.key,
      );
      if (!isNotValid) {
        props.setSettings(allValues);
      }
    },
  })(RulesSettings),
);
