import React, {useEffect} from 'react';
import Layout from 'antd/es/layout';
import useElements from '../Elements';
import usePages from '../Pages';
import {PageContainer} from './StyledComponents';
import {getMenuStore} from 'modules/NavigationMenu';
import ViewComponent from './ViewComponent';
import {useSelector} from 'react-redux';
import Spinner from '../../commons/Spinner';
import {getIsLoading} from '../EditMode/Editor';
import {SizeMe} from 'react-sizeme';
import EditorComponent from '../EditMode/EditorComponent';
import {Responsive, WidthProvider as widthProvider} from 'react-grid-layout';

const ResponsiveReactGridLayout = widthProvider(Responsive);

function View() {
  const {getElementsWithInjectedChildren, fetchElements} = useElements();
  const {currentPageSelector, fetchPages} = usePages();
  const isLoading = useSelector(getIsLoading);
  const elementsWithInjectedChildren = useSelector(getElementsWithInjectedChildren);
  const currentPage = useSelector(currentPageSelector);
  const menuOptions = useSelector(getMenuStore);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    fetchElements();
  }, [currentPage, fetchElements]);

  const layouts = elementsWithInjectedChildren.map(element => ({
    ...element,
    w: Number(element.width),
    h: Number(element.height),
    x: element.left,
    y: element.top,
    i: element.id,
  }));

  console.log('===== VIEW MODE ====', elementsWithInjectedChildren);

  return (
    <div>
      {/*<NavigationMenu />*/}
      <PageContainer
        pageWidth={currentPage && currentPage.settings ? currentPage.settings.pageWidth : ''}
        pageHeight={
          currentPage && currentPage.settings && currentPage.settings.pageHeight
            ? menuOptions.enabled
              ? `${Number(currentPage.settings.pageHeight) - 48}`
              : currentPage.settings.pageHeight
            : ''
        }
        styles={currentPage && currentPage.style ? currentPage.style.content : ''}
      >
        <Layout>
          <Spinner isLoading={isLoading}>
            {/*{elementsWithInjectedChildren.map(element => (*/}
            {/*  <ViewComponent width={size.width} element={element} key={element.id} />*/}
            {/*))}*/}
            <ResponsiveReactGridLayout
              margin={[0, 0]}
              cols={{lg: 48, md: 24, sm: 12, xs: 8, xxs: 4}}
              rowHeight={28}
              style={{padding: 0, minHeight: '100vh'}}
              layouts={{lg: layouts}}
              measureBeforeMount={true}
              useCSSTransforms={true}
              compactType={null}
              preventCollision={true}
              isResizable={false}
              isDraggable={false}
              // @ts-ignore
              isDroppable={false}
            >
              {elementsWithInjectedChildren.map(element => {
                return (
                  <div key={element.id}>
                    <EditorComponent
                      element={element}
                      handleDropElement={() => {}}
                      width={element.width}
                      isLive={true}
                    />
                  </div>
                );
              })}
            </ResponsiveReactGridLayout>
          </Spinner>
        </Layout>
      </PageContainer>
    </div>
  );
}
export default View;
