import {useState, useEffect, useCallback} from 'react';
import {ElementSchema} from 'interfaces/Elements';
import useElements, {transformDataFunc} from '../../Elements';
import {transformFilters, Filters} from 'utils/filtering';
import {SerialData} from 'interfaces/Data';
import {LogTypes} from 'modules/EditMode/Debugger/store/interfaces';
import {addLog} from '../../EditMode/Debugger';
import {useDispatch} from 'react-redux';
import {Settings as SettingsSchema} from './interfaces';
import {listenOnEvent, removeEventListener} from 'adaptors/webSocket';
import {getDataSourceEventName} from '../../../utils/dataSources';

type Props<T> = {
  element: ElementSchema<SettingsSchema>;
  transformData: boolean;
  dataSchema?: T;
  isPreview?: boolean;
};

interface Data {
  data: SerialData[];
  totalCount: number;
}

interface Response {
  data: Data;
  pagination: Pagination;
  setData: Function;
  isLoading: boolean;
  setTableFilters(filters: Filters): void;
  updatePagination(pagination: Partial<Pagination>): void;
}

interface Pagination {
  pageNumber: number;
  pageSize: number;
}

const initialPagination = {
  pageNumber: 1,
  pageSize: 10,
};

const initialData = {
  data: [],
  totalCount: 0,
};

function useTable<T>(props: Props<T>): Response {
  const {element, transformData} = props;
  const dispatch = useDispatch();
  const {getElementData} = useElements();
  const {id, dataSource, settings, timeWindow} = element;
  const [data, setData] = useState<Data>(initialData);
  const [filters, setTableFilters] = useState<Filters>();
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pageSize = settings && settings.rowsCount;

  const updatePagination = useCallback((pagination: Partial<Pagination>) => {
    setPagination(prevPagination => ({...prevPagination, ...pagination}));
  }, []);

  // useEffect(() => {
  //   if (pageSize && pageSize !== pagination.pageSize) {
  //     updatePagination({pageSize});
  //   }
  // }, [pageSize, pagination.pageSize, updatePagination]);

  const handleRealTimeData = useCallback(data => {
    setData(currentData => ({
      ...currentData,
      data: [...transformDataFunc(data), ...currentData.data],
    }));
  }, []);

  useEffect(() => {
    dispatch(
      addLog({
        type: LogTypes.INFO,
        message: `Fetching Data for component`,
        source: {
          type: 'ELEMENT',
          id,
        },
      }),
    );
    setIsLoading(true);
    getElementData(
      {id, dataSource},
      {filters: transformFilters(filters || {}), ...pagination, pageSize},
      transformData,
    )
      .then(({data, totalCount}) => {
        setData({data, totalCount});
        dispatch(
          addLog({
            type: LogTypes.INFO,
            message: `Data Fetched for component`,
            source: {
              type: 'ELEMENT',
              id,
            },
          }),
        );
        setIsLoading(false);
      })
      .catch(e => {
        dispatch(
          addLog({
            type: LogTypes.ERROR,
            message: `Error while Fetching Data for component`,
            source: {
              type: 'ELEMENT',
              id,
            },
          }),
        );
        setIsLoading(false);
      });
  }, [dataSource, dispatch, filters, getElementData, id, pageSize, pagination, transformData]);

  useEffect(() => {
    if (dataSource && (timeWindow && timeWindow.type === 'REAL_TIME')) {
      const eventName = getDataSourceEventName(dataSource);
      if (eventName) {
        listenOnEvent(eventName, handleRealTimeData, id);
        return () => removeEventListener(id);
      }
    }
  }, [dataSource, handleRealTimeData, id, timeWindow]);

  return {data, isLoading, setData, setTableFilters, pagination, updatePagination};
}

// useTable.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;

export default useTable;
