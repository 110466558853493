export enum AvailableTabs {
  INFO = 'info',
  DATA_SOURCE = 'dataSource',
  ELEMENTS = 'elements',
  CONSOLE = 'Console',
  SETTINGS = 'settings',
  DATA = 'data',
  STYLES = 'styles',
  ADVANCED_STYLES = 'ADVANCED_STYLES'
}

export enum DockPositions {
  // TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}
