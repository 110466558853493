import React, {memo, useCallback, useState} from 'react';
import Row from 'antd/es/row';
import {useDispatch, useSelector} from 'react-redux';
import {
  getLibraryVisibility,
  getPagesVisibility,
  getSettingsVisibility,
  setEditorSettings,
  toggleElementsLibraryVisibility,
  togglePagesVisibility,
  toggleSettingsVisibility,
} from '../Editor';
import Pages from './Pages';
import {StyledButton} from './styles';
import {Page} from 'interfaces/Pages';
import SettingsMenu from './Settings';
import {ActiveModal} from './interfaces';
import EditorSettingsModal from './EditorSettings/';
import PageSettingsModal from './PageSettings/PageSettings';
import MenuSettingsModal from './MenuSettings';
import HotKeysModal from './HotKeysModal';

function ActionsSider() {
  const dispatch = useDispatch();
  const isLibraryVisible = useSelector(getLibraryVisibility);
  const isSettingsVisible = useSelector(getSettingsVisibility);
  const isPagesVisible = useSelector(getPagesVisibility);
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const [pageInSettings, setPageInSettings] = useState<Page | null>(null);

  const setEditorSetting = useCallback(
    settings => {
      dispatch(setEditorSettings(settings));
    },
    [dispatch],
  );

  const addComponentHandler = useCallback(() => {
    dispatch(toggleElementsLibraryVisibility());
  }, [dispatch]);

  const settingsHandler = useCallback(() => {
    dispatch(toggleSettingsVisibility());
  }, [dispatch]);

  const pagesHandler = useCallback(() => {
    dispatch(togglePagesVisibility());
  }, [dispatch]);

  return (
    <>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{height: '100%', flexDirection: 'column'}}
      >
        <StyledButton
          onClick={addComponentHandler}
          visible={isLibraryVisible}
          tooltipPlacement="right"
          tooltipTitle="Add Components"
          icon="plus"
        />
        <StyledButton
          onClick={settingsHandler}
          visible={isSettingsVisible}
          tooltipPlacement="right"
          tooltipTitle="Editor Settings"
          icon="setting"
        />
        <StyledButton
          onClick={pagesHandler}
          visible={isPagesVisible}
          tooltipPlacement="right"
          tooltipTitle="Pages Store"
          icon="file-zip"
        />
      </Row>
      <SettingsMenu isVisible={isSettingsVisible} setActiveModal={setActiveModal} />
      <Pages isVisible={isPagesVisible} setPageInSettings={setPageInSettings} />
      <EditorSettingsModal
        isVisible={activeModal === ActiveModal.EDITOR_SETTINGS}
        setSettings={setEditorSetting}
        onClose={() => setActiveModal(null)}
      />
      <PageSettingsModal
        visible={Boolean(pageInSettings)}
        page={pageInSettings}
        onClose={() => setPageInSettings(null)}
      />
      <MenuSettingsModal
        visible={activeModal === ActiveModal.MENU_SETTINGS}
        onClose={() => setActiveModal(null)}
      />
      <HotKeysModal
        visible={activeModal === ActiveModal.HOT_KEYS}
        onClose={() => setActiveModal(null)}
      />
    </>
  );
}

export default memo(ActionsSider);
