import React, {useCallback, useContext, useEffect, useState} from 'react';
import ClipboardButton from 'react-clipboard.js';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import {WrappedFormUtils} from 'antd/es/form/Form';
import api from 'modules/EditMode/Editor/api';
import {AppContext} from 'App';
import debounce from 'lodash.debounce';
import Spin from 'antd/es/spin';

const {Option} = Select;
const {Password} = Input;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
    md: {span: 16},
  },
};

interface Props {
  form: WrappedFormUtils;
  // saveSettings: Function;
}

interface IShareOptions {
  protectionType: string | null;
  pin: string | null;
  publicId: string | null;
}

const getVisualizationShareLink = (visualizationId: string, publicId: string): string => {
  const url = `/public/${publicId}/site-builder/${visualizationId}`;
  return new URL(url, window.location.origin).href;
};

function ShareOptions(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState<IShareOptions | null>(null);
  const {params} = useContext(AppContext);
  const {form} = props;
  const {getFieldDecorator, getFieldValue} = form;
  const {visualizationId} = params;

  const protectionType =
    getFieldValue('protectionType') || (initialData && initialData.protectionType);

  const getVisualizationSettings = useCallback(async () => {
    setIsLoading(true);
    const shareOptions = await api.getShareOptions<{visualizationId?: string}, IShareOptions>({
      visualizationId,
    });
    setIsLoading(false);
    setInitialData({
      protectionType: shareOptions.protectionType,
      pin: '*****',
      publicId: shareOptions.publicId,
    });
  }, [visualizationId]);

  const publicLink =
    initialData &&
    initialData.publicId &&
    getVisualizationShareLink(String(visualizationId), String(initialData.publicId));

  const editVisualizationSettings = useCallback(
    data => {
      return api.editShareOptions(data, {visualizationId});
    },
    [visualizationId],
  );

  const saveShareSettings = useCallback(
    debounce(() => {
      form.validateFields(async (err, values) => {
        if (!err) {
          if (values.protectionType === 'protected') {
            values.isProtected = true;
            values.isPublic = true;
          } else if (values.protectionType === 'public') {
            values.isPublic = true;
          } else if (values.protectionType === 'private') {
            values.isProtected = false;
            values.isPublic = false;
          }
          await editVisualizationSettings(values);
          getVisualizationSettings();
        }
      });
    }, 1000),
    [editVisualizationSettings],
  );

  useEffect(() => {
    getVisualizationSettings();
  }, [getVisualizationSettings]);

  return (
    <Form layout="vertical" labelAlign="left">
      <Spin delay={500} spinning={isLoading}>
        <Form.Item
          style={{margin: '0px'}}
          wrapperCol={{span: 24}}
          label="Protection Type"
          {...formItemLayout}
        >
          {getFieldDecorator('protectionType', {
            initialValue: initialData && initialData.protectionType,
          })(
            <Select onChange={() => saveShareSettings()}>
              <Option key="public">Public</Option>
              <Option key="protected">Protected</Option>
              <Option key="private">Private</Option>
            </Select>,
          )}
        </Form.Item>
        {protectionType === 'protected' && (
          <Form.Item
            style={{margin: '0px'}}
            wrapperCol={{span: 24}}
            label="PIN"
            {...formItemLayout}
          >
            {getFieldDecorator('pin', {
              rules: [{required: true}],
              initialValue: initialData && initialData.pin,
            })(<Password onChange={() => saveShareSettings()} />)}
          </Form.Item>
        )}
        {protectionType === 'public' && publicLink && (
          <>
            <br />
            <ClipboardButton
              component="span"
              data-clipboard-text={publicLink}
              onSuccess={() => {}}
              onError={console.log}
            >
              <Input
                readOnly
                style={{width: '80%', cursor: 'pointer'}}
                addonBefore="Public Link"
                addonAfter="Copy"
                value={publicLink}
              />
            </ClipboardButton>
          </>
        )}
      </Spin>
    </Form>
  );
}

export default Form.create<Props>()(ShareOptions);
