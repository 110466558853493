import React from 'react';
import Descriptions from 'antd/es/descriptions';
import {ElementSchema} from '../../../interfaces/Elements';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

type Props = {
  element: ElementSchema;
};

function ElementDebuggerData({element}: Props) {
  if (!element) {
    return null;
  }

  return (
    <Descriptions bordered>
      <Descriptions.Item label="Layout" span={3}>
        <Row>
          <Col span={6}>
            <strong>Top : </strong> {element.top}
          </Col>
          <Col span={6}>
            <strong>Left : </strong> {element.left}
          </Col>
          <Col span={6}>
            <strong>Width : </strong> {element.width}
          </Col>
          <Col span={6}>
            <strong>Height : </strong> {element.height}
          </Col>
        </Row>
      </Descriptions.Item>
    </Descriptions>
  );
}

export default ElementDebuggerData;
