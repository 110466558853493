import React from 'react';
import {ReactComponent as ImagePlaceHolder} from './img_placeholder.svg';
import {ElementSchema} from 'interfaces/Elements';
import {Settings} from './interfaces';
import {StyledComponent} from '../../styled';

interface Props {
  element: ElementSchema<Settings>;
}

function Image(props: Props) {
  const {element} = props;
  const {settings = {}, styles} = element;

  if (!settings.imgUrl) {
    return (
      <StyledComponent styles={(styles && styles.content) || ''}>
        <ImagePlaceHolder style={{fill: '#a7a7a7'}} width="100%" height="90%" />
      </StyledComponent>
    );
  }

  return (
    <StyledComponent styles={(styles && styles.content) || ''}>
      <img width="100%" height="100%" src={settings.imgUrl} alt="" />
    </StyledComponent>
  );
}

export default Image;
