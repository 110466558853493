import React, {useEffect} from 'react';
import Form from 'antd/es/form';
import * as interfaces from './interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {ElementSchema} from '../../../../interfaces/Elements';
import useController from '../../Control/Prototype';
import Slider from 'antd/es/slider';

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form?: WrappedFormUtils;
}
const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function SliderCmp(props: Props) {
  const {value, sendAction} = useController(props);
  const {
    element: {settings = {}},
    form,
  } = props;
  const {fieldName, label} = settings || {};

  const onChange = (value: number) => {
    if (settings.actionName) {
      sendAction({
        value,
        name: settings.actionName,
      });
    }
  };

  useEffect(() => {
    if (!form || !fieldName) {
      return;
    }
    form.setFieldsValue({[fieldName]: value});
  }, [fieldName, form, value]);

  if (form) {
    return (
      <FormItem label={label} {...formItemLayout}>
        {form.getFieldDecorator(`${fieldName}`, {
          initialValue: Number(value),
        })(
          <Slider
            vertical={settings.isVertical}
            style={{width: settings.isVertical ? undefined : '100%', margin: '5px 0'}}
            min={settings.minValue}
            max={settings.maxValue}
            onChange={value => {
              if (typeof value === 'number') {
                onChange(value);
              }
            }}
          />,
        )}
      </FormItem>
    );
  }
  return (
    <Slider
      vertical={settings.isVertical}
      value={Number(value)}
      style={{width: settings.isVertical ? undefined : '100%', margin: '5px 0'}}
      min={settings.minValue}
      max={settings.maxValue}
      onChange={value => {
        if (typeof value === 'number') {
          onChange(value);
        }
      }}
    />
  );
}

export default SliderCmp;
