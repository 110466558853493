// @flow
import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import Icon from 'antd/es/icon';
import InputNumber from 'antd/es/input-number';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {Settings} from './interfaces';
import {SettingsElementProps} from '../../../../interfaces/Settings';
import {WrappedFormUtils} from 'antd/lib/form/Form';

const {Item: FormItem} = Form;

interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
}

function LiquidGaugeSettings(props: Props) {
  const {
    form: {getFieldDecorator},
    element: {settings = {}},
  } = props;

  return (
    <Form layout="inline" labelAlign="left">
      <Row gutter={24} type="flex" justify="space-around">
        <Col md={12} sm={16} xs={24}>
          <FormItem label="Title">
            {getFieldDecorator('settings.title', {
              initialValue: settings.title,
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={12} sm={16} xs={24}>
          <FormItem
            label={
              <span>
                Total Number &nbsp;
                <Tooltip title="total-number-tooltip">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('settings.maxValue', {
              initialValue: settings.maxValue || 100,
            })(<InputNumber />)}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(LiquidGaugeSettings),
  },
];
