import React, {useCallback, useEffect, useState} from 'react';
import View from './view';
import EditorApis from 'modules/EditMode/Editor/api';
import PinForm from './PinForm';
// import useFetch from 'use-http';
// import {authorizationToken} from 'adaptors/httpClient';
// import {PublicParams} from '../../App';
// import queryString from 'query-string';

type Props = {
  setAuthorizationToken: Function;
  publicId?: string;
  isPreview: boolean;
};

function ViewMode({publicId, isPreview, setAuthorizationToken}: Props) {
  const [isPinFormVisible, setPinFormVisibility] = useState<boolean>(false);
  const [pinFormError, setPinFormError] = useState<string>();

  // console.log('=== data ===', data);
  // const qs = queryString.parse(window.location.search);

  // if (qs.token && typeof qs.token === 'string') {
  //   authorizationToken.set(qs.token);
  // }

  // if (
  //   qs.token &&
  //   typeof qs.applicationId === 'string' &&
  //   typeof qs.organizationId === 'string' &&
  //   typeof qs.visualizationId === 'string'
  // ) {
  //   paramsApi.set({
  //     applicationId: qs.applicationId,
  //     organizationId: qs.organizationId,
  //     visualizationId: qs.visualizationId,
  //   });
  // }

  const verifyPin = useCallback(
    (pin: string) => {
      if (!publicId) {
        return;
      }
      EditorApis.verifyPIN({publicId, pin})
        .then(result => {
          setPinFormVisibility(false);
          setAuthorizationToken(result);
        })
        .catch(() => {
          setPinFormError('invalid_pin');
        });
    },
    [publicId, setAuthorizationToken],
  );

  useEffect(() => {
    if (!isPreview) {
      EditorApis.getPublicVisualizationInfo()
        .then(result => {
          const {isProtected} = result;
          setPinFormVisibility(isProtected);
        })
        .catch(() => {
          // TODO: Check the returned HTTP code is 403
          setPinFormVisibility(true);
        });
    }
  }, [isPreview]);

  if (isPinFormVisible) {
    return <PinForm verifyPin={verifyPin} pinFormError={pinFormError} />;
  }

  return <View />;
}

export default ViewMode;
