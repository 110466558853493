import {Elements, ElementSchema, SelectedElementPosition} from 'interfaces/Elements';
import {Info} from "../../../../interfaces/Editor";

export interface IEditor {
  isLoading: boolean;
  isSaving: boolean;
  hasUnsavedChanges: boolean;
  isElementsLibraryVisible: boolean;
  isSettingsVisible: boolean;
  isPagesVisible: boolean;
  isHotKeysModalVisible: boolean;
  elementInCopy?: ElementSchema;
  isDragging: boolean;
  selectedElementPosition: SelectedElementPosition;
  activeModal?: string;
  editorInfo: Info;
  settings: {
    showGrid?: boolean;
    enableSnap?: boolean;
    showElementBorders?: boolean;
    height: number;
    width?: number;
    styles?: string;
  },
  isResizing:{
    isResize: boolean;
    elementId: string | null;
  },
}

export enum Actions {
  SET_ELEMENT_POSITION = 'SET_ELEMENT_POSITION',
  TOGGLE_LIBRARY_VISIBILITY ='TOGGLE_LIBRARY_VISIBILITY',
  TOGGLE_HOT_KEYS_VISIBILITY = 'TOGGLE_HOT_KEYS_VISIBILITY',
  SET_IS_DRAGGING = 'SET_IS_DRAGGING',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_SETTINGS = 'SET_SETTINGS',
  SET_ELEMENT_IN_COPY = 'SET_ELEMENT_IN_COPY',
  TOGGLE_SETTINGS_VISIBILITY = 'TOGGLE_SETTINGS_VISIBILITY',
  TOGGLE_PAGES_VISIBILITY ='TOGGLE_PAGES_VISIBILITY',
  CLOSE_EDITOR_SETTINGS = 'CLOSE_EDITOR_SETTINGS',
  SET_SAVING_LOADER_STATUS = 'SET_SAVING_LOADER_STATUS',
  SET_CHANGES_STATUS = 'SET_CHANGES_STATUS',
  SET_EDITOR_INFO = 'SET_EDITOR_INFO',
  SET_IS_RESIZING = 'SET_IS_RESIZING',
}
export enum OperationTypes {
  FETCHING_PAGES='FETCHING_PAGES',
  UPDATING_PAGES = 'UPDATING_PAGES',
  CREATING_PAGES ='CREATING_PAGES',
  DELETING_PAGES ='DELETING_PAGES',
  FETCHING_ELEMENTS = 'FETCHING_ELEMENTS',
  UPDATE_ELEMENT='UPDATE_ELEMENT',
  CLEAR_ELEMENTS= 'CLEAR_ELEMENTS',
  SAVING_ELEMENTS = 'SAVING_ELEMENTS',
  UPDATING_MENU_SETTINGS= 'UPDATING_MENU_SETTINGS',
  CREATE_ELEMENT = 'CREATE_ELEMENT',
  DELETE_ELEMENT = 'DELETE_ELEMENT',
}
