import styled from 'styled-components/macro';
import Tabs from 'antd/es/tabs';
const {TabPane} = Tabs;

export const Debugger = styled.div`
  .ant-select-dropdown {
    vertical-align: top;
  }
`;

export const StyledTabs = styled(Tabs)`
  &&& {
    overflow: visible;
  }
`;
export const StyledTabPane = styled(TabPane)`
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
`;
