import Text from './Text';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {DataTypes} from '../../../../interfaces/DataSources';

const TextBluePrint: ComponentCharacteristics<{text: string}> = {
  type: 'text',
  name: 'Text',
  category: 'Common',
  Component: Text,
  settings: Settings,
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.ATTRIBUTES, DataTypes.TELEMETRIES],
  },
  width: 6,
  height: 4,
};

export default TextBluePrint;
