import React, {useCallback, useContext, useEffect, useState} from 'react';
import Form from 'antd/es/form';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Select from 'antd/es/select';
import {DataSourceTypes, DataSourceValue} from 'interfaces/DataSources';
import debounce from 'lodash.debounce';
import Api from '../api';
import {AppContext} from 'App';

const FormItem = Form.Item;
const {Option} = Select;

interface Props {
  form: WrappedFormUtils;
  initialValue?: DataSourceValue;
  formItemLayout: {};
  disabled?: boolean;
}

function DataSourceValueComponent(props: Props) {
  const {form, initialValue, formItemLayout, disabled} = props;
  const {getFieldDecorator, getFieldValue} = form;
  const [data, setData] = useState<Array<{id: string; name: string}>>([]);
  const [loading, setIsLoading] = useState(false);
  const {params} = useContext(AppContext);
  const {dataSourceType} = getFieldValue('dataSource') || {};

  const searchForDevice = async (deviceName: string) => {
    const data = await Api.searchForDevice(deviceName, params);
    setData(data.map(device => ({id: device.id, name: device.name})));
  };

  const searchForTag = async (tagName: string) => {
    const data = await Api.searchForTag(tagName, params);
    setData(data.map(({name}) => ({id: name, name: name})));
  };

  const handleDataSourceValueSearch = useCallback(
    debounce<(a: string) => void>(searchValue => {
      setIsLoading(true);
      const {dataSourceType} = form.getFieldValue('dataSource') || {};
      if (dataSourceType === DataSourceTypes.DEVICE) {
        searchForDevice(searchValue).then(() => setIsLoading(false));
      } else if (dataSourceType === DataSourceTypes.TAG) {
        searchForTag(searchValue).then(() => setIsLoading(false));
      }
    }, 500),
    [],
  );

  const handleSelect = useCallback(() => {
    form.setFieldsValue({
      dataKeys: undefined,
    });
  }, [form]);

  useEffect(() => {
    if (dataSourceType) {
      handleDataSourceValueSearch('');
    }
  }, [dataSourceType]);

  return (
    <FormItem {...formItemLayout} label="Data Source">
      {getFieldDecorator('dataSource.dataSourceValue', {
        initialValue: initialValue && {key: initialValue.identifier, label: initialValue.name},
      })(
        <Select
          loading={loading}
          showSearch={true}
          onSearch={handleDataSourceValueSearch}
          labelInValue={true}
          disabled={disabled}
          onSelect={handleSelect}
        >
          {data.map(record => (
            <Option key={record.id}>{record.name}</Option>
          ))}
        </Select>,
      )}
    </FormItem>
  );
}

export default DataSourceValueComponent;
