import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {Provider} from 'react-redux';
import store from './store';
import {BrowserRouter as Router} from 'react-router-dom';

// registering service worker
// import registerServiceWorker from './registerServiceWorker';

// app ui
import App from './App';
import HTML5Backend from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';

// registerServiceWorker();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
