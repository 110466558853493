import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import Menu from 'antd/lib/menu';
import {Store} from 'store/interfaces';
import {Actions, IMenu} from 'interfaces/NavigationMenu';
import {Page} from 'interfaces/Pages';
import usePages from '../Pages';

export const getMenuStore = (store: Store) => store.navigationMenu;

export const setMenuOptions = (options: IMenu) => ({
  type: Actions.SET_MENU_OPTIONS,
  payload: options,
});

export const addMenuLink = (page: Page) => ({
  type: Actions.ADD_MENU_LINK,
  payload: page,
});

function NavigationMenu() {
  const {setCurrentPage} = usePages();
  const menuOptions = useSelector(getMenuStore);

  const handleClick = useCallback(
    path => {
      setCurrentPage(path);
    },
    [setCurrentPage],
  );

  if (!menuOptions.enabled) {
    return null;
  }

  return (
    <Menu onClick={({key}) => handleClick(key)} mode="horizontal">
      {menuOptions.enableIcon && menuOptions.icon && (
        <Menu.Item style={{pointerEvents: 'none'}}>
          <img
            width={menuOptions.icon.width || 40}
            height={menuOptions.icon.height}
            style={{maxHeight: 50}}
            alt=""
            src={menuOptions.icon.url}
          />
        </Menu.Item>
      )}
      {menuOptions.links.map(({name, path}) => (
        <Menu.Item key={path}>{name}</Menu.Item>
      ))}
    </Menu>
  );
}

export default NavigationMenu;
