import instance, {injectParamsIntoUrl} from '../../adaptors/httpClient';
import {ElementSchema} from 'interfaces/Elements';
import {DataResponse} from '../../interfaces/DataSources';
import {composeUrl} from 'adaptors/config';

const urls = {
  getAll: '/visualizations/:visualizationId/pages/:pageId/components',
  elements: '/visualizations/:visualizationId/components/',
  element: '/visualizations/:visualizationId/components/:componentId',
  savePageComponents: '/visualizations/:visualizationId/pages/:pageId/components',
  updateComponent: '/visualizations/:visualizationId/components/:componentId',
  getComponentData:
    '/v1/organizations/:organizationId/applications/:applicationId/visualizations/:visualizationId/pages/:pageId/components/:componentId',
  getPublicComponentData: '/v1/visualizations/public/:publicId/components/:componentId',
  removeElement: '/visualizations/:visualizationId/components/:componentId',
};

const publicUrls = {
  getAll: '/pages/:pageId/components',
  getComponent: '/components/:componentId',
};

interface Response {
  result: ElementSchema[];
}

export default {
  getAll: <P = {visualizationId: string; pageId: string}>(params: P): Promise<ElementSchema[]> => {
    const url = injectParamsIntoUrl<P>(urls.getAll, params);
    return instance.get<Response>(url).then(({data}) => data.result);
  },
  getAllPublicElements: <P = {publicId: string; pageId: string}>(
    params: P,
  ): Promise<ElementSchema[]> => {
    const url = injectParamsIntoUrl<P>(publicUrls.getAll, params);
    return instance.get<Response>(url).then(({data}) => data.result);
  },
  savePageComponents: <P = {visualizationId: string; pageId: string}>(
    components: ElementSchema[],
    params: P,
  ) => {
    const url = injectParamsIntoUrl<P>(urls.savePageComponents, params);
    const elementsArr = components.map(elm => ({
      ...elm,
      width: String(elm.width),
      height: String(elm.height),
      leftPerc: elm.leftPerc || 0,
      widthPerc: elm.widthPerc || 0,
    }));
    return instance.post(url, elementsArr);
  },
  updateComponent: <P = {visualizationId: string; componentId: string}>(
    element: ElementSchema,
    params: P,
  ) => {
    const url = injectParamsIntoUrl<P>(urls.updateComponent, params);
    const elementClone = {...element, width: String(element.width), height: String(element.height)};
    return instance.put(url, elementClone);
  },
  getComponentData: <P = {visualizationId: string; componentId: string}>(
    params: P,
    options?: {},
  ): Promise<DataResponse> => {
    const url = injectParamsIntoUrl<P>(urls.getComponentData, params);
    return instance
      .get(url, {
        params: options,
        baseURL: composeUrl,
      })
      .then(({data}) => data.result);
  },
  getPublicComponentData: <P = {publicId: string; componentId: string}>(
    params: P,
    options?: {},
  ): Promise<DataResponse> => {
    const url = injectParamsIntoUrl<P>(urls.getPublicComponentData, params);
    return instance
      .get(url, {
        params: options,
        baseURL: composeUrl,
      })
      .then(({data}) => data.result);
  },
  removeElement: <P = {visualizationId: string; componentId: string}>(params: P) => {
    const url = injectParamsIntoUrl<P>(urls.removeElement, params);
    return instance.delete(url).then(({data}) => data.result);
  },
};
