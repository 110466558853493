import Component from './Code';
import settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings} from './interfaces';

const Code: ComponentCharacteristics<Settings> = {
  type: 'code',
  name: 'Code',
  category: 'Common',
  settings,
  Component,
};
export default Code;
