import React from 'react';
import Card from './Card';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from './interfaces';
import {DataTypes} from 'interfaces/DataSources';

const CardBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'card',
  name: 'Card',
  category: 'KPI',
  Component: Card,
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.TELEMETRIES, DataTypes.ATTRIBUTES],
    maxNumberOfKeys: 1,
    selectMode: 'default',
  },
  settings: Settings,
};
export default CardBluePrint;
