import React, {useCallback} from 'react';
import Collapse from 'antd/es/collapse';
// import Row from 'antd/es/row';
// import Col from 'antd/es/col';
// import Switch from 'antd/es/switch';
import debounce from 'lodash.debounce';
import components from '../../../Components';
import {ElementSchema} from '../../../../interfaces/Elements';
import useElements from 'modules/Elements';
// import useCommands from '../../Commands/useCommands';

type Props = {
  element: ElementSchema;
};

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 4},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
};

function Settings(props: Props) {
  const {element} = props;
  const {settings} = components[element.type];
  const {Panel} = Collapse;

  const {updateElementSettings, updateElement} = useElements();
  // const {
  //   commands: {back},
  // } = useCommands();

  const setTempElementSettings = useCallback(
    debounce(({settings}: any) => {
      updateElementSettings(element.id, settings);
    }, 500),
    [element.id, updateElementSettings],
  );

  // const fullWidthHandler = useCallback(
  //   value => {
  //     if (value) {
  //       back();
  //       updateElement(
  //         {
  //           ...element,
  //           top: 0,
  //           left: 0,
  //           // height: '100%',
  //           widthPerc: 100,
  //           leftPerc: 0,
  //           settings: {
  //             ...element.settings,
  //             isFull: value,
  //           },
  //         },
  //         false,
  //       );
  //     } else {
  //       setTempElementSettings({
  //         settings: {
  //           isFull: value,
  //         },
  //       });
  //     }
  //   },
  //   [back, element, setTempElementSettings, updateElement],
  // );

  return (
    <div>
      {/*<Row>*/}
      {/*  <Col xs={24} sm={12} md={6} style={{marginLeft: '10px'}}>*/}
      {/*    Enable Full Width & Height :*/}
      {/*  </Col>*/}
      {/*  <Col xs={24} sm={12} md={6}>*/}
      {/*    <Switch onClick={fullWidthHandler} />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Collapse bordered={false} defaultActiveKey={['1']}>
        {(Array.isArray(settings) ? settings : [settings]).map(({SettingsComponent, name}) => (
          <Panel header={name} key={name}>
            <SettingsComponent
              key={element.id}
              formItemLayout={formItemLayout}
              element={element}
              setSettings={setTempElementSettings}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default Settings;
