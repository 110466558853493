import React, {memo, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Icon from 'antd/es/icon';
import Tooltip from 'antd/es/tooltip';
import useElements from '../../Elements';

import {savingLoaderStatusSelector, hasUnsavedChangesSelector} from './index';
import {authorizationToken} from 'adaptors/httpClient';
import queryString from 'query-string';

const {confirm} = Modal;
const {Group: ButtonGroup} = Button;
const parsed = queryString.parse(window.location.search);

function EditorActions() {
  const {clearElements, saveElements, debounceSaveElements} = useElements();
  const isLoading = useSelector(savingLoaderStatusSelector);
  const hasUnsavedChanges = useSelector(hasUnsavedChangesSelector);

  const handlePreviewMode = useCallback(() => {
    const qs = queryString.stringify({
      mode: 'preview',
      ...parsed,
      token: authorizationToken.get(),
      // ...paramsApi.get(),
    });
    window.open(`${window.location.pathname}?${qs}`, '_blank');
  }, []);

  const handleSave = useCallback(() => {
    //@ts-ignore
    debounceSaveElements.cancel();
    saveElements();
  }, [saveElements]);

  const handleClear = useCallback(() => {
    confirm({
      title: 'Clear Page',
      content: 'Are you sure you want to delete all component of current page ?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => {
        clearElements();
      },
    });
  }, [clearElements]);

  useEffect(() => {
    //@ts-ignore
    debounceSaveElements.cancel();
    if (hasUnsavedChanges) {
      debounceSaveElements();
    }
  }, [hasUnsavedChanges]);

  return (
    <div style={{position: 'fixed', bottom: '10px', width: '100%', textAlign: 'center'}}>
      <ButtonGroup>
        <Tooltip placement="top" title="Clear Page">
          <Button style={{backgroundColor: '#6765f5', color: 'white'}} onClick={handleClear}>
            <Icon type="delete" />
          </Button>
        </Tooltip>
        <Button style={{backgroundColor: '#6765f5', color: 'white'}} onClick={handlePreviewMode}>
          Preview
        </Button>
        {hasUnsavedChanges && (
          <Button
            loading={isLoading}
            style={{backgroundColor: '#6765f5', color: 'white'}}
            icon="save"
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}
EditorActions.whyDidYouRender = Boolean(process.env.REACT_APP_ENABLE_DEBUG);
export default memo(EditorActions);
