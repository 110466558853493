import React from 'react';
import {UnControlled as CodeMirror} from 'react-codemirror2';
import {SettingsElementProps} from 'interfaces/Settings';
import {WrappedFormUtils} from 'antd/lib/form/Form';

const codeOptions = {
  lineNumbers: true,
  extraKeys: {'Ctrl-Space': 'autocomplete'},
  mode: 'htmlmixed',
  theme: 'eclipse',
  viewportMargin: Infinity,
  mathTags: true,
};

interface Props extends SettingsElementProps<{content?: string}> {
  form: WrappedFormUtils;
}
function Settings(props: Props) {
  const {
    element: {settings = {}},
  } = props;
  const updateCode = (editor: any, data: any, content: string) => {
    props.setSettings({settings: {content}});
  };

  return <CodeMirror value={settings.content} onChange={updateCode} options={codeOptions} />;
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Settings,
  },
];
