import {ComponentCharacteristics} from 'interfaces/Elements';
import Component from './Select';
import Settings from '../Radio/Settings';

const SelectBluePrint: ComponentCharacteristics<{}> = {
  type: 'select',
  name: 'Select',
  category: 'Form',
  Component,
  settings: Settings,
  width: 6,
  height: 6,
};
export default SelectBluePrint;
