import Image from './Image';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from './interfaces';

const ImageBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'image',
  name: 'Image',
  category: 'Common',
  Component: Image,
  settings: Settings,
};

export default ImageBluePrint;
