import React from 'react';
import Switch from 'antd/lib/switch';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import InputNumber from 'antd/lib/input-number';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {IMenu} from 'interfaces/NavigationMenu';
import {useSelector} from 'react-redux';
import {getMenuStore} from '../../../NavigationMenu';

type Props = {
  form: WrappedFormUtils;
};

const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 5},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
};

function MenuSettingsForm(props: Props) {
  const {form} = props;
  const {getFieldDecorator, getFieldValue, getFieldsValue} = form;
  const menuOptions = useSelector(getMenuStore);

  const menuEnabled = getFieldValue('enabled') === undefined ? menuOptions.enabled : getFieldValue('enabled');
  const iconEnabled = getFieldValue('enableIcon') === undefined ? Boolean(menuOptions.icon) : getFieldValue('enableIcon');
  // const iconEnabled = getFieldValue('enableIcon');
  const iconOptions: Partial<IMenu['icon']> = menuOptions.icon;

  return (
    <>
      <FormItem {...formItemLayout} label="Enable">
        {getFieldDecorator('menu.enabled', {
          initialValue: menuOptions.enabled,
          valuePropName: 'checked',
        })(<Switch />)}
      </FormItem>
      <FormItem {...formItemLayout} label="Enable Logo">
        {getFieldDecorator('menu.enableIcon', {
          initialValue: Boolean(menuOptions.icon),
          valuePropName: 'checked',
        })(<Switch />)}
      </FormItem>
      {iconEnabled && (
        <FormItem {...formItemLayout} label="Logo URL">
          {getFieldDecorator('menu.icon.url', {
            initialValue: iconOptions && iconOptions.url,
          })(<Input />)}
        </FormItem>
      )}
      {iconEnabled && (
        <FormItem {...formItemLayout} label="Logo Width">
          {getFieldDecorator('menu.icon.width', {
            initialValue: iconOptions && iconOptions.width,
          })(<InputNumber min={0} />)}
        </FormItem>
      )}
      {iconEnabled && (
        <FormItem {...formItemLayout} label="Logo Height">
          {getFieldDecorator('menu.icon.height', {
            initialValue: iconOptions && iconOptions.height,
          })(<InputNumber min={0} />)}
        </FormItem>
      )}
    </>
  );
}

export default MenuSettingsForm;
