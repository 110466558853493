import Component from './EventsTable';
import Settings from '../TableSettings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from '../interfaces';
import {DataSourceFields, DataTypes} from '../../../../interfaces/DataSources';

const EventTableBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'eventTable',
  name: 'Event Table',
  category: 'Tables',
  Component,
  dataSourcesOptions: {
    disabledFields: [DataSourceFields.DataType],
    initialValue: {
      dataType: DataTypes.EVENTS,
    },
    visibleFields: [
      DataSourceFields.DataType,
      DataSourceFields.DataSourceType,
      DataSourceFields.DataSourceValue,
    ],
  },
  isTimeWindowEnabled: true,
  settings: Settings,
  height: 6,
  width: 6,
};
export default EventTableBluePrint;
