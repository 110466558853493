import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Slider from 'antd/es/slider';
import Select from 'antd/es/select';
import Checkbox from 'antd/es/checkbox';
import InputNumber from 'antd/es/input-number';
import Tooltip from 'antd/es/tooltip';
import Icon from 'antd/es/icon';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import {Settings} from './interfaces';
import {SettingsElementProps} from 'interfaces/Settings';
import {WrappedFormUtils} from 'antd/es/form/Form';

const {Item: FormItem} = Form;
const {Option} = Select;

interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
}
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
};

const ChartSettings = (props: Props) => {
  const {form} = props;
  const {getFieldDecorator} = form;
  const {
    element: {settings, dataSource},
  } = props;
  const keys = dataSource ? dataSource.dataKeys || [] : [];

  return (
    <Form labelAlign="left" layout="inline">
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem {...formItemLayout} label="X-axis" className="full-width">
            {getFieldDecorator('settings.xAxisTitle', {
              initialValue: settings && settings.xAxisTitle,
            })(<Input />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem
            className="full-width"
            {...formItemLayout}
            label={
              <span>
                Precision &nbsp;
                <Tooltip title="Tooltip Precision">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('settings.precision', {
              initialValue: (settings && settings.precision) || -1,
            })(<InputNumber min={-1} />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem
            className="full-width"
            {...formItemLayout}
            label={
              <span>
                Category Field &nbsp;
                <Tooltip title="category-field-tooltip">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('settings.categoryField', {
              initialValue: settings && settings.categoryField,
            })(
              <Select allowClear={true}>
                {keys.map(key => (
                  <Option key={key} value={key}>
                    {key}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={24} sm={24} xs={24}>
          <FormItem {...formItemLayout} label="X-Axis Rotation" className="full-width">
            {getFieldDecorator('settings.xAxisLabelRotation', {
              initialValue: (settings && settings.xAxisLabelRotation) || 0,
            })(
              <Slider
                marks={{'-90': -90, '-45': -45, 0: 0, 45: 45, 90: 90}}
                included={false}
                min={-90}
                max={90}
              />,
            )}
          </FormItem>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={6} sm={16} xs={24}>
          <FormItem label="Enable Legent" className="full-width">
            {getFieldDecorator('settings.legendEnabled', {
              initialValue: settings && settings.legendEnabled,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </FormItem>
        </Col>
        <Col md={6} sm={16} xs={24}>
          <FormItem label="Enable Zoom" className="full-width">
            {getFieldDecorator('settings.isScrollEnabled', {
              initialValue: settings && settings.isScrollEnabled,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </FormItem>
        </Col>
        <Col md={6} sm={16} xs={24}>
          <FormItem label="Enable Cursor" className="full-width">
            {getFieldDecorator('settings.isCursorEnabled', {
              initialValue: settings && settings.isCursorEnabled,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </FormItem>
        </Col>
        <Col md={6} sm={16} xs={24}>
          <FormItem label="Enable Export" className="full-width">
            {getFieldDecorator('settings.enableExport', {
              initialValue: settings && settings.enableExport,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default [
  {
    name: 'Chart Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(ChartSettings),
  },
];
