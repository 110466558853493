import styled from 'styled-components/macro';
export const StyledComponent = styled.div`
  &&& {
    ${(props: {styles: string}) => props.styles || ''}
    height: 100%;
    width: 100%;
    padding: 5;
    display: block;
    overflow: auto;
  }
`;
