import React, {useCallback, useEffect} from 'react';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import {WrappedFormUtils} from 'antd/es/form/Form';
import ColorPicker, {ColorType} from '../../../../commons/ColorPicker';

const {Option} = Select;
const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
};
type style = {
  [key: string]: string | undefined;
};
interface Props {
  form: WrappedFormUtils;
  initialValue: style;
  applyStyles(style: style): void;
}
const borderStyles = [
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
  'none',
  'hidden',
];
const background_size = ['auto', 'length', 'cover', 'contain', 'initial', 'inherit'];
function Styles(props: Props) {
  const {
    form: {getFieldDecorator, setFieldsValue},
    initialValue,
  } = props;

  const handleColorChange = useCallback(
    (color: ColorType, fieldName: string) => {
      setFieldsValue({
        [fieldName]: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      });
    },
    [setFieldsValue],
  );

  getFieldDecorator('background-color', {
    initialValue: initialValue['background-color'],
  });

  getFieldDecorator('border-color', {
    initialValue: initialValue['border-color'],
  });

  const initialColor: string = initialValue['background-color'] || '';
  const backgroundColor = initialColor.substring(5, initialColor.length - 1).split(',');

  const initialBorderColor: string = initialValue['border-color'] || '';
  const borderColor = initialBorderColor.substring(5, initialBorderColor.length - 1).split(',');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
      }}
    >
      <FormItem label="Background Image" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('background-image', {
          initialValue: initialValue['background-image'],
        })(<Input />)}
      </FormItem>
      <FormItem label="Background Position" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('background-position', {
          initialValue: initialValue['background-position'],
        })(<Input />)}
      </FormItem>
      <FormItem label="Background Repeat" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('background-size', {
          initialValue: initialValue['background-size'],
        })(
          <Select className="full-width">
            {background_size.map(style => (
              <Option value={style} key={style}>
                {style}
              </Option>
            ))}
          </Select>,
        )}
      </FormItem>
      <FormItem label="Background Color" style={{width: '300px'}} {...formItemLayout}>
        <ColorPicker
          initialValue={
            backgroundColor.length === 4
              ? {
                  r: Number(backgroundColor[0]),
                  g: Number(backgroundColor[1]),
                  b: Number(backgroundColor[2]),
                  a: Number(backgroundColor[3]),
                }
              : undefined
          }
          handleColorChange={(color: ColorType) => {
            handleColorChange(color, 'background-color');
          }}
        />
      </FormItem>
      <FormItem label="Border Width" style={{width: '400px'}} {...formItemLayout}>
        {getFieldDecorator('border-width', {
          initialValue: initialValue['border-width'],
        })(<Input />)}
      </FormItem>
      <FormItem label="Border Style" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('border-style', {
          initialValue: initialValue['border-style'],
        })(
          <Select className="full-width">
            {borderStyles.map(style => (
              <Option value={style} key={style}>
                {style}
              </Option>
            ))}
          </Select>,
        )}
      </FormItem>
      <FormItem label="Border Color" style={{width: '300px'}} {...formItemLayout}>
        <ColorPicker
          initialValue={
            borderColor.length === 4
              ? {
                  r: Number(borderColor[0]),
                  g: Number(borderColor[1]),
                  b: Number(borderColor[2]),
                  a: Number(borderColor[3]),
                }
              : undefined
          }
          handleColorChange={(color: ColorType) => {
            handleColorChange(color, 'border-color');
          }}
        />
      </FormItem>
      <FormItem label="Font Family" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('font-family', {
          initialValue: initialValue['font-family'],
        })(<Input />)}
      </FormItem>
      <FormItem label="Font Size" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('font-size', {
          initialValue: initialValue['font-size'],
        })(<Input />)}
      </FormItem>
      <FormItem label="Padding" style={{width: '300px'}} {...formItemLayout}>
        {getFieldDecorator('padding', {
          initialValue: initialValue['padding'],
        })(<Input />)}
      </FormItem>
    </div>
  );
}
export default Form.create({
  onValuesChange: (props: Props, changedValues: {[key: string]: string | number}, allValues) => {
    Object.entries(changedValues).forEach(([key, value]) => {
      if (value && !isNaN(Number(value))) {
        allValues[key] = `${value}px`;
      }
    });
    props.applyStyles(allValues);
  },
})(Styles);
