import Input from 'antd/es/input';
import Icon from 'antd/es/icon';
import styled from 'styled-components';
import colors from '../../../utils/colors';

export const ElementsLibraryStyled = styled.div`
  display: ${(props: {visible?: boolean}) => (props.visible ? 'initial' : 'none')}
  position: absolute;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.9);
  min-height: 28vh;
  max-height: 100vh;
  z-index: 500;
  border-radius: 5px;
  border: 1px solid ${colors.border};
   margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

export const SearchInputContainer = styled.div`
  box-sizing: border-box;
  padding: 12px;
  border-bottom: 1px solid ${colors.border};
`;

export const Library = styled.div`
  padding-bottom: 10px;
  height: 100%;
  overflow: hidden;
  max-height: 550px;
`;

export const Category = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
  //background-color: rgba(156, 156, 156, 0.4);
`;

export const SearchInput = styled(Input)`
  height: 24px;
  margin-bottom: 20px;
  & + .ant-input-group-addon {
    padding: 0 5px;
  }
`;

export const SearchIcon = styled(Icon)`
  font-size: 12px;
  color: black;
`;

export const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

export const CategoryName = styled.div`
  background-color: rgba(176, 172, 250, 1);
  color: white;
  //border-top-right-radius: 7px;
  //border-top-left-radius: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px 10px;
  font-weight: bold;
  //color: white;
`;

export const LibraryComponent = styled.div`
  transition: height 0.3s;
  overflow-y: hidden;
  padding-top: 5px;
`;

export const LibraryItem = styled.div`
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding: 2px 15px;
  cursor: move;
  font-size: 13px;
  line-height: 14px;
  &:hover {
    background-color: rgba(176, 172, 250, 0.3);
    color: white;
  }
`;
