import {Reducer} from 'react';
import {LogActions, DebuggerStore} from './interfaces';
import immer from 'immer';
import {AvailableTabs} from '../interfaces';

const defaultState: DebuggerStore = {
  // elementsData: {},
  activeTab: AvailableTabs.INFO,
  logs: [],
  isVisible: localStorage.getItem('debuggerVisibility') === 'true',
};

export const debuggerStore: Reducer<DebuggerStore, any> = (state = defaultState, action) => {
  switch (action.type) {
    case LogActions.ADD_LOG:
      return immer(state, stateDraft => {
        stateDraft.logs.push(action.payload);
      });
    case LogActions.CLEAR:
      return {...state};
    case LogActions.SET_DEBUGGER_VISIBILITY: {
      localStorage.setItem('debuggerVisibility', `${action.payload}`);
      return {...state, isVisible: action.payload};
    }
    case LogActions.SET_DEBUGGER_TAB: {
      return {...state, activeTab: action.payload};
    }
    // case LogActions.ADD_ELEMENT_DATA:
    //   const elementData = action.payload;
    //   return {...state, elementsData: {[elementData.id]: elementData.data}};
    default:
      return state;
  }
};
