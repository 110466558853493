import React from 'react';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {ElementDataSource, ElementSchema} from 'interfaces/Elements';
import {DataSourceFields} from 'interfaces/DataSources';
import DataSourceType from 'commons/DataSources/FormItems/DataSourceType';
import DataSourceValue from 'commons/DataSources/FormItems/DataSourceValue';
import DataKeys from 'commons/DataSources/FormItems/DataKeys';
import DataType from 'commons/DataSources/FormItems/DataType';
import components from '../../modules/Components';

interface Props {
  // element: ElementSchema;
  form: WrappedFormUtils;
  element: ElementSchema | null;
  // options: DataSourceOptions;
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 5},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
};

function DataSources(props: Props) {
  const {form, element} = props;

  if (!element) {
    return null;
  }
  const {dataSource = {}} = element;
  const {dataSourcesOptions} = components[element.type];

  if (!dataSourcesOptions) {
    return null;
  }

  const {
    disabledFields = [],
    visibleFields = [
      DataSourceFields.DataSourceType,
      DataSourceFields.DataKeys,
      DataSourceFields.DataSourceValue,
      DataSourceFields.DataType,
    ],
    availableDataTypes,
    availableDataSourceTypes,
    initialValue = {},
    maxNumberOfKeys,
    selectMode,
  } = dataSourcesOptions;

  const {
    dataType: initialDataType = initialValue.dataType,
    dataSourceValue: initialDataSourceValue = initialValue.dataSourceValue,
    dataSourceType: initialDataSourceType = initialValue.dataSourceType,
    dataKeys: initialDataKeys = initialValue.dataKeys,
  }: Partial<ElementDataSource> = dataSource || {};

  return (
    <>
      {visibleFields.includes(DataSourceFields.DataSourceType) && (
        <DataSourceType
          initialValue={initialDataSourceType}
          formItemLayout={formItemLayout}
          form={form}
          disabled={disabledFields.includes(DataSourceFields.DataSourceType)}
          availableDataSourceTypes={availableDataSourceTypes}
        />
      )}
      {visibleFields.includes(DataSourceFields.DataSourceValue) && (
        <DataSourceValue
          initialValue={initialDataSourceValue}
          formItemLayout={formItemLayout}
          form={form}
          disabled={disabledFields.includes(DataSourceFields.DataSourceValue)}
        />
      )}
      {visibleFields.includes(DataSourceFields.DataType) && (
        <DataType
          initialValue={initialDataType}
          formItemLayout={formItemLayout}
          form={form}
          disabled={disabledFields.includes(DataSourceFields.DataType)}
          availableTypes={availableDataTypes}
        />
      )}
      {visibleFields.includes(DataSourceFields.DataKeys) && (
        <DataKeys
          initialValue={initialDataKeys}
          formItemLayout={formItemLayout}
          form={form}
          disabled={disabledFields.includes(DataSourceFields.DataKeys)}
          maxNumberOfKeys={maxNumberOfKeys}
          selectMode={selectMode}
        />
      )}
    </>
  );
}

export default DataSources;
