import { MetricsData, SerialData } from "./Data";

export enum DataSources {
  CERVELLO = 'CERVELLO',
  VPI = 'VPI',
  DB_CONNECTOR = 'DB_CONNECTOR',
}

export enum DataSourceTypes {
  DEVICE = 'DEVICE',
  TAG = 'TAG',
}

export enum DataTypes {
  TELEMETRIES = 'TELEMETRIES',
  ATTRIBUTES = 'ATTRIBUTES',
  ALARMS = 'ALARMS',
  EVENTS='EVENTS',
}

export enum DataSourceFields {
  DataSourceType = 'DATA_SOURCE_TYPE',
  DataKeys = 'DATA_KEYS',
  DataSourceValue = 'DATA_SOURCE_VALUE',
  DataType ='DATA_TYPE',
}

export interface DataSourceValue {
  identifier: string;
  name: string;
}

export interface initialValue {
  DataSourceType?: DataSourceTypes;
  DataSourceValue?: DataSourceValue;
  dataType?: DataTypes;
  dataKeys?: string | string[];
}

export interface DataSourceOptions {
  disabledFields?: DataSourceFields[];
  visibleFields?: DataSourceFields[];
  initialValue?: initialValue;
  availableDataTypes?: DataTypes[];
  availableDataSourceTypes?: DataSourceTypes[];
  maxNumberOfKeys?: number;
  selectMode?: 'tags' | 'multiple' | 'default';
}

export interface DataResponse {
  data: SerialData[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}
