import React, {useEffect, useMemo, useState} from 'react';
import Map from '../Map';
import {ElementSchema} from 'interfaces/Elements';
import {Settings} from '../interfaces';
import {SerialData} from 'interfaces/Data';
import useElements from 'modules/Elements';
import {groupDataBy, getCoordinatesFromSerialData} from '../mapsUtils/utils';

const identifier = Date.now();

interface Props {
  element: ElementSchema<Settings>;
  style: {[key: string]: any};
}

const DEFAULT_LAT_KEY = 'lat';
const DEFAULT_LNG_KEY = 'lng';

function RealTimeMaps(props: Props) {
  const {
    element: {id, settings = {}, dataSource},
    style,
  } = props;
  const {getElementData} = useElements();
  const isLive = false;

  const [locationData, setLocationData] = useState<SerialData[]>([]);

  const getCoordinates = useMemo(() => {
    const latKey = settings.latKey || DEFAULT_LAT_KEY;
    const lngKey = settings.lngKey || DEFAULT_LNG_KEY;
    return getCoordinatesFromSerialData(
      Object.values(groupDataBy(locationData, ['resourceId', 'time'])),
      latKey,
      lngKey,
      (dataSource && dataSource.dataSourceValue.name) || '',
    );
  }, [dataSource, locationData, settings.latKey, settings.lngKey]);

  useEffect(() => {
    getElementData({id, dataSource}, {pageSize: 5000}, false).then(({data}) => {
      if (data) {
        setLocationData(data);
      }
    });
  }, [dataSource, getElementData, id]);

  return (
    <Map
      settings={settings}
      style={style}
      mapOptions={{
        zoom: 12,
        style: {
          height: props.element.height,
        },
        boundsOptions: {maxZoom: 12},
      }}
      coordinatesData={getCoordinates}
    />
  );
}

export default RealTimeMaps;
