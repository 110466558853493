import React from 'react';
import Form from 'antd/lib/form';
import {WrappedFormUtils} from 'antd/lib/form/Form';
import Select from 'antd/lib/select';
import {DataSourceTypes} from 'interfaces/DataSources';

const FormItem = Form.Item;
const {Option} = Select;

interface Props {
  form: WrappedFormUtils;
  initialValue?: DataSourceTypes;
  formItemLayout: {};
  disabled?: boolean;
  availableDataSourceTypes?: DataSourceTypes[];
}

const availableDataSources = [DataSourceTypes.DEVICE, DataSourceTypes.TAG];

function DataSourceType(props: Props) {
  const {
    form,
    initialValue,
    formItemLayout,
    disabled,
    availableDataSourceTypes = availableDataSources,
  } = props;
  const {getFieldDecorator} = form;

  const handleChange = () => {
    form.setFieldsValue({
      dataSourceValue: undefined,
      dataKeys: undefined,
    });
  };

  return (
    <FormItem {...formItemLayout} label="Data Source Type">
      {getFieldDecorator('dataSource.dataSourceType', {
        initialValue,
      })(
        <Select onChange={handleChange} disabled={disabled}>
          {availableDataSourceTypes.map(type => (
            <Option key={type}>{type}</Option>
          ))}
        </Select>,
      )}
    </FormItem>
  );
}

export default DataSourceType;
