import React, {useCallback} from 'react';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import {ElementSchema} from 'interfaces/Elements';
import {WrappedFormUtils} from 'antd/es/form/Form';
import * as interfaces from './interfaces';
import useController from '../../Control/Prototype';

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
}
const {Item: FormItem} = Form;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

function InputComponent(props: Props) {
  const {
    form,
    element: {settings = {}},
  } = props;
  const {fieldName, label, disabled, rules = []} = settings || {};
  const {value, sendAction} = useController(props);

  const handleChange = useCallback(
    ({target}) => {
      const fieldError = form && fieldName ? form.getFieldError(`${fieldName}`) : undefined;
      if (settings.actionName && !fieldError) {
        sendAction({
          name: settings.actionName,
          value: target.value,
        });
      }
    },
    [fieldName, form, sendAction, settings.actionName],
  );

  if (form) {
    return (
      <FormItem label={label} {...formItemLayout} style={{width: '100%', height: '100%'}}>
        {form.getFieldDecorator(`${fieldName}`, {
          initialValue: value,
          rules: rules.map(({type, typeValue, message}) => {
            //@ts-ignore
            const name: string = type;
            // @ts-ignore
            const value = isNaN(typeValue) ? typeValue : Number(typeValue);
            return {
              [name]: value,
              message,
            };
          }),
        })(<Input disabled={disabled} onChange={handleChange} />)}
      </FormItem>
    );
  }
  return <Input disabled={disabled} onChange={handleChange} value={`${value}`} />;
}
export default InputComponent;
