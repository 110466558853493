import LinearGauge from './LinearGauge';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';
import {Settings as SettingsSchema} from './interfaces';
import {DataTypes} from '../../../../interfaces/DataSources';

const LinearGuageBluePrint: ComponentCharacteristics<SettingsSchema> = {
  type: 'linearGauge',
  name: 'Linear Gauge',
  category: 'KPI',
  Component: LinearGauge,
  dataSourcesOptions: {
    availableDataTypes: [DataTypes.TELEMETRIES, DataTypes.ATTRIBUTES],
    maxNumberOfKeys: 1,
    selectMode: 'default',
  },
  settings: Settings,
  width: 6,
  height: 6,
};
export default LinearGuageBluePrint;
