import React from 'react';
import PropTypes from 'prop-types';

// Creates OpenlockSpin
// Copied render method from 'antd/components/spin/Form.tsx' from github, then modified to vanilla js
// https://github.com/ant-design/ant-design/blob/master/components/spin/index.tsx

import Spin from 'antd/es/spin';

class Spinner extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    size: PropTypes.string,
    delay: PropTypes.number,
  };

  render() {
    const {isLoading, delay = 0, size, style = {}} = this.props;
    return isLoading ? (
      <Spin
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...style,
        }}
        className="spinner-container"
        delay={delay}
        size={size}
      />
    ) : (
      this.props.children || null
    );
  }
}

export default Spinner;
