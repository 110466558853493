import React from 'react';
import {Marker, Tooltip} from 'react-leaflet';
import Leaflet from 'leaflet';

interface Props {
  name?: string;
  popupContent?: string;
  position: [number, number];
  icon?: Leaflet.Icon;
  popupStyle?: {};
}
function MarkerComponent(props: Props) {
  const {name, popupContent, position, icon, popupStyle = {}} = props;

  const createMarkerPopupContent = () => {
    return (
      <span>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 10,
            ...popupStyle,
          }}
        >
          {name}
        </p>
        {popupContent && (
          <span
            dangerouslySetInnerHTML={{
              __html: popupContent,
            }}
          />
        )}
      </span>
    );
  };

  return (
    <Marker position={position} icon={icon}>
      <Tooltip>{createMarkerPopupContent()}</Tooltip>
    </Marker>
  );
}

export default MarkerComponent;
