import Component from './Button';
import Settings from './Settings';
import {ComponentCharacteristics} from 'interfaces/Elements';

const ButtonBluePrint: ComponentCharacteristics<{}> = {
  type: 'button',
  name: 'Button',
  category: 'Common',
  Component,
  settings: Settings,
  width: 6,
  height: 6,
};
export default ButtonBluePrint;
