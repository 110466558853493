import React, {useCallback} from 'react';
import Modal from 'antd/lib/modal';
import {Page} from 'interfaces/Pages';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Button from 'antd/lib/button';
import usePages from '../../../Pages';

const {confirm} = Modal;
const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 5},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
};
type Props = {
  page: Page | null;
  visible: boolean;
  form: WrappedFormUtils;
  onClose: Function;
};

function PageSettings(props: Props) {
  const {visible, page, form, onClose} = props;
  const {Item: FormItem} = Form;
  const {getFieldDecorator} = form;
  const {deletePage, updatePage} = usePages();

  const removePage = useCallback(
    (pageId: string) => {
      confirm({
        title: 'Delete Page',
        content:
          "Careful! this action will delete the page and all of its content and can't be undone!",
        okText: 'confirm',
        cancelText: 'cancel',
        onOk: async () => {
          await deletePage(pageId);
          onClose();
        },
      });
    },
    [deletePage, onClose],
  );

  const savePageSettings = useCallback(() => {
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        await updatePage({...page, ...values});
        onClose();
      }
    });
  }, [form, onClose, page, updatePage]);

  if (!page) {
    return null;
  }

  return (
    <Modal
      title="Page Settings"
      visible={visible}
      onCancel={() => onClose()}
      onOk={savePageSettings}
    >
      <Form>
        <FormItem {...formItemLayout} label="Page Name">
          {getFieldDecorator('name', {
            initialValue: page.name,
          })(<Input />)}
        </FormItem>
      </Form>
      <div style={{textAlign: 'center'}}>
        <Button
          disabled={page.isRootPage === true}
          type="danger"
          onClick={() => removePage(page.id)}
        >
          Delete Page
        </Button>
      </div>
    </Modal>
  );
}

export default Form.create<Props>()(PageSettings);
