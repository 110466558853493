import React, {useCallback} from 'react';
import Tabs from 'antd/lib/tabs';
import Settings from './EditorSettings';
import ShareOptions from './ShareOptions';
import Modal from 'antd/es/modal';
import {WrappedFormUtils} from 'antd/es/form/Form';
import Form from 'antd/es/form';

const {TabPane} = Tabs;

interface Props {
  form: WrappedFormUtils;
  setSettings: Function;
  onClose: Function;
  isVisible: boolean;
}

function EditorSettings(props: Props) {
  const {form, isVisible, onClose, setSettings} = props;
  const {validateFields} = form;

  const saveSettings = useCallback(() => {
    validateFields((err, values) => {
      if (!err) {
        if (values.editor) {
          setSettings(values.editor);
        }
        onClose();
      }
    });
  }, [onClose, setSettings, validateFields]);

  return (
    <Modal visible={isVisible} style={{padding: 10}} onCancel={() => onClose()} onOk={saveSettings}>
      <Tabs>
        <TabPane key="settings" tab="Grid Settings">
          <Settings setSettings={setSettings} form={form} />
        </TabPane>
        <TabPane key="share" tab="Share Settings">
          <ShareOptions />
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default Form.create<Props>()(EditorSettings);
