import React, {useCallback, useContext} from 'react';
import Button from 'antd/es/button';
import {ElementSchema} from 'interfaces/Elements';
import * as interfaces from './interfaces';
import {WrappedFormUtils} from 'antd/es/form/Form';
import APIs from '../../Control/apis';
import {AppContext} from '../../../../App';

interface Props {
  element: ElementSchema<interfaces.Settings>;
  form: WrappedFormUtils;
  style: {[key: string]: any};
}
function ButtonWrapper(props: Props) {
  const {
    form: {validateFields},
    element: {settings},
    style,
  } = props;
  const {actionName} = settings || {};
  const {params} = useContext(AppContext);

  const sendAction = useCallback(
    (data: {value: any; name: string}): void => {
      if (data.name && params.organizationId && params.applicationId) {
        APIs.sendAction<{organizationId: string; applicationId: string}>(data, {
          organizationId: params.organizationId,
          applicationId: params.applicationId,
        });
      }
    },
    [params.applicationId, params.organizationId],
  );

  const clickHandler = useCallback(() => {
    if (actionName) {
      validateFields((errors, value) => {
        console.log(errors);
        if (!errors) {
          sendAction({value: JSON.stringify(value), name: actionName});
        }
      });
    }
  }, [actionName, sendAction, validateFields]);

  return (
    <Button onClick={clickHandler} style={{...style, width: '100%', height: '100%'}}>
      {settings ? settings.label || 'Button' : 'Button'}
    </Button>
  );
}
export default ButtonWrapper;
