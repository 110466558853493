import {combineReducers} from 'redux';
import {dataStore} from '../modules/Data/DataStore';
import {debuggerStore} from '../modules/EditMode/Debugger/store';
import {editorStore} from '../modules/EditMode/Editor/store';
import {pagesStore} from '../modules/Pages/Store';
import {elementsStore} from 'modules/Elements/ElementsStore';
import {navigationMenu} from 'modules/NavigationMenu/store';
import {Store} from './interfaces';

export default () => {
  // @ts-ignore
  return combineReducers<Store>({
    editorStore,
    dataStore,
    debuggerStore,
    pagesStore,
    elementsStore,
    navigationMenu,
  });
};
