import React from 'react';
import {HotKeys, KeyMap} from 'react-hotkeys';
import useCommands from '../../Commands/useCommands';

interface HotKey {
  name: string;
  func: string;
  keys: string | string[];
}

type Props = {
  children: React.ReactNode;
};

export const hotKeysMap: HotKey[] = [
  {
    name: 'Cut',
    func: 'cut',
    keys: 'ctrl+x',
  },
  {
    name: 'Paste',
    func: 'paste',
    keys: 'ctrl+v',
  },
  {
    name: 'Copy',
    func: 'copy',
    keys: 'ctrl+c',
  },
  {
    name: 'Delete',
    func: 'hide',
    keys: ['del', 'backspace'],
  },
];

const keyMap: KeyMap = hotKeysMap.reduce((result: KeyMap, key: HotKey) => {
  result[key.func] = key.keys;
  return result;
}, {});

function WithHotKeys(props: Props) {
  const {commands} = useCommands();

  return (
    <HotKeys
      // style={{width: '100%', height: 'calc(100vh - 130px)'}}
      keyMap={keyMap}
      // @ts-ignore
      handlers={commands}
    >
      {props.children}
    </HotKeys>
  );
}

// WithHotKeys.whyDidYouRender = process.env.REACT_APP_ENABLE_DEBUG;

export default React.memo(WithHotKeys);
