import React, {useCallback, useContext, useEffect, useState} from 'react';
import Form from 'antd/lib/form';
import {WrappedFormUtils} from 'antd/lib/form/Form';
import Select from 'antd/lib/select';
import debounce from 'lodash.debounce';
import Api from '../api';
import {DataSourceTypes, DataTypes} from '../../../interfaces/DataSources';
import {AppContext} from 'App';

const FormItem = Form.Item;
const {Option} = Select;

interface Props {
  form: WrappedFormUtils;
  initialValue?: string[];
  formItemLayout: {};
  disabled?: boolean;
  maxNumberOfKeys?: number;
  selectMode?: 'tags' | 'multiple' | 'default' | 'combobox';
}

function DataKeys(props: Props) {
  const [keys, setKeys] = useState<string[]>([]);
  const {form, initialValue, formItemLayout, disabled, maxNumberOfKeys, selectMode} = props;
  const {getFieldDecorator} = form;
  const {params} = useContext(AppContext);
  const {dataSource = {}} = form.getFieldsValue();
  const {dataType, dataSourceType, dataSourceValue = {}} = dataSource;

  const getDataKeysForDevice = useCallback(
    async deviceId => {
      if (dataType === DataTypes.TELEMETRIES) {
        return await Api.getDeviceTelemetryKeys(deviceId, params);
      } else if (dataType === DataTypes.ATTRIBUTES) {
        return await Api.getDeviceAttributesKeys(deviceId, params);
      }
      return Promise.resolve([]);
    },
    [dataType, params],
  );

  const getDataKeysForTag = useCallback(
    async tagName => {
      if (dataType === DataTypes.TELEMETRIES) {
        return await Api.getTagTelemetryKeys(tagName, params);
      } else if (dataType === DataTypes.ATTRIBUTES) {
        return await Api.getTagAttributesKeys(tagName, params);
      }
      return Promise.resolve([]);
    },
    [dataType, params],
  );

  const getDataKeys = useCallback(
    debounce(async () => {
      try {
        if (dataSourceType === DataSourceTypes.DEVICE) {
          const keys = await getDataKeysForDevice(dataSourceValue.key);
          setKeys(keys);
        } else if (dataSourceType === DataSourceTypes.TAG) {
          const keys = await getDataKeysForTag(dataSourceValue.key);
          setKeys(keys);
        }
      } catch (e) {
        // TODO: Log to errors
      }
    }, 500),
    [form],
  );

  /**
   * Request data source keys
   */
  useEffect(() => {
    if (dataSourceType && dataSourceValue.key) {
      //form.setFieldsValue({dataSource: {...dataSource, dataKeys: []}});
      getDataKeys();
    }
  }, [dataSourceType, dataSourceValue.key, getDataKeys]);

  return (
    <FormItem {...formItemLayout} label="Data Keys">
      {getFieldDecorator('dataSource.dataKeys', {
        initialValue: initialValue || [],
      })(
        <Select
          mode={selectMode || 'tags'}
          placeholder="Select Keys"
          filterOption={false}
          disabled={disabled}
          maxTagCount={maxNumberOfKeys}
        >
          {keys.map(key => (
            <Option key={key}>{key}</Option>
          ))}
        </Select>,
      )}
    </FormItem>
  );
}

export default DataKeys;
