import React, {useCallback, useEffect, useState} from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {Settings} from './interfaces';
import {SettingsElementProps} from '../../../../interfaces/Settings';
import {WrappedFormUtils} from 'antd/es/form/Form';
import MetricsSettings from '../Metrics';
const FormItem = Form.Item;

interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
}

function GuageSettings(props: Props) {
  const {
    form: {getFieldDecorator},
    element: {settings},
  } = props;

  return (
    <Form layout="inline" labelAlign="left">
      <Row gutter={24} type="flex" justify="space-around">
        <Col md={24} sm={16} xs={24}>
          <FormItem label="Max Value">
            {getFieldDecorator('settings.maxValue', {
              initialValue: settings && settings.maxValue,
            })(<Input />)}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}

export default [
  {
    name: 'Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(GuageSettings),
  },
  {
    name: 'Metrics',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(MetricsSettings),
  },
];
