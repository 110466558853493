import React from 'react';
import Form from 'antd/es/form';
import Checkbox from 'antd/es/checkbox';
import Select from 'antd/es/select';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {WrappedFormUtils} from 'antd/es/form/Form';
import {Settings} from '../interfaces';
import {ElementSchema} from 'interfaces/Elements';
import {SettingsElementProps} from 'interfaces/Settings';

const {Item: FormItem} = Form;
const {Option} = Select;
interface Props extends SettingsElementProps<Settings> {
  form: WrappedFormUtils;
  element: ElementSchema<Settings>;
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 5},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 12},
  },
};

const PieChartSettings = (props: Props) => {
  const {
    form: {getFieldDecorator, getFieldValue},
    element: {settings},
  } = props;

  return (
    <Form labelAlign="left" layout="inline">
      <Row gutter={24}>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Legend Enabled" className="full-width">
            {getFieldDecorator('settings.legendEnabled', {
              initialValue: settings && settings.legendEnabled,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </FormItem>
        </Col>
        <Col md={8} sm={16} xs={24}>
          <FormItem label="Enable Export" className="full-width">
            {getFieldDecorator('settings.enableExport', {
              initialValue: settings && settings.enableExport,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </FormItem>
        </Col>
        {getFieldValue('settings.legendEnabled') && (
          <Col md={8} sm={16} xs={24}>
            <FormItem label="Legend Position" className="full-width" {...formItemLayout}>
              {getFieldDecorator('settings.legendPosition', {
                initialValue: (settings && settings.legendPosition) || 'right',
              })(
                <Select style={{width: '100%'}}>
                  <Option value="top">Top</Option>
                  <Option value="right">Right</Option>
                  <Option value="bottom">Bottom</Option>
                  <Option value="left">Left</Option>
                </Select>,
              )}
            </FormItem>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default [
  {
    name: 'Chart Settings',
    SettingsComponent: Form.create({
      onValuesChange: (props: Props, changedValues, allValues) => {
        props.setSettings(allValues);
      },
    })(PieChartSettings),
  },
];
